export default function options() {
    return {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: {
            text: ''
          },
          xaxis: {
            categories: ['Luiz Fellype', 'Wendre Matheus', 'Hiago', 'Mahavatar'],
            labels: {
                show: false
            }
          },
          yaxis: {
            labels: {
              show: true
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "%"
              }
            }
          },
          fill: {
            opacity: 1
          
          },
          legend: {
            position: 'bottom',
            offsetX: 40
          }   
    }
          
  }
  