import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//@material-ui
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

//@material icons
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function TableComponent(props) {
  const [state, setState] = useState({
    operations_id: null,
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
  });
  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead style={{ backgroundColor: "#7c8796", color: "#fff" }}>
            <TableRow>
              <TableCell
                style={{ color: "#fff", width: "42%", paddingRight: 75 }}
                align="right"
              >
                Clamps
              </TableCell>
              <TableCell
                style={{ color: "#fff", width: "28%", paddingRight: 75 }}
                align="right"
              >
                Garfo
              </TableCell>
              <TableCell
                style={{ color: "#fff", width: "28%", paddingRight: 75 }}
                align="right"
              >
                Grand Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
      <MaterialTable
        columns={[
          {
            title: "Operador",
            field: "group",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
          },
          {
            title: "Quantidade de Pausas",
            field: "quantity_clamps",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
          },
          {
            title: "% Pausa",
            field: "percent",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
            render: (rowData) => rowData.percent_clamps.toFixed(2) + "%",
          },
          {
            title: "Quantidade de Pausas",
            field: "quantity_garfo",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
          },
          {
            title: "% Pausa",
            field: "percent",
            cellStyle: {
              paddingLeft: 24,
              textAlign: "left",
              borderRight: "1px solid rgba(174, 174, 174, 0.33)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.33)",
            },
            headerStyle: {
              borderRight: "1px solid rgba(174, 174, 174, 0.33)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.33)",
            },
            render: (rowData) => rowData.percent_garfo + "%",
          },
          {
            title: "Quantidade de Pausas",
            field: "total",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
          },
          {
            title: "% Pausa",
            field: "percent",
            cellStyle: { paddingLeft: 24, textAlign: "left", border: "#fff" },
            render: (rowData) => rowData.total_percent.toFixed(2) + "%",
          },
        ]}
        data={props.final_pauses}
        options={{
          pageSize: 5,
          actionsColumnIndex: -1,
          showFirstLastPageButtons: false,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          tableLayout: "auto",
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#C2c9d4",
            color: "#fff",
            border: "#fff",
            //boderRadius: 30,
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          boxShadow: "0px 0px #fff",
        }}
      />
    </>
  );
}

export default TableComponent;
