import React, {useState} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//custom components
import {BlockedProgress, SegregatedProgress, StructuredProgress, TravelProgress} from './Progress'
import {DivWrapper, DivHeader, ContainerIMG, Placeholder} from './styles'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';




const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f3f5f8',
    color: '#bdc3cc',
    border: '0px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Table(){
    const [state, setState] = useState({
      operations_id: null,
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    })
    const handleStartDate = (date) => {
      setState((prev) => ({
        ...prev,
        startDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
    const handleEndDate = (date) => {
      setState((prev) => ({
        ...prev,
        endDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
   
    const forklifts = [
        {name: "Luiz Fellype Silveira de Oliveira de Souza", products_blocked: 5, products_segregated: 5,  products_structured: 5, products_travel: 6},
        {name: "Wendreo", products_blocked: 3, products_segregated: 25,  products_structured: 30, products_travel: 50}
    ]
    
    const operations = [
      {id: 1, name: "Operations A"},
      {id: 2, name: "Operations B"}
    ]
    const forkliftslist = [
      {id: 1, name: "Empilhadeira 1"},
      {id: 2, name: "Empilhadeira 2"},
      {id: 3, name: "Empilhadeira 3"},
    ]
    const periods = [
      {id: 1, name: "Periodo A"},
      {id: 2, name: "Periodo B"},
      {id: 3, name: "Periodo C"}
    ]

    return (
      <Grid container  justify="center" alignItems="center" spacing={3}>
       
        <Grid item lg={2} md={2} sm={2} xs={2}>
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Operações
                  </MenuItem>
                  {operations.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Empilhadeiras
                  </MenuItem>
                  {forkliftslist.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}              
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(state.startDate, "YYYY-MM-DD")}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
            
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}              
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(state.endDate, "YYYY-MM-DD")}
              onChange={handleEndDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Período
                  </MenuItem>
                  {periods.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        
        
        <Grid item lg={12} sm={12} md={12}> 
        <MaterialTable 
        columns={[
          {
            title: "Operador",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left", paddingRight: 80, border: "#fff" },
            headerStyle: {borderBottom: '#fff', borderRadius: '8px 0px 0px 0px'},
           
          },
          {
            title: "Total Produtos Blocado",
            field: "products_blocked",
            cellStyle: {paddingLeft: 24, textAlign: "left", border: "#fff"},
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => (<><Grid container direction="row"
            justify="flex-start"
            alignItems="center" spacing={2}><Grid item>{rowData.products_blocked}%</Grid><Grid item md={8} lg={8} sm={8}><BlockedProgress variant="determinate" value={rowData.products_blocked} /></Grid></Grid></>)
          },
          {
            title: "Total Produtos Segregado",
            field: "products_segregated",
            cellStyle: {paddingLeft: 24, textAlign: "left", border: "#fff" },
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => (<><Grid container direction="row"
            justify="flex-start"
            alignItems="center" spacing={2}><Grid item>{rowData.products_segregated}%</Grid><Grid item md={8} lg={8} sm={8}><SegregatedProgress variant="determinate" value={rowData.products_segregated} /></Grid></Grid></>)
          },
          {
            title: "Total Produtos Estruturado",
            field: "products_structured",
            cellStyle: {paddingLeft: 24, textAlign: "left", border: "#fff"},
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => (<><Grid container direction="row"
            justify="flex-start"
            alignItems="center" spacing={2}><Grid item>{rowData.products_structured}%</Grid><Grid item md={8} lg={8} sm={8}><StructuredProgress variant="determinate" value={rowData.products_structured} /></Grid></Grid></>)
          },
          {
            title: "Total Produtos Viagens",
            field: "products_travel",
            cellStyle: {paddingLeft: 24, textAlign: "left", border: "#fff"},
            headerStyle: {borderBottom: '#fff', borderRadius: '0px 8px 0px 0px'},
            render: (rowData) => (<><Grid container direction="row"
            justify="flex-start"
          alignItems="center" spacing={2}><Grid item>{rowData.products_travel}%</Grid><Grid item md={8} lg={8} sm={8}><TravelProgress variant="determinate" value={rowData.products_travel} /></Grid></Grid></>)
          },
        ]}
        data={forklifts}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          showFirstLastPageButtons: false,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#C2c9d4",
            color: "#fff",
            border: '#fff',
            //boderRadius: 30,
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          boxShadow: '0px 0px #fff'
        }}
      />
        </Grid>
      </Grid>
       
    )
}

export default Table;