import React from 'react';
import api from '../../../../../core/services/api'



//material-ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

//custom components
import TableItem from './TableItem'

function ConfirmItem(props) {
  const [open, setOpen] = React.useState(false);
  const [obj, setObj] = React.useState("");
  const [justification, setJustification] = React.useState(props.justification || "");
  const [state, setState] = React.useState("");

  const handleClickOpen = () => {
    setState({ disabled: false })
    setJustification(props.justification || "")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    setState({disabled: true})
      const objeto = {
          status: 'transit',
          package_list: obj,
          justification: justification
      }
     confirmPlanning(objeto, props.id);
     setOpen(false);
  }

  async function confirmPlanning(obj, id){
    try {
        await api.put(`plannning-confirm/`+id, obj);
        props.getData();
      } catch (error) {
        console.warn(error);
      }
  }

  return (
    <div>
        <MenuItem  onClick={handleClickOpen} disabled={props.disabled}>
        {props.children}
        </MenuItem>
     
      <Dialog
      fullWidth={true}
      maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Confirmar envio de {props.it}</DialogTitle>
        <DialogContent>
        <TableItem it={props.it} package_list={props.package_list} packages={props.packages} setObj={setObj} />
        <TextField
            //error={state.error ? (forklift_number ? false : true) : false}
            id="justification"
            name="justification"
            label="Justificativa"
            type="text"
            value={justification}
            onChange={(e) => setJustification(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmItem;