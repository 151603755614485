import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";

//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
import SearchkBox from "../../../components/SearchBox";
import Main from '../../../components/Main'


//@material-ui
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getDefaultNormalizer } from "@testing-library/react";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import GetAppIcon from '@material-ui/icons/GetApp';



function TableModules() {
  
  const [state, setState] = useState({
    modulos: []
  })

  useEffect(() => {
    getData();
  }, [])
  
  async function getData() {
    try {
      const { data } = await api.get(`modulo`);
      
           setState((prev) => ({
        ...prev,
        modulos: data || [],
      }));
      console.log(data)
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteModule(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o Modulo ?" + item.module_id
        )
      ) {
        await api.delete(`modulo/` + item.id);
      } else {
      }
      getData();
    } catch (error) {
      console.warn(error);
    }
  }


  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  const classes = useStyles();
  const [direction, setDirection] = useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };
  
 

  return (
    <Main color="#f7f7f7" title="Módulos">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} />}
              tooltipTitle="Adicionar Módulo"
              onClick={() => handleOpen()}

            />
            {/*   <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
            /> */}
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
        {/*  <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> */}

      </Grid>
      <MaterialTable
        columns={[

          {
            title: "ID do Modulo",
            field: "module_id",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
        /*   {
            title: "Ativo",
            field: "active",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          }, */
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm modulos={rowData} getData={getData} />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteModule(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.modulos}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  );
}

export default TableModules;
