import React, {useState, useEffect} from 'react'
import api from '../../../../core/services/api'

//custom components
import Main from "../../components/Main";
import Loading from "./Loading"
import Transit from "./Transit"
import Handedout from './Handedout'
import TablePackage from './TablePackage'

//@material-ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// Utils
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";


function DashboardRenault(){
        const [state, setState] = useState("")
        const [startDate, setStartDate] = useState(moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"));
        const [endDate, setEndDate] = useState( moment(Date.now()).format("YYYY-MM-DD"));
        const [packages, setPackage] = useState("");
        const [filter, setFilter] = useState("?status=all")
        

        useEffect(() => {
            getPackages();
            getPlannings();
            getData();   
          }, [])
        useEffect(() => {
          getPlannings();   
        }, [filter])
          
          
        const handleStartDate = (date) => {
            setStartDate(date);
        };
        const handleEndDate = (date) => {
            setEndDate(date);
        }


        async function getData() {
            try{
            const { data } = await api.get(`total_balance`);
            setState((prev) => ({
                ...prev,
                
                package_await: data.current_week.total_packages,
                package_transit: data.current_week.total_packages_transit,
                package_received: data.current_week.total_packages_received,
                last_package_await: data.last_week.total_packages_last_week,
                last_package_transit: data.last_week.total_packages_transit_last_week,
                last_package_received: data.last_week.total_packages_received_last_week
            }))
            } catch (error) {
            console.warn(error);
            }
        }
        async function getPackages() {
            try {
              const { data } = await api.get(`packages`);
              setPackage(data.data);
            } catch (error) {
              console.warn(error);
            }
          }
        async function getPlannings() {
            try{
              const { data } = await api.get(`planning`+filter);
              setState((prev) => ({
                ...prev,
                plannings: data.data
              }))
            } catch (error) {
              console.warn(error);
            }
          }
        const calcDiff = (last_value, actual_value) => {
            let percent;
            if (parseFloat(actual_value) > parseFloat(last_value)) {
              percent = (parseFloat(actual_value) / parseFloat(last_value) - 1) * 100;
            } else {
              percent = (parseFloat(last_value) / parseFloat(actual_value) - 1) * 100;
            }
        
            const direction =
              parseFloat(actual_value) < parseFloat(last_value) ? "down" : "up";
            let result = (isFinite(percent) && percent) || 0;
            return { result, direction };
          };
          const handleChange = (event) => {
            setFilter(event.target.value);
           
          };

    return (
        <Main color="#f7f7f7">
            <div style={{display: "flex", justifyContent: "left", flex: 1, marginBottom: "24px" }} >
            <Grid container justify="space-between">
            <Grid item lg={7}><Typography variant="h5" >Saldo de embalagens</Typography></Grid>
            <Grid container lg={5} justify="flex-end">
                {/*
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk={true}
                    style={{ marginRight: "24px" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Data Inicial"
                    value={moment(startDate, "YYYY-MM-DD")}
                    onChange={handleStartDate}
                    KeyboardButtonProps={{
                        "aria-label": "state Inicial",
                    }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk={true}
                    style={{ marginRight: "24px" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Data Final"
                    value={moment(endDate, "YYYY-MM-DD")}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                        "aria-label": "Data Inicial",
                    }}
                    />
                </MuiPickersUtilsProvider>  */}
         </Grid>
     </Grid>
     </div>
     <Grid container spacing={3}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <Loading value={state.package_await} last_value={calcDiff(state.last_package_await, state.package_await)} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <Transit value={state.package_transit} last_value={calcDiff(state.last_package_transit, state.package_transit)}/>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <Handedout value={state.package_received} last_value={calcDiff(state.last_package_received, state.package_received)}/>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container justify="space-between"  alignItems="flex-end" style={{paddingBottom: 24}}>
        <Grid item lg={7}><Typography variant="h5" >Embalagens por viagem</Typography></Grid>
        <Grid item lg={2} md={2} xs={6}>
        <InputLabel>Filtro</InputLabel>
        <Select
          value={filter}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="?status=all">Todos</MenuItem>
          <MenuItem value="?status=waiting">Aguardando</MenuItem>
          <MenuItem value="?status=transit">Trânsito</MenuItem>
          <MenuItem value="?status=received">Entregue</MenuItem>
        </Select>
        </Grid>
      </Grid>
                <TablePackage plannings={state.plannings} packages={packages} />
            </Grid>
            </Grid>
        </Main>
    )
}

export default DashboardRenault;