import React, { useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Chart from "react-apexcharts";

import _ from "lodash";

import options from "./options";
import api from '../../../../../../core/services/api';

export default function () {
  const [state, setState] = useState({
    loading: true,
    forkliftID: null,
    operationID: null,
    forklifts: [],
    operations: [],
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    cols: [],
    picking: [],
    getting: [],
    otimizing: [],
    reoperation: [],
    reverse: [],
    resultBy: 'day',
    dateRangeID: null,
    dateRange: [
      { id: 1, name: "Últimos 7 dias" },
      { id: 2, name: "Últimos 14 dias" },
      { id: 3, name: "Últimos 20 dias" },
      { id: 4, name: "Últimos 30 dias" },
      { id: 5, name: "Hoje" },
      { id: 6, name: "Ontem" },
      { id: 7, name: "Essa Semana" },
      { id: 8, name: "Última Semana" },
      { id: 9, name: "Esse Mês" },
      { id: 10, name: "Último Mês" },
      { id: 11, name: "Este Ano" },
    ],
  });

  async function getData() {
    const apiCols = [];
    const apiPicking = [];
    const apiGetting = [];
    const apiOtimizing = [];
    const apiReoperation = [];
    const apiReverse = [];
    const apiMarket = [];
    const apiBackup = [];

    let url = `/forklift-idleness-operation-place?start=${state.startDate}&end=${state.endDate}`;
    url += state.forkliftID ? "&forklift_number=" + state.forkliftID : "";
    url += state.operationID ? "&operation_place_id=" + state.operationID : "";
    url += state.resultBy ? "&result_by=" + state.resultBy : "day";
    const { data } = await api.get(url);

    const rawData = _(data.data).groupBy("localdate").value();

    // Gera as Datas
    const keys = _.keys(rawData);

    //  Formata Colunas
    keys.forEach((item) => {
      if (state.resultBy == 'day') {
        apiCols.push(moment(item).utc().format("DD MMM YYYY"));
      } else {
        apiCols.push(item);
      }
    });

    // Mapeia os Resultados
    data.data.forEach(({ name, localdate, ociosidade_percentual }) => {
      switch (name) {
        case "Otimização":
          // apiOtimizing.push(parseInt(ociosidade_percentual));
          apiOtimizing.push({ date: localdate, value: ociosidade_percentual });
          break;
        case "Picking":
          // apiPicking.push(parseInt(ociosidade_percentual));
          apiPicking.push({ date: localdate, value: ociosidade_percentual });
          break;
        case "Recebimento":
          // apiGetting.push(parseInt(ociosidade_percentual));
          apiGetting.push({ date: localdate, value: ociosidade_percentual });
          break;
        case "Reoperação":
          // apiReoperation.push(parseInt(ociosidade_percentual));
          apiReoperation.push({ date: localdate, value: ociosidade_percentual });
          break;
        case "Reversa":
          // apiReverse.push(parseInt(ociosidade_percentual));
          apiReverse.push({ date: localdate, value: ociosidade_percentual });
          break;
          case "Supermercado":
            // apiReoperation.push(parseInt(ociosidade_percentual));
            apiMarket.push({ date: localdate, value: ociosidade_percentual });
            break;
            case "Backup":
              // apiReoperation.push(parseInt(ociosidade_percentual));
              apiBackup.push({ date: localdate, value: ociosidade_percentual });
              break;
        default:
          break;
      }
    });

    const processValuesByDate = (object) => {
      let itens = [];
      keys.forEach(item => {
        const found = object.find(element => element.date == item);
        if (found) {
          if(found.value == null){
            itens.push(0)
          } else {
            itens.push(found.value.toFixed(1)); 
          }
        } else {
          itens.push(null);
        }
      });
      return itens;
    };

    setState((prev) => ({
      ...prev,
      cols: apiCols,
      otimizing: processValuesByDate(apiOtimizing),
      picking: processValuesByDate(apiPicking),
      reverse: processValuesByDate(apiReverse),
      reoperation: processValuesByDate(apiReoperation),
      getting: processValuesByDate(apiGetting),
      market: processValuesByDate(apiMarket),
      backup: processValuesByDate(apiBackup),
      loading: false,
    }));

    //console.log('testando dados', data.data)

    //console.log('api optimizing',
    //  apiOtimizing,
    // apiPicking,
    //   apiReverse,
    //   apiReoperation,
    //   apiGetting
    // );
  }

  async function initData() {
    const forklifts = await api.get("/forklifts");
    const operations = await api.get("/operation-places");
    setState((prev) => ({
      ...prev,
      forklifts: forklifts.data,
      operations: operations.data,
    }));
  }

  function resetForklift() {
    setState(prev => ({
      ...prev,
      forkliftID: null
    }))
  }

  // Watch the data
  useEffect(() => {
    getData();
  }, [state.startDate, state.endDate, state.forkliftID]);

  useEffect(() => {
    resetForklift();
    getData();
  }, [state.operationID]);

  // First Loading Use Effect
  useEffect(() => {
    initData();
  }, []);

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const changeForkliftID = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      forkliftID: value,
    }));
  };

  const changeOperationID = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      operationID: value,
    }));
  };

  const changeDateRange = (e) => {
    const { value } = e.target;
    if (value == 1) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 2) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 3) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(20, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 4) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 5) {
      setState((prev) => ({
        ...prev,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 6) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 7) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 8) {
      const firsDayWeek = moment().startOf("isoWeek");
      const lastWeek = firsDayWeek.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastWeek.startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: lastWeek.endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 9) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 10) {
      const firstDayMonth = moment().startOf("month");
      const lastMonth = firstDayMonth.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: lastMonth.endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 11) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().endOf("year").format("YYYY-MM-DD"),
        resultBy: 'month'
      }));
    }
  };

  return (
    <Paper>
      <Container>
        <h5
          style={{ color: "grey", textAlign: "center", fontWeight: "bolder" }}
        >
          Ociosidade Máquinas - Evolução Local de Operação
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flex: 1,
            marginTop: "30px",
          }}
        >
          <Select
            displayEmpty
            value={state.operationID}
            onChange={changeOperationID}
            style={{ width: "223px", height: "48px" }}
            placeholder="Empilhadeiras"
          >
            <MenuItem key="all" value={null}>
              Operações
            </MenuItem>
            {state.operations.length > 0 &&
              state.operations.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
          <Select
            displayEmpty
            value={state.forkliftID}
            onChange={changeForkliftID}
            style={{ width: "223px", height: "48px" }}
            placeholder="Empilhadeiras"
          >
            <MenuItem key="all" value={null}>
              Empilhadeiras
            </MenuItem>
            {state.forklifts.length > 0 &&
              state.forklifts
                .filter((item) => {
                  if (!state.operationID) return true;
                  if (state.operationID == item.operation_place_id) return true;
                })
                .map(({ id, forklift_number }) => (
                  <MenuItem key={id} value={forklift_number}>
                    {forklift_number}
                  </MenuItem>
                ))}
          </Select>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(state.startDate, "YYYY-MM-DD")}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Final"
              value={moment(state.endDate, "YYYY-MM-DD")}
              onChange={handleEndDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
          <Select
            displayEmpty
            value={state.dateRangeID}
            onChange={changeDateRange}
            style={{ width: "223px", height: "48px" }}
            placeholder="Período"
          >
            <MenuItem key="all" value={null}>
              Período
            </MenuItem>
            {state.dateRange.length > 0 &&
              state.dateRange.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div style={{ padding: "30px 0", minHeight: 300 }}>
          {!state.loading && (
            
            <Chart
              options={options(state.cols)}
              series={[
                {
                  name: "Picking",
                  data: state.picking,
                },
                {
                  name: "Recebimento",
                  data: state.getting,
                },
                {
                  name: "Otimização",
                  data: state.otimizing,
                },
                {
                  name: "Reoperação",
                  data: state.reoperation,
                },
                {
                  name: "Reversa",
                  data: state.reverse,
                },
                {
                  name: "Supermercado",
                  data: state.market
                },
                {
                  name: "Backup",
                  data: state.backup
                }
              ]}
              type="bar"
              height={350}
            />
          )}
        </div>
      </Container>
    </Paper>
  );
}
