import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import api from '../../../../../core/services/api'

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//material-ui
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip';


//custom components
import WhirpoolSelect from "../../../components/WhirpoolSelect";

function CreateForm(props) {
  const [state, setState] = React.useState({
    packages_id: "",
    packages_list: props.packages,
    disabled: false
  });
 
  const [it, setIt] = React.useState("");
  const [origin, setOrigin] = React.useState("");
  const [provider_id, setProvider] = React.useState("");
  const [send_receive, setSendreceive] = React.useState("");
  const [packages_id, setPackagesid] = React.useState([]);
  const [quantity, setQuantity] = React.useState([]);
  const [open, setOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      packages_list: props.packages,
      disabled: false
    }));
    setIt("");
    setOrigin("");
    setProvider("");
    setSendreceive("");
    setPackagesid([]);
    setQuantity("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendReceive = (event) => {
    setSendreceive(event.target.value);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    
    const obj = {
      it: it,
      origin: origin,
      provider_id: provider_id,
      send_receive: send_receive,
      package_list: packages_id
    }
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    console.log('você adicionou obj', obj)
    postPlanning(obj);
    
    setOpen(false)
  }
  async function postPlanning(obj){
    try {
      await api.post(`planning`, obj);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }
  function getPackagename(id){
    const name = state.packages_list.filter((page) => {return page.id === id}).map((page) => {return page.name})
    return name;
    
  }
  const handleDelete = (id) => () => {
    setPackagesid((packages) => packages_id.filter((packages) => packages !== id));
  };
  const handlePackagesAdd = () => {
    const packs = packages_id.filter((packs) => {return packs.package_id === state.packages_id})
    if (packs.length > 0){
      packs[0].amount = parseInt(packs[0].amount)+parseInt(quantity)
      setPackagesid([...packages_id])
    } else {
      setPackagesid([...packages_id, {package_id: state.packages_id, amount: quantity}])
    }
  }

  return (
    <div>
      <Fab
        color="primary"
        style={{ zIndex: 9999, position: "fixed", bottom: "10%", right: "1%" }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      {/* 
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Nova Empilhadeira
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Cadastar novo planejamento</DialogTitle>

        <DialogContent>
          <TextField
            //error={state.error ? (forklift_number ? false : true) : false}
            id="it"
            name="it"
            label="IT de Transporte"
            type="text"
            value={it}
            onChange={(e) => setIt(e.target.value)}
            fullWidth
          />
          <TextField
            //error={state.error ? (forklift_number ? false : true) : false}
            id="origin"
            name="origin"
            label="Origem"
            type="text"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
            fullWidth
          />
          <TextField
            //error={state.error ? (forklift_number ? false : true) : false}
            id="provider_id"
            name="provider_id"
            label="Fornecedor"
            type="text"
            value={provider_id}
            onChange={(e) => setProvider(e.target.value)}
            fullWidth
          />
           <div style={{ marginTop: 12, marginBottom: 12 }}>
                  <Grid container  alignItems="flex-end" justify="space-between" spacing={2}>
                      <Grid item xs={6}> <WhirpoolSelect
                  //error={state.error}
                  label="Pacotes"
                  value={state.packages_id}
                  name="packages_id"
                  data={state.packages_list}
                  onChange={(event) => handleChange(event)}
                /></Grid>
                <Grid item>
                <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="quantity"
                  name="quantity"
                  label="Quantidade"
                  type="text"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  
                />
                </Grid>
                <Grid item>
                <Button
                 onClick={() => handlePackagesAdd()}>Adicionar</Button>
                </Grid>
                  </Grid>
              </div>
              <div style={{display: 'flex',justifyContent: 'center',
    flexWrap: 'wrap', listStyle: 'none'}}>
              {packages_id.map((data) => {
        return (
          <li key={data.package_id}>
        
            <Chip
              label={state.packages_list ? getPackagename(data.package_id) +": "+data.amount: ""}
              onDelete={handleDelete(data)}
            />
          </li>
        );
      })}</div>
          <FormControl fullWidth>
        <InputLabel>Situação</InputLabel>
        <Select
          value={send_receive}
          onChange={handleSendReceive}
         
        >
          <MenuItem value={1}>Enviar</MenuItem>
          <MenuItem value={2}>Receber</MenuItem>
        </Select>
      </FormControl>
             
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
