import React, { useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";

//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";

//@material-ui
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getDefaultNormalizer } from "@testing-library/react";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";

function TableForklifts() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    forklifts: [],
    forklift_types: [],
    forklift_operations: [],
  });

  useEffect(() => {
    getForkliftType();
    getOperationPlace();
    getData();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getData() {
    try {
      const { data } = await api.get(`forklifts?active=all`);
      const filterdata = data.filter((item) => item.excluded !== true);
      setState((prev) => ({
        ...prev,
        forklifts: filterdata,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getForkliftType() {
    try {
      const { data } = await api.get(`forklift-types`);

      setState((prev) => ({
        ...prev,
        forklift_types: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getOperationPlace() {
    try {
      const { data } = await api.get(`operation-places`);

      setState((prev) => ({
        ...prev,
        forklift_operations: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function deleteForklift(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar empilhadeira " + item.forklift_number
        )
      ) {
        await api.delete(`forklift-delete/` + item.id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <>
      <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm
            //datas
            forklift_types={state.forklift_types}
            forklift_operations={state.forklift_operations}
            getData={getData}
          />
        </Grid>
      </Grid>
      <MaterialTable
        columns={[
          {
            title: "Número Máquina",
            field: "forklift_number",
            type: "numeric",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },

          {
            title: "Tipo",
            field: "forklift_type_id",
            cellStyle: { padding: 10, textAlign: "left", border: "#fff" },
            render: (rowData) =>
              state.forklift_types
                .filter((item) => {
                  return item.id === rowData.forklift_type_id;
                })
                .map((item) => {
                  return item.name;
                }),
          },
          {
            title: "Local Operação",
            field: "operation_place_id",
            cellStyle: { padding: 10, textAlign: "left", border: "#fff" },
            render: (rowData) =>
              state.forklift_operations
                .filter((item) => {
                  return item.id === rowData.operation_place_id;
                })
                .map((item) => {
                  return item.name;
                }),
          },
          {
            title: "Tag",
            field: "name",
            type: "numeric",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
          {
            title: "Mac Adress",
            field: "macaddress",
            type: "numeric",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
          {
            title: "Status",
            field: "active",
            cellStyle: { padding: 10, textAlign: "left", border: "#fff" },
            render: (rowData) =>
              rowData.active ? <p>Ativo</p> : <p>Inativo</p>,
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { padding: 0, textAlign: "left", border: "#fff" },
            render: (rowData) => (
              <>
                <FormGroup row>
                  <EditForm
                    numero={rowData.forklift_number}
                    operation_place_id={rowData.operation_place_id}
                    forklift_type_id={rowData.forklift_type_id}
                    tag={rowData.name}
                    macaddress={rowData.macaddress}
                    active={rowData.active}
                    forklift={rowData}
                    system_client_id={rowData.system_client_id}
                    //datas
                    forklift_types={state.forklift_types}
                    forklift_operations={state.forklift_operations}
                    getData={getData}
                  />
                  <IconButton
                    color="secondary"
                    onClick={() => deleteForklift(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.forklifts}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            padding: 10,
            textAlign: "left",
            backgroundColor: "#e8ebef",
            border: "#fff",
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />{" "}
    </>
  );
}

export default TableForklifts;
