import React, { useEffect, useState } from "react";
import { Source, Layer } from "react-map-gl";
import api from "../../../core/services/api";
import streets from './streets.json';

export default function () {
  // const [streets, setStreets] = useState(null);

  // useEffect(() => {
  //   getStreets();
  // }, []);

  // async function getStreets() {
  //   const { data } = await api.get("/roads/streets");

  //   const streets = JSON.parse(data.geom);

  //   setStreets(streets);
  // }

  // if (streets) {
    return (
      <Source type="geojson" data={JSON.parse(streets.geom)}>
        <Layer
          id="route"
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "#fff",
            "line-width": 8,
          }}
        />
      </Source>
    );
  // }

  // return null;
}
