import React, { useState, useEffect } from 'react';

import { Alert, ImgAlert, ContainerIMG, MsgAlert, ContainerMSG, ButtonExcludAlert } from './styles'
import alertError from '../../assets/icons/exclamation-mark.svg'


function NewFenceButton(props) {

    return (
            <Alert> 
                <ContainerIMG>
                    <ImgAlert src={alertError}/> 
                </ContainerIMG>

                <ContainerMSG>
                    <MsgAlert>03/04 - 14:52 • A máquina 847 está com o consumo acima do estipulado para o mês </MsgAlert>
                </ContainerMSG>

                <ButtonExcludAlert>Marcar como visualizado</ButtonExcludAlert>

            </Alert>
    )

}
export default NewFenceButton;