import React from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'


function Department(){

    const departments = [
        {name: "Departamento de Tecnologia"},
        {name: "Departamento de Administração"}
    ]

    return (
        <Main color="#f7f7f7" title="Departamentos">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
          <CreateForm
            //datas
            //getData={getData}
          />
        </Grid>
      </Grid>
        <MaterialTable
        columns={[
          {
            title: "Departamento",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left", width: '80%' },
            headerStyle: {borderBottom: '#fff'}
          },
         
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: {paddingRight: 20, textAlign: "right"},
            render: (rowData) => (
              <>
                <FormGroup row style={{justifyContent: 'flex-end'}}>
                  <EditForm department={rowData} /> 
                  <IconButton
                    color="secondary"
                   //onClick={() => deleteCarrier(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={departments}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
            
            
          },
          rowStyle: (x) => {
            if (x.tableData.id == 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          }
         /*  rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          }, */
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
    )
}

export default Department;