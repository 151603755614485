import React from 'react'
import {useStyles} from './styles'


//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import CallMade from "@material-ui/icons/CallMade";
import CallReceived from "@material-ui/icons/CallReceived";

//others components
import { LoadingContainer } from "./styles";
import { Title, Value, Prev, PrevValue, PrevLabel } from "./styles";



function Transit({title, value, last_value}){
    const classes = useStyles();
    return(
        <Card className={classes.root}>
        <CardHeader
          action={
            <Fab disabled style={{ backgroundColor: "#dff6d7" }}>
              <MoveToInboxIcon style={{ color: "#5ed237" }} />
            </Fab>
          }
          title="Embalagens em trânsito"
        />
  
        <CardContent>
          
           
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Value>{value}</Value>
    {/*}    <Prev>
          <PrevValue color={
              last_value && last_value.result
                ? last_value && last_value.direction == "up"
                  ?  "#5ED237"
                  :"#EC273B"
                : "#909090"
            }  >
               {last_value && last_value.result ? (
              last_value && last_value.direction == "up" ? (
                <CallMade />
              ) : (
                <CallReceived />
              )
            ) : (
              ""
            )}
           {last_value && last_value.result
              ? last_value.result.toFixed(1)
              : 0.0}
            %
          </PrevValue>
          <PrevLabel>em relação à semana anterior</PrevLabel>
           </Prev> */}
      </div>

        </CardContent>
        <CardActions></CardActions>
      </Card>
    )
}


export default Transit;