import React from 'react';

import Grid from '@material-ui/core/Grid';
import forkIcon from '../../assets/icone_empilhadeira.png'
import NearMe from '@material-ui/icons/NearMe';

import { setColor } from './Utils';

import { Vehicle, VehicleData, VehicleNumber, IconContainer } from './styles';

export default ({machine, navigate}) => {
    return(
        <Grid item xs={12}>
            <Vehicle>
                <VehicleData>
                    <IconContainer color={setColor(machine.active ? machine.place : 'Ociosa')}>
                        <img src={forkIcon} alt="" width="20" height="auto" />
                    </IconContainer>
                    <VehicleNumber>{machine.name}</VehicleNumber>
                </VehicleData>
                <NearMe onClick={() => navigate(machine.latitude, machine.longitude)}  style={{color:"#7C8796"}} />
            </Vehicle>
        </Grid>
    )
};
