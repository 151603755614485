import React, { Component } from "react";
import { TopBar, Logo } from "./styles";
import Menu from "../Menu";
import logoTrackage from "../../assets/tkg_move.png";
import logoWhirlpool from "../../assets/logo-whirlpool.png";

// import { Container } from './styles';
const usuario = JSON.parse(localStorage.getItem("usuario"));

const logo =
  usuario && usuario.system_client_id == 5 ? logoWhirlpool : logoTrackage;

function Main({ children, color, title }) {
  return (
    <div
      style={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "row",
        background: color,
      }}
    >
      <Menu />
      <div style={{ width: "100%", marginLeft: "50px", background: color }}>
        <TopBar style={{ zIndex: 100 }}>
          <Logo src={logo} />
          {title ? (
            <p
              style={{
                margin: 0,
                borderLeft: "0.5px solid #7d7d7dfc",
                paddingLeft: 20,
                marginLeft: 20,
                fontWeight: "initial",
                color: "#1b1919",
              }}
            >
              {title}
            </p>
          ) : null}
        </TopBar>
        <div style={{ margin: 20, paddingTop: 60, background: color }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Main;
