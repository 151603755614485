import React, {useEffect, useState} from 'react'
import Main from '../../components/Main'
import Alert from "../../components/Alert";
import api from '../../../../core/services/api'



//material-ui
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



function Profile(){
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const [state, setState] = useState({
        disabled: false,
        check: false,
        oldUser: usuario,
        message: "Usuário atualizado com sucesso!",
        alert: "success",
    })
    const [username, setUsername] = useState(usuario.username);
    const [email, setEmail] = useState(usuario.email);
    const [passwordProfile, setPassword] = useState("");
    const [password2Profile, setPassword2] = useState("");
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        setPassword("");
        setPassword2("");
        setState((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
      const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      async function handleSubmit() {
          try {
            if(!state.check){
                //SEM ALTERAR A SENHA
                const newUser = {
                  id: state.oldUser.id,
                  username: username.toLowerCase(),
                  email: email.toLowerCase(),
                  group_id: state.oldUser.group_id
                };
                 console.log('Atualizando perfil SEM ALTERAR SENHA : ', newUser);
                 await api.put(`users`, newUser);
                 setState((prev) => ({...prev, message: "Usuário atualizado com sucesso!", alert: "success"}));
                 setOpen(true);
              } else{
                //ALTERANDO A SENHA
                if(password2Profile == passwordProfile){
                    const newUser = {
                        id: state.oldUser.id,
                        username: username.toLowerCase(),
                        email: email.toLowerCase(),
                        group_id: state.oldUser.group_id,
                        password: passwordProfile
                      };
                        console.log('Atualizando perfil COM ALTERAR SENHA : ', newUser)
                        await api.put(`users`, newUser);
                        setState((prev) => ({...prev, message: "Usuário atualizado com sucesso!", alert: "success"}));
                        setOpen(true);
                } else {
                    setState((prev) => ({...prev, message: "Confirmação de senha diferente.", alert: "error"}));
                    setOpen(true);
                }
               
              }
          } catch (error) {
              console.warn(error)
          }
          
      }

    return (
        <Main color="#f7f7f7" title="Perfil">
            <Card style={{maxWidth: 450}}>
            <CardContent>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            /> </div>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
                fullWidth
                label="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            /></div>
           
             <div style={{ marginTop: 12, marginBottom: 12, display: state.check ? 'block' : 'none' }}>
             <FormControl fullWidth>
              <InputLabel>Senha</InputLabel>
              <Input
                  error={state.error ? true : false}
                  fullWidth
                  id="password"
                  name="password"
                  label="Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={passwordProfile}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                       
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                /></FormControl>
              </div>
              <div style={{ marginTop: 12, marginBottom: 12, display: state.check ? 'block' : 'none' }} >
              <FormControl fullWidth>
                <InputLabel>Confirmar senha</InputLabel>
                <Input
                  error={state.error ? true : false}
                  fullWidth
                  id="password2"
                  name="password2"
                  label="Confirme sua Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password2Profile}
                  onChange={(e) => setPassword2(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{" "}</FormControl>
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
              
              <FormControlLabel
        control={
          <Checkbox
            checked={state.check}
            onChange={handleChange}
            name="check"
            color="primary"
          />
        }
        label="Alterar senha"
      /> 
        </div>
            </CardContent>
            <CardActions>
            <Button
            
                variant="contained"
                color="primary"
                disabled={state.disabled}
                onClick={handleSubmit}
            >
                Salvar
            </Button>
            </CardActions>
            </Card>
            <Alert open={open} message={state.message} alert={state.alert} setOpen={() => setOpen()}/>
        </Main>
    )
}

export default Profile;