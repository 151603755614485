import React from 'react';


//@material-ui
import Grid from '@material-ui/core/Grid'


//custom components
import IdleOperators from './IdleOperators';
import IdleOperatorsPerformanceOperator from './IdleOperatorsPerformanceOperator';
import IdleOperatorsPercent from './IdleOperatorsPercent'

function OperatorsContent(){
    
    return (
    <Grid container spacing={3}>
        <Grid item>
           <IdleOperators />
        </Grid>
        <Grid item>
            <IdleOperatorsPerformanceOperator />
        </Grid>
        <Grid item>
            <IdleOperatorsPercent />
        </Grid>
    </Grid>
      
      
    )
}

export default OperatorsContent;