import React, { useState, useEffect } from "react";
import api from "../../../../../../../core/services/api";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import options from "./options";
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//custom components
import {
  BlockedProgress,
  SegregatedProgress,
  StructuredProgress,
  TravelProgress,
} from "./Progress";
import { DivWrapper, DivHeader, ContainerIMG, Placeholder } from "./styles";

//@material-ui
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

//@material icons
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function Table() {
  const [state, setState] = useState({
    series: [
      {
        name: "JAN",
        data: [0],
      },
    ],
    operations: [],
    operation_id: null,
    reasons: [],
    reason_id: null,
    operators: [],
    operator_id: null,
    forklifts: [],
    forklift_id: null,
    legends: [],
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(1, "year").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getFilters();
    getData();
  }, [
    state.operation_id,
    state.reason_id,
    state.forklift_id,
    state.operator_id,
    state.endDate,
    state.startDate,
  ]);

  async function getFilters() {
    try {
      const operations = await api.get(`/operations-filter`);
      const reasons = await api.get(`/reasons-filter`);
      const operators = await api.get(`/operators-filter`);
      const forklifts = await api.get(`/forklifts-filter`);
      setState((prev) => ({
        ...prev,
        operations: operations.data || [],
        reasons: reasons.data || [],
        operators: operators.data || [],
        forklifts: forklifts.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getData() {
    try {
      let operations = "";
      let reasons = "";
      let operators = "";
      let forklifts_filter = "";

      if (state.operation_id != null)
        operations = `&operation_place=${state.operation_id}`;
      if (state.reason_id != null) reasons = `&reason=${state.reason_id}`;
      if (state.operator_id != null)
        operators = `&operator=${state.operator_id}`;
      if (state.forklift_id != null)
        forklifts_filter = `&forklift=${state.forklift_id}`;

      const data = await api.get(
        `/quantity-pauses-evolution?start_day=${state.startDate}&end_day=${state.endDate}` +
          operations +
          reasons +
          operators +
          forklifts_filter
      );
      let legends = [];
      let series = [];

      data.data.forEach((item, index) => {
        let exist_month = series.filter((item2, index) => {
          if (item.month < 10) {
            return item2.name == item.year + "0" + item.month;
          } else {
            return item2.name == item.year + "" + item.month;
          }
        });
        let exist = legends.includes(item.group);
        if (!exist) legends.push(item.group);
        if (exist_month.length == 0) {
          if (item.month < 10) {
            series.push({ name: item.year + "0" + item.month, data: [] });
          } else {
            series.push({ name: item.year + "" + item.month, data: [] });
          }
        }
      });
      //SORTING SERIES
      series.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      //SERIES
      legends.forEach((item) => {
        series.forEach((item2) => {
          item2.data.push(0);
        });
      });

      data.data.forEach((item, index) => {
        let idx = legends.indexOf(item.group);
        let idx_month = null;
        series.filter((item2, index) => {
          if (item.month < 10) {
            if (item2.name == item.year + "0" + item.month) {
              idx_month = index;
            }
          } else {
            if (item2.name == item.year + "" + item.month) {
              idx_month = index;
            }
          }
        });
        series[idx_month].data[idx] += parseInt(item.quantity);
      });

      //NAME SERIES
      series.forEach((item, index) => {
        let month = item.name.slice(4, 99);
        let year = item.name.slice(0, 4);
        if (month == "01") month = "JAN ";
        if (month == "02") month = "FEV ";
        if (month == "03") month = "MAR ";
        if (month == "04") month = "ABR ";
        if (month == "05") month = "MAI ";
        if (month == "06") month = "JUN ";
        if (month == "07") month = "JUL ";
        if (month == "08") month = "AGO ";
        if (month == "09") month = "SET ";
        if (month == "10") month = "OUT ";
        if (month == "11") month = "NOV ";
        if (month == "12") month = "DEZ ";
        series[index].name = month + year;
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <Grid container justify="center" alignItems="center" spacing={3}>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <Select
          MenuProps={{ disableScrollLock: true }}
          fullWidth
          variant="outlined"
          displayEmpty
          value={state.operation_id}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              operation_id: e.target.value,
            }))
          }
        >
          <MenuItem key="all" value={null}>
            Operações
          </MenuItem>
          {state.operations.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <Select
          MenuProps={{ disableScrollLock: true }}
          fullWidth
          variant="outlined"
          displayEmpty
          value={state.reason_id}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              reason_id: e.target.value,
            }))
          }
        >
          <MenuItem key="all" value={null}>
            Motivo Pausa
          </MenuItem>
          {state.reasons.map((item) => {
            return (
              <MenuItem key={item.id} value={item.reason}>
                {item.reason}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <Select
          MenuProps={{ disableScrollLock: true }}
          fullWidth
          variant="outlined"
          displayEmpty
          value={state.operator_id}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              operator_id: e.target.value,
            }))
          }
          //input={<BootstrapInput />}
        >
          <MenuItem key="all" value={null}>
            Operador
          </MenuItem>
          {state.operators.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk={true}
            inputVariant="outlined"
            variant="inline"
            format="dd/MM/yyyy"
            label="Data Inicial"
            value={moment(state.startDate, "YYYY-MM-DD")}
            onChange={handleStartDate}
            KeyboardButtonProps={{
              "aria-label": "Data Inicial",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk={true}
            inputVariant="outlined"
            variant="inline"
            format="dd/MM/yyyy"
            label="Data Final"
            value={moment(state.endDate, "YYYY-MM-DD")}
            onChange={handleEndDate}
            KeyboardButtonProps={{
              "aria-label": "Data Final",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>

      <Grid item lg={12} sm={12} md={12}>
        <Chart
          options={options(state.legends)}
          series={state.series}
          type="bar"
          height={450}
        />
      </Grid>
    </Grid>
  );
}

export default Table;
