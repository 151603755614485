import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: '100%',
    minWidth:180,
    margin:0,
    alignItems:'center',
    justifyContent:'center',
    marginRight:6,
    
  },
  root2: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    
    height: 56,
    borderRadius: 4,
    boxSizing: "border-box",
    border: "1px solid rgba(133, 133, 133, 0.4)",
    background: 'white',
  },
  
  input: {
    margin: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    outline: "none",
    "&:focus": {
      outlineStyle:"none",
    } ,
    
  },
}));

export default function CustomizedInputBase() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div component="form" className={classes.root2}> 
        <InputBase
          className={classes.input}
          placeholder="Buscar"
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>
    </div>
  );
}