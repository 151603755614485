import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from 'material-table'

//custom components
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";

//material-ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";


function UsersPage() {
  const [state, setState] = React.useState({
    users: [],
    groups: [],
  });
  useEffect(() => {
    getGroups();
    getData();
  }, []);


  async function getData() {
    try {
      const data = await api.get(`users`);
      setState((prev) => ({
        ...prev,
        users: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getGroups() {
    try {
      const data = await api.get(`groups`);
      setState((prev) => ({
        ...prev,
        groups: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteUser(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o usuário " + item.username
        )
      ) {
        await api.delete(`users/` + item.id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <Main color="#eeeeee" title="Usuários">
      <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm
            //datas
           groups={state.groups ? state.groups : []}
           getData={getData}
          />
        </Grid>
      </Grid>
       <MaterialTable
      title="Simple Action Preview"
      columns={[
        { title: 'Usuário', field: 'username', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, },
        { title: 'E-mail', field: 'email', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, },
        { title: 'Grupo', field: 'group_id', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => state.groups.data ? state.groups.data.filter((item) => {return item.id === rowData.group_id}).map((item) => { return item.name }) : rowData.group_id},
        {
          field: "actions",
          title: "Ações",
          cellStyle: { padding: 0, textAlign: "left", border: "#fff" },
          render: (rowData) => (
            <>
              <FormGroup row>
                <EditForm
                  oldUser={rowData}
                  //datas
                  groups={state.groups ? state.groups : []}
                  getData={getData}
                />
                <IconButton
                  color="secondary"
                  onClick={() => deleteUser(rowData)}
                >
                  <DeleteIcon />
                </IconButton>{" "}
              </FormGroup>
            </>
          ),
        },
      ]}
      data={state.users.data}        
      options={{
        pageSize: 10,
        actionsColumnIndex: -1,
        search: false,
        showTitle: false,
        headerStyle: {
          padding: 10,
          paddingLeft: 40,
          textAlign: "left",
          backgroundColor: "#e8ebef",
          border: "#fff",
        },
        rowStyle: (x) => {
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f3f5f8" };
          }
        },
      }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "none" }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      style={{
        borderRadius: 5,
      }}
      localization={{
        body: {
            emptyDataSourceMessage: 'Carregando dados'
        }
    }}
    />
      
    </Main>
  );
}

export default UsersPage;
