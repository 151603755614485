import React, { useState, useEffect, useRef } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import {DivWrapper, DivHeader, DivList, ContainerIMG, Placeholder} from './styles'


function Dropdown({ title, items}) {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [hover, setHover] = useState(false);
  const toggle = () => setOpen(state => !state);
  const node = useRef(null);

  
  const handleClickOutside = (element) => {
    if (node.current.contains(element.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  

  function handleOnClick(item) {
    setSelection(item)
 
    toggle()
  }

  return (
    <DivWrapper ref={node}>
      <DivHeader
        tabIndex={0}
        role="button"
        onKeyPress={(e) => toggle()}
        onClick={(e) => toggle()}

        onMouseEnter={(e)=>{
          setHover(true);
        }}

        onMouseLeave={(e)=>{
          setHover(false);
        }}
      >
          <p>{selection == null ? <Placeholder> {title} </Placeholder> : selection.value }</p>

          <ContainerIMG>
            <ArrowDropDownIcon 
              style={{
                color: ( hover ? 'black' : '#C2CBD8' ),
                cursor:'pointer',
                width: '40px',
                height: '40px',
                fontSize:40,
                transition:'0.3s',
                }} 
                fontSize='inherit' 
            />
          </ContainerIMG>
            
      </DivHeader>
      {open && (
        <DivList>
          {items.map((item, index) => (
            <li key={index} > 
              <button type="button" onClick={(e) => handleOnClick(item)}>
                <span>{item.value}</span>
                
              </button>
            </li>
          ))}
        </DivList>
      )}
    </DivWrapper>
  );
}

export default Dropdown;
