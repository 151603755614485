import React from 'react';
import { CustomMarker } from "./styles";
import { Marker } from "react-map-gl";

export function processDistance(data) {
  const resp = {
    origin: 0,
    destiny: 0,
    end: 0,
    total: 0,
  };

  // Se conter array
  if (data && data.dinamic_route) {
    Object.keys(data.dinamic_route).forEach((item) => {
      resp[item] = (data.dinamic_route[item].distance / 1000).toPrecision(2);
      resp.total += parseFloat(resp[item]);
    });
  }

  return resp;
}

// Processa os Tamanhos
export function processSizes(data) {
  if (data && data.parts_data) {
    console.log(data.parts_data);
    let box = 0;
    let pallet = 0;
    data.parts_data.forEach((item) => {
      if (item.pack_type == "box") box += item.size;
      else pallet += item.size;
    });

    box = box / 1000;
    pallet = pallet / 1000;

    console.log(box);
    console.log(pallet);

    const suma_box = Math.round(box / (150000));
    const suma_pallet = Math.round(pallet / (200000));

    // const msg = `Serão utilizados ${cart_box} SUMA BOX e ${cart_pallet} SUMA PALLET`;

    return { suma_box, suma_pallet, box, pallet };
  }
}

export function calculateETA(data) {
  return Math.round((data * 1000) / (8 / 3.6) / 60);
}

export function processTimes(data) {
   const times = {
    time_supply_cart: 0,
    time_supply_line: 0,
    time_supply_return: 0,
    time_supply_total: 0
   }

  // Calcula tempos por parte do percurso
  data.parts_data.forEach(item => {
    Object.keys(times).forEach(key => {
      times[key] += item[key];
    })
  });

  // Arredonda os tempos e converte pra minutos
  Object.keys(times).forEach(key => times[key] = Math.round(times[key] / 60))

  // Tempo de percurso a 8km/h
  times['eta'] = calculateETA(processDistance(data).total);
  times['total'] = times.eta + times.time_supply_total;

  console.log('times', times);

  return times;
}

export function generateEven(arr, loop) {
  let aux = [];
  let result = [];

  aux = loop ? [...arr, arr[0]] : arr;

  console.log(loop);
  console.log(aux);

  aux.map((item, index) => {
    if (index !== 0 && index !== aux.length - 1) {
      result.push(item);
    }
    result.push(item);
  });

  return to2dimensions(result);
}

function to2dimensions(arr) {
  let result = [];
  let aux = [];
  arr.forEach((item, index) => {
    if (index % 2 === 0) {
      aux = [item];
    } else {
      aux.push(item);
      result.push(aux);
    }
  });
  return result;
}

// // export function renderMarkers({ markers, nodeList }) {
// //   function renderCustomMarker(pois) {
// //     return (
// //       <CustomMarker
// //         variant="body1"
// //         key={pois.node_id}
// //         active={nodeList.includes(pois.node_id)}
// //         color={pois.color}
// //         onClick={() => addNode(pois.node_id)}
// //       >
// //         {pois.name}{" "}
// //         {nodeList.includes(pois.node_id)
// //           ? ` - ${nodeList.indexOf(pois.node_id) + 1}`
// //           : null}
// //       </CustomMarker>
// //     );
// //   }

// //   let MarkerList = markers.map((item) => (
// //     <Marker
// //       offsetLeft={-20}
// //       offsetTop={-15}
// //       key={item.id}
// //       latitude={item.lat}
// //       longitude={item.lng}
// //     >
// //       {renderCustomMarker(item)}
// //     </Marker>
// //   ));

//   return MarkerList;
// }
