import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";


//custom components
import SDSelect from "../../../../components/SDSelect";

function EditForm(props) {
  const [state, setState] = useState({
    active: true,
    error: false,
    disabled: false,  
    module_id: [],
  });
  const [module_id, setModuleId] = useState("");
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setState({
      error: false,
      disabled: false,
      check: false,
      module_id: '',      
    })
    setModuleId(props.module_id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))  
  }

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    try {
      const modulo = {
        module_id: module_id,
        active: props.active
      }
      await api.put(`modulo/${props.modulo.id}`, modulo);
      props.getData();

    } catch (error) {
      console.warn(error)
    }
    console.log('Módulo editado com sucesso'.modulo)
    handleClose()
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Editar Módulo </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="module_id"
              label="ID do Modulo"
              type="text"
              fullWidth
              value={module_id}
              onChange={(e) => module_id.length < 12 ? setModuleId(e.target.value) : setModuleId(module_id.slice(0, -1))}
            />{" "}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
