import React, { useState, useEffect } from "react";

//custom components
import Main from "../../components/Main";
import TableShifts from "./TableShifts";
import api from "../../../../core/services/api";

//material-ui
import Grid from "@material-ui/core/Grid";
import { da } from "date-fns/locale";

function ManagementShifts() {
  const [state, setState] = useState([]);
  const [operations, setOperations] = useState([]);
  useEffect(() => {
    getOperationPlace();
    getData();
  }, []);

  async function getOperationPlace() {
    try {
      const { data } = await api.get(`operation-places`);

      setState((prev) => ({
        ...prev,
        operations_names: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      const { data } = await api.get(`operation-place-shifts?active=all`);

      const operations = {};

      function filteredCollect() {
        const collectedTrueKeys = []
        data.data.map((item => collectedTrueKeys.push(item.operation_place_id)))
        return collectedTrueKeys
      }

      function multiPropsFilter(){
        const filters = filteredCollect();
        const arrayFiltered = filters.filter((item, index) => filters.indexOf(item) === index)  
        for (let filter in arrayFiltered){
            operations['op'+filter] = data.data.filter((item) => {
              return item.operation_place_id == arrayFiltered[filter]
            })
          }
      }
      multiPropsFilter();
/*
      if(data.data[0].operation_place_id){
      const op1 = data.data.filter((item) => {
        return item.operation_place_id === 1;
      });
      const op2 = data.data.filter((item) => {
        return item.operation_place_id === 2;
      });
      const op3 = data.data.filter((item) => {
        return item.operation_place_id === 3;
      });
      const op4 = data.data.filter((item) => {
        return item.operation_place_id === 4;
      });
      const op5 = data.data.filter((item) => {
        return item.operation_place_id === 5;
      });
      const op6 = data.data.filter((item) => {
        return item.operation_place_id === 6;
      });
      const op7 = data.data.filter((item) => {
        return item.operation_place_id === 7;
      });
      operations.push(op1);
      operations.push(op2);
      operations.push(op3);
      operations.push(op4);
      operations.push(op5);
      operations.push(op6);
      operations.push(op7);
    } */

      setState((prev) => ({
        ...prev,
        operationShifts: data.data || [],
        operations: operations || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  function getOperationName(id) {
    try {
      const opName = state.operations_names.filter((item) => {
        return item.id === id;
      });
      return opName[0].name;
    } catch (error) {
      console.warn(error);
    }
  }
  return (
    <Main color="#eeeeee" title="Turnos">
      {state.operations
        ? Object.keys(state.operations).map((item) => {
          let operations = state.operations[item]
            return (
              <>
                <Grid container spacing={5}>
                  <Grid item lg={12}>
                    <TableShifts
                      data={operations}
                      title={getOperationName(operations[0].operation_place_id)}
                      operationsName={state.operations_names}
                      //methods
                      getOperationName={getOperationName}
                      getData={() => getData()}
                    />
                  </Grid>
                </Grid>
              </>
            );
          })
        : ""}
    </Main>
  );
}

export default ManagementShifts;
