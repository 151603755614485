import React, { useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

//custom components
import WhirpoolSelect from "../../../../components/WhirpoolSelect";

function CreateForm(props) {
  const [state, setState] = React.useState({
    forklift: "",
    forklift_type_id: "",
    forklift_types: props.forklift_types,
    operation_place_id: "",
    forklift_operations: props.forklift_operations,
    name: "",
    macaddress: "",
    active: false,
    forklift_number: "",
    system_client_id: "",
    error: false,
    disabled: false,
  });
  const [forklift_number, setNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [macaddress, setMacaddress] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      forklift_types: props.forklift_types,
      forklift_operations: props.forklift_operations,
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleSubmit() {
    if (
      !state.operation_place_id ||
      !state.forklift_type_id ||
      !name ||
      !forklift_number ||
      !macaddress
    ) {
      setState((prev) => ({
        ...prev,
        error: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
       disabled: true,
      }));
      postForklift();
    }
  }
  async function postForklift() {
    try {
      const newForklift = {
        name: name.toLowerCase(),
        macaddress: macaddress.toLowerCase(),
        system_client_id: state.system_client_id,
        forklift_number: forklift_number,
        operation_place_id: state.operation_place_id,
        active: state.active,
        forklift_type_id: state.forklift_type_id,
      };
      //console.log("nova forklift", newForklift);
      await api.post(`forklifts`, newForklift);
      setState("");
      setNumber("");
      setName("");
      setMacaddress("");
      setOpen(false);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <Fab
        color="primary"
        style={{ zIndex: 9999, position: "fixed", bottom: "10%", right: "1%" }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      {/* 
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Nova Empilhadeira
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Cadastar nova empilhadeira</DialogTitle>

        <DialogContent>
          {state ? (
            <>
              {" "}
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (forklift_number ? false : true) : false}
                  id="forklift_number"
                  name="forklift_number"
                  label="Número"
                  type="text"
                  value={forklift_number}
                  onChange={(e) => setNumber(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <WhirpoolSelect
                  error={state.error}
                  label="Tipo"
                  value={state.forklift_type_id}
                  name="forklift_type_id"
                  data={state.forklift_types}
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <WhirpoolSelect
                  error={state.error}
                  label="Local Operação"
                  value={state.operation_place_id}
                  name="operation_place_id"
                  data={state.forklift_operations}
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (name ? false : true) : false}
                  required
                  id="name"
                  label="Tag"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (macaddress ? false : true) : false}
                  required
                  id="macaddress"
                  label="Mac Address"
                  type="text"
                  value={macaddress}
                  onChange={(e) => setMacaddress(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.active}
                    onChange={(event) => handleChange(event)}
                    name="active"
                    color="primary"
                  />
                }
                label={state.active ? "Ativo" : "Inativo"}
              />
            </>
          ) : (
            ""
          )}
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
          disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
