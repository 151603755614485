import React, { useState, useEffect } from "react";
import api from '../../../../../../core/services/api'

import { debounce } from "lodash";
import { easeCubic } from "d3-ease";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Location from "@material-ui/icons/LocationOn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Mapbox Lib

import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, {
  Marker,
  FlyToInterpolator,
  Popup,
  NavigationControl,
} from "react-map-gl";



// Custom Components
import { MapContainer } from "./styles";
import PopupItem from "./PopupItem";

// Utils
import { countForklifts, setColor, randomCoordinates } from "./Utils";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#908bff",
    "&$checked": {
      color: "#908bff",
    },
    "&$checked + $track": {
      backgroundColor: "#908bff",
    },
  },
  checked: {},
  track: {},
})(Switch);

function MapScanners(props){
  const token =
    "pk.eyJ1IjoibHlwZWt1biIsImEiOiJja29hY2NwcWUwMDdrMm9vZXk1a2RtbnRkIn0.TpprfiUZGKn7gqjK8Wy3Fg";
  const [state, setState] = useState({
    is_open: false,
    scanners: props.scanners || [],
    operations: [],
    filter: "",
    popupInfo: null,
    machine_status: null,
    operation_status: null,
    forklift_status: null,
    satelite: true,
    status: {
      total: 0,
      inactives: 0,
      actives: 0,
      clamps: 0,
      forks: 0,
    },
    
  });

  
  useEffect(() => {
    getSettings();
    getScanners();
  }, []);

  const navigateTo = (latitude, longitude) => {
    const viewport = {
      ...state.viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: 18,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    };
    setState((prev) => ({ ...prev, viewport }));
  };

  async function getScanners() {
    try {
      const resp = await api.get(`dashboard-scanners`);
      setState((prev) => ({
        ...prev,
        scanners: resp.data.data || []
      }));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getSettings() {
    const settings = await api.get("/settings");
    setState((prev) => ({
      ...prev,
      viewport: {
        width: "100%",
        height: "100vh",
        latitude: settings.data.resource.coordinates.latitude || [],
        longitude: settings.data.resource.coordinates.longitude || [],
        zoom: 16,
      },
    }));
  }

  function resetPopup() {
    setState((prev) => ({
      ...prev,
      popupInfo: null,
    }));
  }

  function openPopup(scanner) {
    setState((prev) => ({
      ...prev,
      popupInfo: scanner,
    }));
  }

  function renderPopup() {
    return (
      state.popupInfo && (
        <Popup
          closeButton={true}
          tipSize={5}
          anchor="bottom-right"
          longitude={state.popupInfo.lon}
          latitude={state.popupInfo.lat}
          onClose={() => resetPopup()}
          closeOnClick={true}
        >
          <Grid container>
            <Grid item xs={4} md={4} sm={4}>
              <PopupItem title="Nome" value={state.popupInfo.name} />
            </Grid>
            <Grid item xs={8} md={8} sm={8}><PopupItem title="Status" value={state.popupInfo.scanner_active ? "Ativo" : "Inativo"} /></Grid>
            <Grid item xs={12} md={12} sm={12}>
              <PopupItem title="MacAddress" value={state.popupInfo.macaddress} />
            </Grid>
          </Grid>
        </Popup>
      )
    );
  }



  function renderMarker(index, forklift) {
    //const { latitude, longitude } = forklift;
    //const coordinates = randomCoordinates({ latitude, longitude }, 10);

    return (
      <Marker
        style={{
          zIndex: 9999,
          display: "block",
        }}
        key={index}
        longitude={forklift.longitude}
        latitude={forklift.latitude}
      >
        <div onClick={() => openPopup(forklift)}>
          <Location
            style={{
              display: "flex",
              position: "absolute",
              color: setColor(forklift.active ? forklift.place : "Ociosa"),
            }}
          />
        </div>
      </Marker>
    );
  }


  function toggleSatelite(e) {
    setState((prev) => ({
      ...prev,
      satelite: e.target.checked,
    }));
  }

  return (

    <MapContainer>
    <MapGL
      {...state.viewport}
      mapboxApiAccessToken={token}
      mapStyle={`mapbox://styles/mapbox/${
        state.satelite ? "satellite-v9" : "streets-v11"
      }`}
      onViewportChange={(viewport) =>
        setState((prev) => ({ ...prev, viewport }))
      }
    >
      <div
        style={{
          position: "inherit",
          margin: 25,
          bottom: "22%",
          right: 0,
        }}
      >
        {" "}
        <NavigationControl />
      </div>

      {state.scanners.map((scanner, index) => {
        if(scanner.lat && scanner.lon){
          return (
            <Marker
              style={{
                zIndex: 9999,
                display: "block",
              }}
              key={index}
              longitude={scanner.lon}
              latitude={scanner.lat}
            >
              <div onClick={() => openPopup(scanner)}>
                <Location
                  style={{
                    display: "flex",
                    position: "absolute",
                    color: scanner.scanner_active ? '#5ad545' : '#ec273b'
                  
                  }}
                />
              </div>
            </Marker>
          )
        }}
      )}
              {renderPopup()}
    </MapGL>
  </MapContainer>
  
  );
}

export default MapScanners;