import React, {useState} from 'react'
import MaterialTable, {MTableToolbar} from 'material-table'


function TableItem(props){

    const [packages, setPackages] = useState(props.packages);
    function getPackageName(id){
        const obj = packages.filter((packs) => {return packs.id === id})
        return obj[0]?.name || ''
    }

    return (
        <>
        <MaterialTable
          columns={[
            { title: 'Nome', field: 'package_id', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },  render: (rowData) => getPackageName(rowData.package_id), },
            { title: 'Quantidade', field: 'amount', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" } },
            { title: 'Enviadas', field: 'amount_send', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" } },
            { title: 'Recebidas', field: 'amount_received', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" } },
          ]}
          data={props.package_list || []}
          options={{
        
            actionsColumnIndex: -1,
            search: false,
            showTitle: false,
            headerStyle: {
              padding: 10,
              paddingLeft: 40,
              textAlign: "left",
              backgroundColor: "#e8ebef",
              border: "#fff",
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f3f5f8" };
              }
            },
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ display: "none" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          style={{
            borderRadius: 5,
          }}
        />
        </>
    )
}

export default TableItem;