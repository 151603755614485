import styled from 'styled-components';

export const Content = styled.div`
    padding: 0px 30px 0px 30px;
`

export const IconContainer = styled.div`
    color: white;
    margin: 0 auto;
    top: -25px;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background: ${props => props.color ? props.color : 'lightgrey'};
    display: flex;
    justify-content: center;
    align-items: center;
`