import React from "react";
import Main from "../../components/Main";

//custom components
import TableMachines from "./TableMachines";

function MachineList() {
  return (
    <Main color="#eeeeee" title="Máquinas">
      <TableMachines />
    </Main>
  );
}

export default MachineList;
