import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import api from '../../../../../core/services/api'

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



function CreateForm(props) {
  const [state, setState] = React.useState({
    disabled: false
  });
  const [name, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setName("");
    setState("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    
    const obj = {
      name: name
    }
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    console.log('passar api aqui', obj)
    postPackage(obj)
    setOpen(false)
  }
  async function postPackage(obj){
    try {
      await api.post(`packages`, obj);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <Fab
        color="primary"
        style={{ zIndex: 9999, position: "fixed", bottom: "10%", right: "1%" }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      {/* 
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Nova Empilhadeira
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Cadastar novo Pacote</DialogTitle>

        <DialogContent>
          <TextField
            //error={state.error ? (forklift_number ? false : true) : false}
            id="name"
            name="name"
            label="Nome"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        
             
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
