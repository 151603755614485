import './Card.css'
import Share from './share.svg'
import React, { useState } from 'react'

function factoryItem(entity) {
    return entity.map(e => (
        <p className='Item'><div className={e.status ? "Green" : "Red"}/>{e.name}</p>
    ))
}

function CardList(props) {
    const [state, setState] = useState({
        items: []
    })
    return (
        <div className='Card'>
            <div className='CardHeader'>
                <p>{props.title}</p>
                <div className='ImageCropper'>
                    <img src={Share} />
                </div>
            </div>
            <div className='Itens'>
                { factoryItem(props.entity) }
            </div>
        </div>
    )
}

export default CardList