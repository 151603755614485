import React from 'react'
import {useStyles} from './styles'


//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import CallMade from "@material-ui/icons/CallMade";
import CallReceived from "@material-ui/icons/CallReceived";
import Grid from '@material-ui/core/Grid'

//material icons
import SpaceBarIcon from '@material-ui/icons/SpaceBar'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

//others components
import { LoadingContainer, Title, Value, Prev, PrevValue, PrevLabel } from "./styles";


function Leaving({title, value, last_value}){
    const classes = useStyles();
    return(
        <Card className={classes.root}>
        <CardContent>
        <Grid container direction="column" justify="center" alignItems="center"> 
        <Grid item><ArrowUpwardIcon style={{ color: "#d23737", fontSize: 24, marginBottom: '-40px', marginTop: '-40px' }} /></Grid>
        <Grid item><SpaceBarIcon style={{ color: "#d23737", fontSize: 40, marginTop: '-25px' }} /></Grid>
        <Grid item style={{marginTop: '-10px'}}><Value>123</Value></Grid>
        <Grid item><p> </p></Grid>
        <Grid item><p style={{marginBottom: '-2px'}}><b>Saída</b></p></Grid>
        <Grid item><p>Hoje</p></Grid>
        </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    )
}


export default Leaving;