import React, { Component } from 'react';
import css from '../../css/dashboard/items_card.module.css';
import DropDown from '../../components/DropDown';
import dropDownCSS from '../../css/common/dropdown1.module.css';

class ItemsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title || 'Title',
      dropDownData: props.dropDownData || new Array(12).fill(<a>Item</a>),
      lineData: props.lineData || [
        { text: 'Text', number: 0 },
        { text: 'Text', number: 0 }
      ]
    };
  }

  render() {
    return (
      <div {...this.props}>
        <div className={css.container}>
          <div className={css.row0}>
            <div>{this.state.title}</div>
          </div>
          <div className={css.row1}>
            <div className={css.numbers}>
              <div></div>
              {this.props.lineData.map(item => (
                <div className={css.number}>{item.number}</div>
              ))}
              <div></div>
            </div>
            <div className={css.line}>
              <div className={css.dots}>
                <div className={css.smallDot}></div>
                {this.state.lineData.map(item => (
                  <div className={css.bigDot}></div>
                ))}
                <div className={css.smallDot}></div>
              </div>
              <hr></hr>
            </div>
            <div className={css.legends}>
              <div></div>
              <div></div>
              {this.state.lineData.map(item => (
                <div className={css.legend}>{item.text}</div>
              ))}
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemsCard;
