import styled from "styled-components";
import { Paper, Typography, Button as MatButton, List, ListItem, Collapse as MatCollapse} from "@material-ui/core";

export const PartNumbersList = styled(List)``

export const Collapse = styled(MatCollapse)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  position: relative;
`

export const PartNumberItem = styled(ListItem)``

export const PartNumberText = styled(Typography)`
  font-size: 12px !important;
`

export const CustomMarker = styled(Typography)`
  margin: 0;
  padding: 0;
  border-radius: 5%;
  padding: 3px 6px;
  font-size: 8px !important;
  text-align: center;
  color: 'black';
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: flex-start;
  overflow: hidden;
  top: 0;
  left: 0;
`

export const MapSidebarContainer = styled(Paper)`
  width: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`

export const MapSidebar = styled(List)`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Button = styled(MatButton) `
  width: 100%;
  margin-bottom: 10px;
  flex: 1;
`