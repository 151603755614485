import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import StandbyForklift from "./StandbyForklift";
import StandbyForkliftType from "./StandbyForkliftType";
import StandbyForkliftOperation from "./StandbyForkliftOperation";
import RankForklifts from "./RankForklifts";
import RankForkliftsOperation from "./RankForkliftsOperation";
import moment from "moment";
import api from "../../../../../core/services/api";
import Card from "./Card";
import Indicator from "./Indicator";
import Indicator2 from "./Indicator2";
import { useEffect } from "react";

export default function DashboardContent() {
  const [indicators, setIndicators] = useState({
    years: 0,
    months: 0,
    yearsForks: 0,
    monthsForks: 0,
    yearsClamps: 0,
    monthsClamps: 0,
    lastYearsClamps: 0,
    lastMonthsClamps: 0,
    lastYearsForks: 0,
    lastMonthsForks: 0,
    lastYears: 0,
    lastMonths: 0,
  });

  // Set Years
  async function getData() {
    const today = moment();
    const lMonth = today.subtract(1, "months");

    const start_at = lMonth.startOf("month").format("YYYY-MM-DD");
    const years = await api.get("/forklift-idleness-year");
    const months = await api.get("/forklift-idleness-month");
    const lastMonth = await api.get(
      `/forklift-idleness-month?start=${moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD")}&end=${moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`
    );
    const yearsClamps = await api.get("/forklift-idleness-year-clamps");
    const monthsClamps = await api.get("/forklift-idleness-month-clamps");
    const lastMonthsClamps = await api.get(
      `/forklift-idleness-month-clamps?start=${moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD")}&end=${moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`
    );
    const yearsForks = await api.get("/forklift-idleness-year-garfo");
    const monthsForks = await api.get("/forklift-idleness-month-garfo");
    const lastMonthsForks = await api.get(
      `/forklift-idleness-month-garfo?start=${moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD")}&end=${moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`
    );
    console.log(
      "imprimindo start",
      moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
    );
    console.log(
      "imprimindo end",
      moment().subtract(1, "months").format("YYYY-MM-DD")
    );

    setIndicators((prev) => ({
      ...prev,
      years: years.data.data.toFixed(1),
      months: months.data.data.toFixed(1),
      lastMonths: lastMonth.data.data.toFixed(1),
      yearsClamps: yearsClamps.data.data.toFixed(1),
      monthsClamps: monthsClamps.data.data.toFixed(1),
      lastMonthsClamps: lastMonthsClamps.data.data.toFixed(1),
      yearsForks: yearsForks.data.data.toFixed(1),
      monthsForks: monthsForks.data.data.toFixed(1),
      lastMonthsForks: lastMonthsForks.data.data.toFixed(1),
    }));
  }
  const calcDiff = (last_value, actual_value) => {
    let percent;
    if (parseFloat(actual_value) > parseFloat(last_value)) {
      percent = (parseFloat(actual_value) / parseFloat(last_value) - 1) * 100;
    } else {
      percent = (parseFloat(last_value) / parseFloat(actual_value) - 1) * 100;
    }

    const direction =
      parseFloat(actual_value) < parseFloat(last_value) ? "down" : "up";
    let result = (isFinite(percent) && percent) || 0;
    return { result, direction };
  };

  useEffect(() => {
    getData();
  }, []);

  return (
      <Grid spacing={4} container style={{ marginTop: 1 }}>
        <Grid item xs={4}>
          <Card color="#7D77FF">
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Geral</b> -{" "}
                  <b>Ano Atual</b>
                </span>
              }
              value={`${indicators.years}%`}
              /* last_value={calcDiff(indicators.lastYears, indicators.years)} */
            />
            <Divider />
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Geral</b> -{" "}
                  <b>Mês Atual</b>
                </span>
              }
              value={`${indicators.months}%`}
              last_value={calcDiff(indicators.lastMonths, indicators.months)}
            />
           
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card color="#2DE0C2">
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Garfos</b> -{" "}
                  <b>Ano Atual</b>
                </span>
              }
              value={`${indicators.yearsForks}%`}
              /* last_value={calcDiff(indicators.lastYearsForks, indicators.yearsForks)} */
            />
           
            <Divider />
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Garfos</b> -{" "}
                  <b>Mês Atual</b>
                </span>
              }
              value={`${indicators.monthsForks}%`}
              last_value={calcDiff(
                indicators.lastMonthsForks,
                indicators.monthsForks
              )}
            />
             
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card color="#26A0FC">
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Clamps</b> -{" "}
                  <b>Ano Atual</b>
                </span>
              }
              value={`${indicators.yearsClamps}%`}
              /* last_value={calcDiff(indicators.lastYearsClamps, indicators.yearsClamps)} */
            />
            <Divider />
            <Indicator
              title={
                <span>
                  Ociosidade Empilhadeira Acumulado <b>Clamps</b> -{" "}
                  <b>Mês Atual</b>
                </span>
              }
              value={`${indicators.monthsClamps}%`}
              last_value={calcDiff(
                indicators.lastMonthsClamps,
                indicators.monthsClamps
              )}
            />
           
          </Card>
        </Grid>
        <Grid item xs={12}>
          <StandbyForklift />
        </Grid>
        <Grid item xs={12}>
          <StandbyForkliftType subtitle="Tipo Máquina" />
        </Grid>
        <Grid item xs={12}>
          <RankForklifts />
        </Grid>
        <Grid item xs={12}>
          <StandbyForkliftType subtitle="Evolução" />
        </Grid>
        <Grid item xs={12}>
          <RankForkliftsOperation />
        </Grid>
        <Grid item xs={12}>
          <StandbyForkliftOperation />
        </Grid>
      </Grid>
  );
}
