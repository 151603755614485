

import loginPage from './login';

//whirlpool
import whirlpoolDashboard from '../../client/whirlpool/pages/DashboardPanel'
import whirlpoolMap from '../../client/whirlpool/pages/Map'
import whirlpoolForklifts from '../../client/whirlpool/pages/ManagementForklifts'
import whirlpoolShifts from '../../client/whirlpool/pages/ManagementShifts'
import whirlpoolReport from '../../client/whirlpool/pages/Report'
import whirlpoolUsers from '../../client/whirlpool/pages/UsersPage'
import whirlpoolGroups from '../../client/whirlpool/pages/GroupsPage'
import whirlpoolSettings from '../../client/whirlpool/pages/SettingsPage'
import whirlpoolProfile from '../../client/whirlpool/pages/Profile'
import whirlpoolKPI from '../../client/whirlpool/pages/KPI'
import whirlpoolDashboardScanners from '../../client/whirlpool/pages/DashboardScanners'
import whirlpoolBeacons from '../../client/whirlpool/pages/ManagementBeacons'

//sanofi
import sanofiDashboard from '../../client/sanofi/pages/DashboardSanofi';
import sanofiInventories from '../../client/sanofi/pages/SanofiPallets';

//renault
import renaultDashboard from '../../client/renault/pages/DashboardRenault';
import renaultManagementPackaging from '../../client/renault/pages/ManagementPackaging';
import renaultPackaging from '../../client/renault/pages/Packaging';

//fca
import FCAMap from '../../client/fca/pages/Map';

// SD
import dashboardSF from '../../client/sanDiego/pages/Dashboard'
import moduleList from '../../client/sanDiego/pages/ModulesList'
import clientList from '../../client/sanDiego/pages/ClientList'
import machineList from '../../client/sanDiego/pages/MachineList'
import maps from '../../client/sanDiego/pages/GeneralMap'
import fences from '../../client/sanDiego/pages/FenceList'
import alerts from '../../client/sanDiego/pages/Alerts'


//techint
import techintDashboard from '../../client/techint/pages/Dashboard'
import techintDepartment from '../../client/techint/pages/Department'
import techintCollaborators from '../../client/techint/pages/Collaborators'
import techintStacked from '../../client/techint/pages/StackedBar'
import techintMap from '../../client/techint/pages/Map'
import techintTimeline from '../../client/techint/pages/Timeline'

//ARCELLOR
import Principal from '../../client/move/pages/principal/Principal'
import Maps from '../../client/move/pages/maps/Maps'

export default {
    //COMPONENTES DASHBOARD
    'WhirlpoolDashboard': whirlpoolDashboard,
    'WhirlpoolDashboardScanners': whirlpoolDashboardScanners,
    'WhirlpoolMap': whirlpoolMap,
    'WhirlpoolForklifts': whirlpoolForklifts,
    'WhirlpoolShifts': whirlpoolShifts,
    'WhirlpoolReport': whirlpoolReport,
    'WhirlpoolUsers': whirlpoolUsers,
    'WhirlpoolGroups': whirlpoolGroups,
    'WhirlpoolSettings': whirlpoolSettings,
    'WhirlpoolProfile': whirlpoolProfile,
    'WhirlpoolKPI': whirlpoolKPI,
    'WhirlpoolBeacons': whirlpoolBeacons,

    //COMPONENTES SANOFI
    'SanofiDashboard': sanofiDashboard,
    'SanofiInventories': sanofiInventories,

    //COMPONENTES RENAULT
    'RenaultDashboard': renaultDashboard,
    'RenaultManagementPackaging': renaultManagementPackaging,
    'RenaultPackaging': renaultPackaging,

    //COMPONENTES SD
    'SFDashboard': dashboardSF,
    'SDModuleList': moduleList,
    'SDClientList': clientList,
    'SDMachineList': machineList,
    'SDMaps': maps,
    'SDFences': fences,
    'SDAlerts': alerts,
    
    

    //COMPONENTES TECHINT
    'TechintDashboard': techintDashboard,
    'TechintDepartment': techintDepartment,
    'TechintCollaborators': techintCollaborators,
    'TechintStacked': techintStacked,
    'TechintMap': techintMap,
    'TechintTimeline': techintTimeline,

    //COMPONENTES SISTEMA
    'LoginPage': loginPage,

    // Components
    'FCAMap': FCAMap,

    //ARCELLOR
    'Principal': Principal,
}