import React from "react";
import MTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { ClampsProgress, ForksProgress, BorderLinearProgress } from './Progress';

export default function ({ data, idleness_goals }) {
  console.log(data);
  return (
    <Grid item xs={6}>
      <h5 style={{ 'color': 'grey', fontSize: 16 }}>Ranking Ociosidade Anual</h5>
      <MTable
        columns={[
          {
            title: 'Posição', field: 'id', sorting: false,
            render: ({ tableData }) => (<span>{tableData.id + 1}º</span>)
          },
          { title: "Número Máquina", field: "forklift_number", sorting: false },
          { title: "Tipo", field: "name", sorting: false },
          {
            title: "Ocisosidade Máquina",
            field: "ociosidade_percentual",
            sorting: false,
            render: ({ ociosidade_percentual, name }) => {
              return (
                <>
                  <span>{parseFloat(ociosidade_percentual).toFixed(1)}%</span>
                  {name == 'Clamps' ? (<><ClampsProgress variant="determinate" value={ociosidade_percentual} /><BorderLinearProgress value={5} variant="determinate" style={{marginLeft: idleness_goals+'%', bottom: 10}}/></>) : (<><ForksProgress variant="determinate" value={ociosidade_percentual} /><BorderLinearProgress value={5} variant="determinate" style={{marginLeft: idleness_goals+'%', bottom: 10}}/></>)}
                </>
              );
            },
          }
        ]}
        data={data}
        options={{
          toolbar: false,
          showFirstLastPageButtons: false,
          pageSizeOptions: [],
          cellStyle: {
            padding: "10px",
            textAlign: "left",
          },
          rowStyle: (rowData, i) => {
            if (i % 2) {
              return { backgroundColor: "#F3F5F866" };
            } else {
              return { backgroundColor: "#F3F5F8" };
            }
          },
          headerStyle: {
            padding: "10px",
            fontWeight: "bold",
            color: "white",
            textAlign: "left",
            backgroundColor: "#C2C9D4",
          },
        }}
      />
    </Grid>
  );
}