import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles,  lighten, withStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
    root: {
      maxWidth: 120,
      maxHeight: 100,
      minWidth: 150,
      minHeight: 100,
      userSelect: 'none',
      position: 'relative',
      //backgroundColor: '#5ad545',
      color: '#fff'
    },
    title: {
      fontSize: 14,
    },
    pos: {
      textAlign: 'right',
      marginBottom: 12,
    },
  });

export default function ModalScanner(props) {
    const classes = useStyles();

  function getStatus(status){
      if(status){
        return '#5ad545'
      } else {
        return '#ec273b'
      }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#3f51b5', 0.7),
    },
    bar: {
      borderRadius: 3,
      backgroundColor:'#3f51b5',
    },
  })(LinearProgress);

  function getChip(status){
      if(status){
          return (<Chip label="Ativo" style={{backgroundColor:'#5ad545', color: '#fff' }} />)
      } else {
          return ( <Chip label="Inativo" style={{backgroundColor:'#ec273b', color: '#fff' }}/>)
      }
  }

  return (
    <div>
    <Card className={classes.root} style={{backgroundColor: getStatus(props.status)}} onClick={handleClickOpen} >
      <CardContent>
        <Typography variant="h5" component="p" style={{textAlign: 'center', lineHeight: 3}}>
          {props.name}
        </Typography>
      </CardContent>
      <div style={{ marginTop: -10, position: "relative" }}>
        <BorderLinearProgress
          style={{ height: 15 }}
          variant="determinate"
          value={props.avaliability}
        />
      </div>
    </Card>
      {/* <Button variant="outlined" color="primary" >
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.name}</DialogTitle>
        <DialogContent>

          <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">MacAddress:</TableCell>
              <TableCell align="right">{props.macaddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Status:</TableCell>
              <TableCell align="right">{getChip(props.status)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}