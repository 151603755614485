import React, { useState, useEffect } from "react";

//custom components
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import Alert from "../../components/Alert";

//material-ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"

function SettingsPage() {
  const [state, setState] = useState({
    disabled: false,
  });
  const [idleness, setIdleness] = useState("");
  const [idleness_goals, setIdlenessgoals] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const settings = await api.get("/settings");
    setState((prev) => ({
      ...prev,
      settings: settings.data.resource || [],
    }));
    setIdleness(settings.data.resource.idleness_time);
    setIdlenessgoals(settings.data.resource.idleness_goals);
  }

  async function handleSubmit() {
    const obj = {
      resource: {
        idleness_time: idleness,
        idleness_goals: idleness_goals
      },
    };
    setState((prev) => ({
      ...prev,
      disabled: true,
    }));
    await api.put(`/update-settings`, obj);
    setOpen(true);
    setState("");
    getData();
    //
  }

  return (
    <Main color="#eeeeee" title="Confirgurações">
      <Card style={{maxWidth: 450}}>
        <CardContent>
        <div style={{ marginTop: 12, marginBottom: 12 }}>
        <TextField
            id="standard-basic"
            label="Tempo de Ociosidade"
            helperText="Minutos"
            value={idleness}
            onChange={(e) => setIdleness(e.target.value)}
          /> </div>
<div style={{ marginTop: 12, marginBottom: 12 }}>
        <TextField
            label="Meta de Ociosidade"
            helperText="Porcento"
            value={idleness_goals}
            onChange={(e) => setIdlenessgoals(e.target.value)}
          /></div>
        </CardContent>
        <CardActions>
        <Button
           
            variant="contained"
            color="primary"
            disabled={state.disabled}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </CardActions>
      </Card>
      <Alert open={open} message="Configurações salvas com sucesso!" alert="success" setOpen={() => setOpen()}/>
    </Main>
  );
}

export default SettingsPage;
