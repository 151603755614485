import React from "react";

//@material-ui
import NearMe from "@material-ui/icons/NearMe";
import LensIcon from "@material-ui/icons/Lens";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from '@material-ui/icons/Person'
import { Vehicle, VehicleData, VehicleNumber, IconContainer } from './styles';
import { setColor } from './Utils';

export default ({
  people,
  navigate,
  selectedIndex,
  handleListItemClick,
}) => {
  return (
    <>
      <ListItem
        button
        selected={selectedIndex}
        onClick={(event) => handleListItemClick(event, 0)}
        divider
      >
        <IconContainer color={setColor(people.active ? people.place : 'Ociosa')}>
            <PersonIcon />
        </IconContainer>

        <ListItemText primary={people.name} />

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <NearMe
              onClick={() => navigate(people.latitude, people.longitude)}
              style={{ color: "#7C8796" }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
