import React, { useState, useEffect } from "react";

import moment from "moment";
import Chart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import "moment/locale/pt-br";

import api from '../../../../../../core/services/api';
import options from "./options";

moment.locale("pt-br");

export default function () {
  const [data, setState] = useState({
    forkliftID: null,
    operationID: null,
    resultBy: 'day',
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    forklifts: [],
    operations: [],
    values: [],
    values2: [],
    cols: [],
    loading: true,
    dateRangeID: null,
    dateRange: [
      { id: 1, name: "Últimos 7 dias" },
      { id: 2, name: "Últimos 14 dias" },
      { id: 3, name: "Últimos 20 dias" },
      { id: 4, name: "Últimos 30 dias" },
      { id: 5, name: "Hoje" },
      { id: 6, name: "Ontem" },
      { id: 7, name: "Essa Semana" },
      { id: 8, name: "Última Semana" },
      { id: 9, name: "Esse Mês" },
      { id: 10, name: "Último Mês" },
      { id: 11, name: "Este Ano" },
    ],
  });

  async function getData() {
    const config = await api.get('/settings');
    let url = `/forklift-idleness?start=${data.startDate}&end=${data.endDate}`;
    url += data.forkliftID ? "&forklift_number=" + data.forkliftID : "";
    url += data.operationID ? "&operation_place_id=" + data.operationID : "";
    url += data.resultBy ? "&result_by=" + data.resultBy : "day";
    const result = await api.get(url);
    const apiCols = [];
    const apiValues = [];
    const apiValues2 = [];

    result.data.data.map(({ localdate, ociosidade_percentual }) => {
      if (data.resultBy == 'day') {
        apiCols.push(moment(localdate).format("DD MMM YYYY"));
      } else {
        apiCols.push(localdate);
      }

      apiValues.push(ociosidade_percentual);
      apiValues2.push(parseInt(config.data.resource.idleness_goals));
    });

    setState((prev) => ({
      ...prev,
      cols: apiCols,
      values: apiValues,
      values2: apiValues2,
      loading: false,
    }));
  }

  async function initData() {
    const forklifts = await api.get("/forklifts");
    const operations = await api.get("/operation-places");
    setState((prev) => ({
      ...prev,
      forklifts: forklifts.data,
      operations: operations.data,
    }));
  }

  // Main UseEffect
  useEffect(() => {
    getData();
  }, [data.startDate, data.endDate, data.forkliftID, data.operationID]);

  // First Loading Use Effect
  useEffect(() => {
    initData();
  }, []);

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const changeForkliftID = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      forkliftID: value,
    }));
  };

  const changeOperationID = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      operationID: value,
    }));
  };

  const changeDateRange = (e) => {
    const { value } = e.target;
    if (value == 1) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 2) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 3) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(20, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 4) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 5) {
      setState((prev) => ({
        ...prev,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 6) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 7) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 8) {
      const firsDayWeek = moment().startOf("isoWeek");
      const lastWeek = firsDayWeek.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastWeek.startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: lastWeek.endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 9) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 10) {
      const firstDayMonth = moment().startOf("month");
      const lastMonth = firstDayMonth.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: lastMonth.endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day'
      }));
    }
    if (value == 11) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'month'
      }));
    }
  };

  return (
    <Paper>
      <Container>
        <h5
          style={{ color: "grey", textAlign: "center", fontWeight: "bolder" }}
        >
          Ociosidade Máquina Empilhadeira
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flex: 1,
            marginTop: "30px",
          }}
        >
          <Select
            displayEmpty
            value={data.operationID}
            onChange={changeOperationID}
            style={{ width: "223px", height: "48px" }}
            placeholder="Empilhadeiras"
          >
            <MenuItem key="all" value={null}>
              Operações
            </MenuItem>
            {data.operations.length > 0 &&
              data.operations.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
          <Select
            displayEmpty
            value={data.forkliftID}
            onChange={changeForkliftID}
            style={{ width: "223px", height: "48px" }}
            placeholder="Empilhadeiras"
          >
            <MenuItem key="all" value={null}>
              Empilhadeiras
            </MenuItem>
            {data.forklifts.length > 0 &&
              data.forklifts
                .filter((item) => {
                  if (!data.operationID) return true;
                  if (data.operationID == item.operation_place_id) return true;
                })
                .map(({ id, forklift_number }) => (
                  <MenuItem key={id} value={forklift_number}>
                    {forklift_number}
                  </MenuItem>
                ))}
          </Select>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(data.startDate, "YYYY-MM-DD")}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Final"
              value={moment(data.endDate, "YYYY-MM-DD")}
              onChange={handleEndDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
          <Select
            displayEmpty
            value={data.dateRangeID}
            onChange={changeDateRange}
            style={{ width: "223px", height: "48px" }}
            placeholder="Período"
          >
            <MenuItem key="all" value={null}>
              Período
            </MenuItem>
            {data.dateRange.length > 0 &&
              data.dateRange.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div style={{ padding: "30px 0", minHeight: 300 }}>
          {!data.loading && (
            <Chart
              options={options(data.cols, data.values2[0])}
              series={[
                {
                  name: "Ociosidade",
                  data: data.values,
                },
              ]}
              type="bar"
              height={350}
            />
          )}
        </div>
      </Container>
    </Paper>
  );
}
