import React from 'react';
import Main from '../../components/Main'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//custom components
import ScannersPanel from './ScannersPanel'
import MapPanel from './MapPanel'

//@material-ui
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'





function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
  }));



//PAGE
function DashboardScanners(){
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
    <Main color="#f7f7f7" title="Scanners">
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
     
        >

            <Tab label="Scanners" />
            <Tab label="Mapa" /> 
        </Tabs>
      </Paper>

        <TabPanel value={value} index={0}>
          <ScannersPanel />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapPanel />
        </TabPanel>
     

        </Main>
    )
}

export default DashboardScanners;