import React from "react";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import Machine from "./Machine";
import Operation from "./Operation";
import { Sidebar, SidebarTitle, MachinesContainer } from "./styles";
import Counter from "./Counter";

export default function ({ machines, operations, status, children, changeViewport }) {
  return (
    <Sidebar>
      <Grid container>
        <Counter title="Total" value={status.total} />
        <Counter title="Operação" value={status.actives} />
        <Counter title="Ociosas" value={status.inactives} />
      </Grid>
      <Divider color="#ECEFF1" />
      <Grid container>
        <Counter title="Total" value={status.total} />
        <Counter title="Garfos" value={status.forks} />
        <Counter title="Clamps" value={status.clamps} />
      </Grid>
      <Divider color="#ECEFF1" />
      <MachinesContainer style={{ marginTop: 10 }}>
        <Grid container>
          {operations.map(({ name }) => (
            <Operation operation={name} />
          ))}
        </Grid>
      </MachinesContainer>
      {children}
      <SidebarTitle>Máquinas</SidebarTitle>
      <MachinesContainer>
        <Grid container>
          {machines.map(machine => (
            <Machine navigate={changeViewport} machine={machine} />
          ))}
        </Grid>
      </MachinesContainer>
    </Sidebar>
  );
}
