import React from 'react'

import Main from '../../components/Main'
import Graph from '../../components/graph'
import Graph2 from '../../components/Graph2'
import Grid from "@material-ui/core/Grid";
import Card from '../../components/Card'
import ConsumoFitaDash from '../../components/ConsumoFitaDash'

import cercaIcon from "../../assets/icons/cercaIcon.svg";
import informationIcon from "../../assets/icons/informationIcon.svg";
// import informationIcon from '../../assets/icons/';
import switchIcon from "../../assets/icons/switchIcon.svg";
import listIcon from "../../assets/icons/listaIcon.svg";


//custom components
import RankingMachineConsumption from './RankingMachineConsumption'
import RankingMachineUsePercent from './RankingMachineUsePercent'

function Dashboard(){

  const colors = {
    color1: "#5ED237",
    color2: "#5ABE38"
  }

  console.log(colors)
  console.log(colors.color2)

  return (
    <Main color="#f7f7f7" title="KPIs">
      <Grid container spacing={2}>
      
      <Grid item xs={4}>
        
        <Card icon={listIcon} colorIcon="#5ABE38" colorInfo="#5ED237" text="Máquinas dentro do consumo estipulado" value='452'/>
      </Grid>
      <Grid item xs={4}>
        <Card icon={informationIcon} colorIcon="#C42545" colorInfo="#E32C51" text="Máquinas fora do consumo estipulado" value='38'/>
      </Grid>
      <Grid item xs={4}>
        <Card icon={informationIcon} colorIcon="#12B0B5" colorInfo="#0FC6CC" text="Máquinas abaixo do consumo estipulado" value='23'/>
      </Grid>

      <Grid item xs={4}>
        
        <Card icon={cercaIcon} colorIcon="#5ABE38" colorInfo="#5ED237" text="Máquinas dentro da cerca virtual" value='389'/>
      </Grid>
      <Grid item xs={4}>
        <Card icon={cercaIcon} colorIcon="#C42545" colorInfo="#E32C51" text="Máquinas fora da cerca virtual" value='14'/>
      </Grid>
      <Grid item xs={4}>
        <Card icon={switchIcon} colorIcon="#FF9B04" colorInfo="#FFBC04" text="Máquinas desligadas" value='23'/>
      </Grid>
      

        <Grid item xs={12}>
          <ConsumoFitaDash/>
        </Grid>

       <Grid item xs={12}>
         <RankingMachineConsumption />
       </Grid>
       <Grid item xs={12}>
         <RankingMachineUsePercent />
       </Grid>

      </Grid>          
      
      
    </Main>
  )
}


export default Dashboard;