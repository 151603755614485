import React, { useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";
import { Content } from './styles'

//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
// import Search from  '../../../components/Search'


import SelectDate from "../../../components/SelectDate"
import SearchkBox from "../../../components/SearchBox";
import Dropdown from "../../../components/SelectDropdown";

//@material-ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";

function TableFenches() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    modules: [],
  });

  // useEffect(() => {
  //   getData();
  // }, []);
  
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const maquinas = [
    {
      id: 1,
      value: '800A',
    },
    {
      id: 2,
      value: '700A',
    },
    
  ];

  
  const clientes = [
    {
      id: 1,
      value: 'Cliente 1',
    },
    {
      id: 2,
      value: 'Cliente 2',
    },
    
  ];

  

  async function getData() {
    try {
      let data = {
        cliente: "teste",
      } 
      // const { data } = await api.get(`/modules/list`);
      // const filterdata = data.filter((item) => item.module_id != null);

      setState((prev) => ({
        ...prev,
        modules: data,
      }));

    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteModule(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o Cliente " + item.module_id
        )
      ) {
        await api.delete(`/module/destroyModule/` + item.module_id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <>
      <MaterialTable
        columns={[
          {
            title: "ID Cerca",
            field: "cerca",
           
            render: rowData => rowData.cerca  
          },
          {
            title: "Cliente",
            field: "cliente",
           
            render: rowData => rowData.cliente
          },
          {
            title: "Ver no Mapa",
            field: "mapa",
            sorting: false,
            render: rowData => <a href="">Ver no Mapa</a>
          },
          {
            field: "actions",
            title: "Ações",
            sorting: false,
            headerStyle: {
              textAlign: "right",
            },
            cellStyle: {
              textAlign: "right",
              paddingRight: 50
            },
            render: rowData => <a href=""> Editar</a>           
          }
        ]}

        data={[{ 
          cliente: 'Cliente 1',
          cerca: "5482",
          mapa: "ver no mapa",
        },
        { 
          cliente: 'Cliente 2',
          cerca: "5678",
          mapa: "ver no mapa",
        },
        { 
          cliente: 'Cliente 3',
          cerca: "0909",
          mapa: "ver no mapa",
        },
        { 
          cliente: 'Cliente 4',
          cerca: "0000",
          mapa: "ver no mapa",
        },
      ]}

        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          
          headerStyle: {
            paddingRight: 24,
            paddingLeft: 24,
            textAlign: "left",
            backgroundColor: "#e8ebef",
            border: "#fff",
          },
          cellStyle: {
            paddingLeft:24, 
            paddingRight:24,
            textAlign: "left", 
            border: "#fff",
            height: '80px'
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />{" "}
    </>
  );
}

export default TableFenches;
