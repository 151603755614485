import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'



//custom components
import SDSelect from "../../../../components/SDSelect";

function CepMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
CepMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function CnpjMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
CnpjMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const currencies = [
  {
    value: 'Norte',
    label: 'Norte',
  },
  {
    value: 'Nordeste',
    label: 'Nordeste',
  },
  {
    value: 'Centro-Oeste',
    label: 'Centro-Oeste',
  },
  {
    value: 'Sul',
    label: 'Sul',
  },
  {
    value: 'Sudeste',
    label: 'Sudeste',
  },
];


function EditForm(props) {
  const [state, setState] = useState({
    uf: 0,
    active: true,
    error: false,
    disabled: false,
    name: "",
    cod_client: "",
    region: "",
    phone: "",
    celphone: "",
    cnpj: "",
    cep: "",
    address: "",
    person_in_charge: "",
    address_n: "",
    complement: "",
    city: "",
    email: "",
  });

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [celphone, setCelPhone] = useState("");
  const [region, setRegion] = useState("");
  const [person_in_charge, setPersonInCharge] = useState("");
  const [cod_client, setCod_Client] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [address_n, setAddressN] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      error: false,
      disabled: false,
      check: false,
    })
    setName(props.clients.name);
    setPhone(props.clients.phone);
    setCelPhone(props.clients.celphone);
    setRegion(props.clients.region);
    setPersonInCharge(props.clients.person_in_charge);
    setCod_Client(props.clients.cod_client);
    setCnpj(props.clients.cnpj);
    setCep(props.clients.cep);
    setAddress(props.clients.address);
    setAddressN(props.clients.address_n);
    setComplement(props.clients.complement);
    setCity(props.clients.city);
    setUf(props.clients.uf);
    setEmail(props.clients.email);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setName("");
    setPhone("");
    setCelPhone("");
    setRegion("");
    setPersonInCharge("");
    setCod_Client("");
    setCnpj("");
    setCep("");
    setAddress("");
    setAddressN("");
    setComplement("");
    setCity("");
    setUf("");
    setEmail("");
  };

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    try {
      const client = {
        resource: {
          name: name,
          phone: phone,
          celphone: celphone,
          region: region,
          person_in_charge: person_in_charge,
          cod_client: cod_client,
          cnpj: cnpj,
          cep: cep,
          address: address,
          address_n: address_n,
          complement: complement,
          uf: uf,
          city: city,
          email: email,
        },
        active: props.active
      }
      await api.put(`clients/${props.clients.id}`, client);
      props.getData();

    } catch (error) {
      console.warn(error)
    }
    console.log('Cliente editado com sucesso!'.clients)
    handleClose()
  }


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Editar Cliente </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="nome"
              name="Nome"
              label="Nome"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="cod_cliente"
              label="Cód. Cliente"
              value={cod_client}
              type="text"
              onChange={(e) => setCod_Client(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">CNPJ</InputLabel>
              <Input
                id="cnpj"
                onChange={(e) => setCnpj(e.target.value)}
                inputComponent={CnpjMask}
                name="textmask"
                value={cnpj}
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">Telefone</InputLabel>
              <Input
                onChange={(e) => setPhone(e.target.value)}
                inputComponent={PhoneMask}
                value={phone}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">Celular</InputLabel>
              <Input
                onChange={(e) => setCelPhone(e.target.value)}
                inputComponent={PhoneMask}
                name="textmask"
                value={celphone}
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl>
              <InputLabel htmlFor="formatted-text-mask-input">CEP</InputLabel>
              <Input
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                placeholder="Digite seu cep"
                inputComponent={CepMask}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField              
              required
              id="address"
              label="Endereço"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField           
              id="numero"
              label="N°"
              type="text"
              value={address_n}
              onChange={(e) => setAddressN(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField        
              id="complemento"
              label="Complemento"
              type="text"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="city"
              label="Cidade"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              select
              id="regiao"
              type="text"
              helperText="Selecione a Região"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              fullWidth
            >
              {currencies.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="responsavel"
              label="Nome Responsável"
              type="text"
              value={person_in_charge}
              onChange={(e) => setPersonInCharge(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />{" "}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
