import React, {useState, useEffect} from 'react';
import Main from '../../components/Main'
import { easeCubic } from "d3-ease";


//@material-ui 
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

//material icons
import Location from "@material-ui/icons/LocationOn";

// Mapbox Lib
import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, {
  Marker,
  FlyToInterpolator,
  Popup,
  NavigationControl,
} from "react-map-gl";


//custom components
import Sidebar from "./Sidebar";
import { MapContainer } from "./styles";
import PopupItem from "./PopupItem";

// Utils
import { setColor, randomCoordinates } from "./Utils";

const CustomSwitch = withStyles({
    switchBase: {
      color: "#908bff",
      "&$checked": {
        color: "#908bff",
      },
      "&$checked + $track": {
        backgroundColor: "#908bff",
      },
    },
    checked: {},
    track: {},
  })(Switch);


  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

function Map(){
  const token = process.env.REACT_APP_MAPBOX_TOKEN
  const [opensidebar, setOpensidebar] = useState(true);
  const [state, setState] = useState({
    is_open: false,
    peoples: [],
    filteredPeople: [],
    operations: [],
    filter: "",
    popupInfo: null,
    people_status: null,
    operation_status: null,
    forklift_status: null,
    people_department: null,
    satelite: true,
    status: {
      total: 0,
      inactives: 0,
      actives: 0,
      clamps: 0,
      forks: 0,
    },
  });

  useEffect(() => {
    getCarros();
    getOperations();
    getSettings();
    const interval = setInterval(() => {
      getCarros2();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setFilter();
  }, [
    state.filter,
    state.forklifts,
    state.people_status,
    state.operation_status,
    state.forklift_status,
  ]);
  function showRoute(path) {
    this.props.history.push(path);
  }

  const navigateTo = (latitude, longitude) => {
    const viewport = {
      ...state.viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: 18,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    };
    setState((prev) => ({ ...prev, viewport }));
  };

  async function getCarros() {
    try {
      const resp = [
        {name: "Wendreo", operation_place_id: 8, place: "Almoxarifado", active: true, idleness: {minutes: 1, seconds: 6, milliseconds: 998}, latitude: -26.2483922852425, longitude: -48.8674842269472},
        {name: "Luiz Fellype", operation_place_id: 11, place: "Setor B", active: true, idleness: {minutes: 1, seconds: 6, milliseconds: 998}, latitude: -26.2472002339888, longitude: -48.8692049346532}
      ] //inserir API CARROS AQUI
  
        const people = resp.map((people) => {
          const latitude = people.latitude;
          const longitude = people.longitude;
          const coordinates = randomCoordinates({ latitude, longitude }, 10);
          people.latitude = coordinates.latitude;
          people.longitude = coordinates.longitude;
          return people;
      }); 
      setState((prev) => ({
        ...prev,
        peoples: people,
      }));
      
      localStorage.setItem('oldstate', JSON.stringify(people));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getCarros2() {
    try {
      const resp =[
        {name: "Wendreo", operation_place_id: 8, place: "Almoxarifado", active: true, idleness: {minutes: 1, seconds: 6, milliseconds: 998}, latitude: -26.2483922852425, longitude: -48.8674842269472},
        {name: "Luiz Fellype", operation_place_id: 11, place: "Setor B", active: true, idleness: {minutes: 1, seconds: 6, milliseconds: 998}, latitude: -26.2472002339888, longitude: -48.8692049346532}
      ] // INSERIR API CARROS AQUI TBM
     
      const oldstate = JSON.parse(localStorage.getItem('oldstate'));        
      
      const people = resp.map((people) => {
          if (people.active){
            const latitude = people.latitude;
            const longitude = people.longitude;
            const coordinates = randomCoordinates({ latitude, longitude }, 10);
            people.latitude = coordinates.latitude;
            people.longitude = coordinates.longitude;
            return people;
          } else {
            const oldPeople = oldstate.filter((item) => {return item.name === people.name})
            people.latitude = oldPeople[0].latitude;
            people.longitude = oldPeople[0].longitude;
            return people;
          }
         
          
      }); 
      setState((prev) => ({
        ...prev,
        peoples: people,
      }));
      
      localStorage.setItem('oldstate', JSON.stringify(people));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getOperations() {
    try {
      const data = [
        {"id":8,"name":"Almoxarifado","active":true,"created_at":"2020-08-10 10:21:07","updated_at":"2020-08-10 10:21:07","system_client_id":7},
        {"id":9,"name":"Setor A","active":true,"created_at":"2020-08-10 10:21:13","updated_at":"2020-08-10 10:21:13","system_client_id":7},
        {"id":10,"name":"Recebimento","active":true,"created_at":"2020-08-10 10:21:19","updated_at":"2020-08-10 10:21:19","system_client_id":7},
        {"id":11,"name":"Setor B","active":true,"created_at":"2020-08-10 10:21:26","updated_at":"2020-08-10 10:21:26","system_client_id":7},
        {"id":12,"name":"Elétrica","active":true,"created_at":"2020-08-10 10:21:33","updated_at":"2020-08-10 10:21:33","system_client_id":7},
        {"id":14,"name":"Gestores","active":true,"created_at":"2020-08-10 10:21:53","updated_at":"2020-08-10 10:21:53","system_client_id":7}] //INSERIR API DE LOCAIS OU OPERAÇOES
      let operations = data;
     /*  operations.push({
        id: null,
        name: "Ociosa",
        active: true,
        created_at: Date.now(),
      }); */
      setState((prev) => ({
        ...prev,
        operations,
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getSettings() {
    const settings = [] //INSERIR API PARA CONFIRGURAÇÕES SYSTEM CLIENT
    setState((prev) => ({
      ...prev,
      viewport: {
        width: "100%",
        height: "100vh",
        latitude: -26.2491676 || [],
        longitude: -48.8701235 || [],
        zoom: 16,
      }
    }));
  }

  function resetPopup() {
    setState((prev) => ({
      ...prev,
      popupInfo: null,
    }));
  }

  function openPopup(people) {
    setState((prev) => ({
      ...prev,
      popupInfo: people,
    }));
  }

  function renderPopup() {
    return (
      state.popupInfo && (
        <Popup
          closeButton={false}
          tipSize={5}
          anchor="bottom-right"
          longitude={state.popupInfo.longitude}
          latitude={state.popupInfo.latitude}
          onClose={() => resetPopup()}
          closeOnClick={true}
        >
          <Grid container>
            <Grid item xs={8}>
              <PopupItem title="Máquina" value={state.popupInfo.name} />
            </Grid>
            <Grid item xs={4}>
              <PopupItem title="Tipo" value={state.popupInfo.type} />
            </Grid>
            <Grid item xs={8}>
              <PopupItem
                title="Área de Operação"
                value={state.popupInfo.place}
              />
            </Grid>
            <Grid item xs={4}>
              <PopupItem title="Área" value={state.popupInfo.scanner} />
            </Grid>
          </Grid>
        </Popup>
      )
    );
  }

  function setFilter() {
    const peoples = filterPeople();
    console.log('setFilter peoples: ', peoples)
    setState((prev) => ({
      ...prev,
      filteredPeople: peoples,
    }));
    
  }

  function filterPeople() {
    console.log('filter people primeiro if :', state.peoples)
    
    if (state.peoples.length == 0) return state.peoples;
    let data = state.peoples;
    if (state.filter.length > 0) {
      data = data.filter((item) => {
        const name = item.name.toString();
        if (name.includes(state.filter)) return true;
      });
    }
    
    if (state.people_status != null) {
      data = data.filter((item) => {
        if (state.people_status == item.active) return true;
      });
    }

    if (state.forklift_status) {
      data = data.filter((item) => {
        const name = item.name.toString();
        if (name.includes(state.forklift_status)) return true;
      });
    }

    if (state.operation_status) {
      data = data.filter((item) => {
        if (state.operation_status == item.place) return true;
      });
    }

  
    return data;
  }

  function renderMarker(index, forklift) {
    //const { latitude, longitude } = forklift;
    //const coordinates = randomCoordinates({ latitude, longitude }, 10);
    
    return (
      <Marker
        style={{
          zIndex: 9999,
          display: "block",
        }}
        key={index}
        longitude={forklift.longitude}
        latitude={forklift.latitude}
      >
        <div onClick={() => openPopup(forklift)}>
          <Location
            style={{
              display: "flex",
              position: "absolute",
              color: setColor(forklift.active ? forklift.place : "Ociosa"),
            }}
          />
        </div>
      </Marker>
    );
  }

 


  function toggleSatelite(e) {
    setState((prev) => ({
      ...prev,
      satelite: e.target.checked,
    }));
  }

  return (
    <Main style={{ margin: 0, padding: 0, overflow: "hidden" }} title="Mapa Geral">
      <MapContainer>
            <MapGL
              {...state.viewport}
              mapboxApiAccessToken={token}
              mapStyle={`mapbox://styles/mapbox/${
                state.satelite ? "satellite-v9" : "streets-v11"
              }`}
              onViewportChange={(viewport) =>
                setState((prev) => ({ ...prev, viewport }))
              }
            >
              <div
                style={{
                  position: "inherit",
                  margin: 25,
                  bottom: "7%",
                  right: opensidebar ? 330 : 0,
                  transition: "all .35s ease",
                }}
              >
                {" "}
                <NavigationControl />
              </div>
              <Paper
                style={{
                  position: "inherit",
                  paddingLeft: 12,
                  paddingRight: 12,
                  margin: 25,
                  top: 0,
                  left: 0,
                  transition: "all .35s ease",
                }}
              >
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={state.satelite}
                      onChange={toggleSatelite}
                      name="satellite"
                    />
                  }
                  label="Satélite"
                />
              </Paper>
            
              {state.filteredPeople.map((forklift, index) => (
               
                <Marker
                  style={{
                    zIndex: 9999,
                    display: "block",
                  }}
                  key={index}
                  longitude={forklift.longitude}
                  latitude={forklift.latitude}
                >
                  <div onClick={() => openPopup(forklift)}>
                    <Location
                      style={{
                        display: "flex",
                        position: "absolute",
                        color: setColor(
                          forklift.active ? forklift.place : "Ociosa"
                        ),
                      }}
                    />
                  </div>
                </Marker>
              ))}
              {renderPopup()}
            </MapGL>
          </MapContainer>
            
            <Sidebar
              operations={state.operations}
              toggleSatelite={toggleSatelite}
              satelite={state.satelite}
              loading={state.loading}
              showRoute={showRoute}
              goto={navigateTo}
              data={state.filteredPeople}
              filter={state.filter}
              changeViewport={navigateTo}
             
              setOpensidebar={setOpensidebar}
            >
              <TextField
                size="small"
                variant="outlined"
                value={state.filter}
                onChange={(e) => {
                  e.persist();
                  setState((prev) => ({ ...prev, filter: e.target.value }));
                }}
                label="Buscar"
                style={{ margin: 15, marginTop: 8, backgroundColor: '#fff', border: '0px solid #fff' }}
            
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    <SearchIcon style={{color: '#797979'}} />
                  </InputAdornment>
                  ),
                }}
              />
              <FormControl style={{margin: 15, marginTop: 8}}>
                <Select
                
                 variant="outlined"
                  displayEmpty
                  value={state.people_status}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      people_status: e.target.value,
                    }))
                  }
                  input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Status
                  </MenuItem>
                  <MenuItem value={true}>Ativo</MenuItem>
                  <MenuItem value={false}>Inativo</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{margin: 15, marginTop: 8}}>
                <Select
                variant="outlined"
                  displayEmpty
                  value={state.operation_status}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operation_status: e.target.value,
                    }))
                  }
                  input={<BootstrapInput />}
                >
                  <MenuItem key="all" value={null}>
                    Operação
                  </MenuItem>
                  {state.operations.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{margin: 15, marginTop: 8}}>
                <Select
                 variant="outlined"
                  displayEmpty
                  value={state.people_department}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      people_department: e.target.value,
                    }))
                  }
                  input={<BootstrapInput />}
                >
                  <MenuItem key="all" value={null}>
                    Local
                  </MenuItem>
                  {state.operations.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Sidebar>
          
    </Main>
  );
}

export default Map;