import './Main.css'
import Card from '../../components/Card'
import React, { useState, useEffect } from 'react'
import PlaceNames from '../../utils/place_names'
import api from '../../../../core/services/api'

import Main from "../../components/Main";

// Tempo para atualizar dados da página em ms
const timeout = 5000    

function Principal() {
    const [state, setState] = useState({
        scanners: [
            { name: "Porto", status: false},
            { name: "Descarga", status: false},
            { name: "Desapeação", status: false},
        ],
        onOperation: 0,
        onRoad: 0,
        onPorto: 0,
        sumDesapeacao: 0,
        onAMV: 0,
        averagePorto: 0,
        averagePortoAmv: 0,
        averageAmv: 0,
        averageDesapeacao: 0,
        averageDescarga: 0
    })

    useEffect(() => {
        var timer = setInterval(async () => {
            var data = await fetchData()
            console.log('data', data)
            setState(data)
        }, timeout)
        return () => clearTimeout(timer)
    }, [])

    return (
        <Main>
            <div className='Page'>
                <div className='Lines'>
                    <div className='Line'>
                        <Card 
                            title="Status Scanners"
                            entity={ state.scanners } />
                        <Card 
                            title="Total de veículos na operação"
                            entity={{
                                value: state.onOperation
                            }}
                            type='info' />
                        <Card
                            title="Total de veículos em trânsito"
                            entity={{
                                value: state.onRoad
                            }}
                            type='info' />
                        <Card
                            title="Total de veículos no Porto"
                            entity={{
                                value: state.onPorto
                            }}
                            type='info' />
                    </div>
                    <div className='Line'>
                        <Card
                            title="Total de veículos na AMV"
                            entity={{
                                value: state.onAMV
                            }}
                            type='info' />
                        <Card
                            title="Tempo do veículo em desapeação"
                            entity={{
                                value: Math.trunc(state.sumDesapeacao),
                                unit: "min"
                            }} 
                            type='info' />
                        <Card
                            title="Tempo médio de permanência no Porto"
                            entity={{
                                value: Math.trunc(state.averagePorto),
                                min: 0,
                                expected: 20,
                                avarage: 25,
                                max: 30,
                                unit: "min"
                            }} 
                            type='filler' />
                        <Card 
                            title="Tempo médio de trânsito entre Porto e AMV"
                            entity={{
                                value: Math.trunc(state.averagePortoAmv),
                                min: 0,
                                expected: 40,
                                avarage: 50,
                                max: 55,
                                unit: "min"
                            }}
                            type='filler' />
                    </div>
                    <div className='Line'>
                        <Card
                            title="Tempo médio na AMV"
                            entity={{
                                value: Math.trunc(state.averageAmv),
                                min: 0,
                                expected: 35,
                                avarage: 40,
                                max: 45,
                                unit: "min"
                            }}
                            type='filler'/>
                        <Card
                            title="Tempo médio de desapeação"
                            entity={{
                                value: Math.trunc(state.averageDesapeacao),
                                min: 0,
                                expected: 4,
                                avarage: 5,
                                max: 6,
                                unit: "min"
                            }} 
                            type='filler' />
                        <Card
                            title="Tempo médio de descarga"
                            entity={{
                                value: Math.trunc(state.averageDescarga),
                                min: 0,
                                expected: 6,
                                avarage: 7,
                                max: 8,
                                unit: "min"
                            }} 
                            type='filler' />
                        <Card
                            entity={{}}
                            title="Paralisações ao longo do trajeto"
                            type='info' />
                    </div>
                </div>
            </div>
        </Main>
    )
}

const fetchData = async () => {
    const scannersHealth = await api.get(`/scanners/health`)
    const locations = await api.get(`/scanners/locations`)
    const trucks = await api.get(`/scanners/trucks`)
    const times = await api.get(`/scanners/times`)

    var scanners = scannersFromPlance(scannersHealth.data)
    var placeLocations = scannersFromPlance(locations.data)
    var timers = scannersFromPlance(times.data)
    var totalTrucks = trucks.data.length

    var places = timersItens(timers)
    var consolidations = consolidation(places)
    var countObj = operationsCounter(totalTrucks, placeLocations)

    return { 
        scanners,
        ...countObj,
        ...consolidations
    }
}

const timersItens = (timers) => {
    var places = {}

    for(var i = 0; i < timers.length; i++) {
        var timer = timers[i]
        if(!places[`${timer.name}-${timer.place_name}`]) {
            places[`${timer.name}-${timer.place_name}`] = []
        }
        places[`${timer.name}-${timer.place_name}`].push(timer)
    }

    return places
}

const consolidation = (places) => { 
    var carrosDescargas = places['Descarga-AC233FC09012'] || []
    var carrosDesapeacao = places['Desapeação-AC233FC09032'] || []
    var carrosPorto = places['Porto-AC233FC09034'] || []

    // Transforma todos os tempos em minutos
    var sumDescarga = carrosDescargas
        .map(elem => elem.delta / 60000)
        .reduce((acc, x) => x + acc, 0)
    var sumDesapeacao = carrosDesapeacao
        .map(elem => elem.delta/ 60000)
        .reduce((acc, x) => x + acc, 0)
    var sumPorto = carrosPorto
        .map(elem => elem.delta / 60000)
        .reduce((acc, x) => x + acc, 0)

    var averagePortoAmv = (sumDescarga + sumDesapeacao + sumPorto) / 
    ((carrosDescargas.length + carrosDesapeacao.length + sumPorto.length) || 1)

    var averageAmv = (sumDescarga + sumDesapeacao) / 
    ((carrosDescargas.length + carrosDesapeacao.length) || 1)
    var averageDescarga = sumDescarga / (carrosDescargas.length || 1)
    var averagePorto = sumPorto / (carrosPorto.length || 1)
    var averageDesapeacao = sumDesapeacao / (carrosDesapeacao.length || 1)

    // Soma e média dos agrupamentos em segundos (delta está em segundos)
    return { 
        sumDescarga, 
        sumDesapeacao, 
        sumPorto, 
        averageAmv,
        averagePortoAmv,
        averageDescarga,
        averagePorto,
        averageDesapeacao
    }
}

const operationsCounter = (totalTrucks, placeLocations) => {
    var descarga = placeLocations.find(e => e.name === "Descarga")
    var descapeacao = placeLocations.find(e => e.name === "Desapeação")
    var porto = placeLocations.find(e => e.name === "Porto")

    var portoCount = !isNaN(porto.count) ? parseInt(porto.count) : 0
    var despeasaoCount = !isNaN(descapeacao.count) ? parseInt(descapeacao.count) : 0
    var descargaCount = !isNaN(descarga.count) ? parseInt(descarga.count) : 0

    var amvCount = descargaCount + despeasaoCount

    return {
        onOperation: totalTrucks,
        onRoad: totalTrucks - (amvCount + portoCount),
        onPorto: portoCount,
        onAMV: amvCount
    }
}

const scannersFromPlance = (data) => {
    var result = data.filter(e => {
        var found = false
        PlaceNames.forEach(placeName => {
            if(placeName.place_name === e.place_name) {
                found = true
                e.name = placeName.name
            }
        })
        return found
    })
    return result
}

export default Principal