import React from 'react'

import Main from '../../components/Main'

//custom components
import Total from './Total'
import Entering from './Entering'
import Leaving from './Leaving'
import Flow from './Flow'
import MovementPanel from './MovementPanel'

//material-ui
import Grid from '@material-ui/core/Grid'

function Dashboard(){

  const panel = [
    {name: 'Luiz Fellype', status: 'in', place: 'Logistica', time: '10:00 PM'},
    {name: 'Usuário 2', status: 'out', place: 'Setor A', time: '12:00 PM'}
  ]
  return (
    <Main color="#f7f7f7">
      <Grid container spacing={3} style={{marginLeft: 2}}>
        <Grid container spacing={3} lg={9} md={9}>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Total  />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Entering  />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
                <Leaving  />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Flow  />
            </Grid>
        </Grid>
        <Grid container lg={3} md={3} sm={12} xs={12}>
          <MovementPanel data={panel} />
        </Grid>
      </Grid>
    </Main>
  )
}


export default Dashboard;