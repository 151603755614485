import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from 'material-table'
import moment from 'moment';


//custom components
import api from "../../../../../core/services/api";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ListItem from '../ListItem';

//material ui
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from '@material-ui/icons/Inbox';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search'




function TablePallets() {
    const [search, setSearch] = React.useState('')
    const [state, setState] = React.useState({
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    });
    const [age, setAge] = React.useState('');
    useEffect(() => {
      getData();
    }, [state.endDate, state.startDate]);
    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const handleStartDate = (date) => {
      setState((prev) => ({
        ...prev,
        startDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
  
    const handleEndDate = (date) => {
      setState((prev) => ({
        ...prev,
        endDate: moment(date).format("YYYY-MM-DD"),
      }));
    };

    async function getData() {
      try {
        const data = await api.get(`inventories?start=${state.startDate}&end=${state.endDate}`);
        setState((prev) => ({
          ...prev,
          inventories: data.data || [],
          filteredInventories: data.data || []
        }));
      } catch (error) {
        console.warn(error);
      }
    }
    function filterData(value) {
      const filter = state.inventories.filter((inventorie) => {
        return inventorie.lpn.toLowerCase().includes(value.toLowerCase()) || inventorie.posicaopallet.toLowerCase().includes(value.toLowerCase());
      });
      setState((prev) => ({
        ...prev,
        filteredInventories: filter,
      }));
      setSearch(value);
    }

  return (<>
       <div
        style={{
          display: "flex",
          justifyContent: "left",
          flex: 1,
          marginBottom: "24px",
        }}
      >
         <TextField
                  style={{marginRight: 24}}
                  id="name"
                  name="name"
                  label="Buscar"
                  type="text"
                  value={search}
                  onChange={(e) => filterData(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                
                />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ marginRight: "24px" }}
            autoOk={true}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            label="Data Inicial"
            value={moment(state.startDate, "YYYY-MM-DD")}
            onChange={handleStartDate}
            KeyboardButtonProps={{
              "aria-label": "state Inicial",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            style={{ marginRight: "24px" }}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            label="Data Final"
            value={moment(state.endDate, "YYYY-MM-DD")}
            onChange={handleEndDate}
            KeyboardButtonProps={{
              "aria-label": "Data Inicial",
            }}
          />
        </MuiPickersUtilsProvider>
      
    </div>
       <MaterialTable
      title="Pallets"
      columns={[
        { title: 'LPN', field: 'lpn', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },},
        { title: 'Quantidade de itens', field: 'quantidadeitens', type: 'numeric', cellStyle: { textAlign: "center", border: "#fff" }, },
        { title: 'Posição Pallet', field: 'posicaopallet', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, },
        { title: 'Última atualização', field: 'ultimaatualizacao', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, },
        {
            field: "actions",
            title: "Itens",
            cellStyle: { padding: 0, paddingLeft: 40, textAlign: "right", border: "#fff", width: 20, maxWidth: 20 },
          headerStyle: { width:20, maxWidth: 20 },
            render: (rowData) => (
              <>
                <FormGroup row>
                 <ListItem lpn={rowData.lpn} sku={rowData.sku} quantidade={rowData.quantidade} lote={rowData.lote} />
                </FormGroup>
              </>
            ),
          },
      ]}
      data={state.filteredInventories}       
      options={{
        pageSize: 10,
        actionsColumnIndex: -1,
        search: true,
        showTitle: false,
        headerStyle: {
          paddingLeft: 40,
          textAlign: "left",
          backgroundColor: "#e8ebef",
          border: "#fff",
        },
        rowStyle: (x) => {
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f3f5f8" };
          }
        },
      }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "none" }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      style={{
        borderRadius: 5,
      }}
      localization={{
        body: {
            emptyDataSourceMessage: 'Carregando dados'
        }
    }}
    />
  </>);
}

export default TablePallets;
