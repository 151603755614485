import React, { useState } from "react";

//custom components
import Main from "../../components/Main";
import MapsLocation from "../../components/MapLocations";
import SearchkBox from "../../components/SearchBox";
import SelectDropdownMaterial from "../../components/SelectDropdownMaterial";
// import Dropdown from "../../components/";
import { Container, FormFilters, Content } from './styles'

const items = {
  Geolocalização: [
    {id: 1,value: 'Dentro da cerca'},
    {id: 2,value: 'Fora da cerca'},
  ],
  Consumo: [
    {id: 1,value: 'Dentro do estipulado'},
    {id: 2,value: 'Fora do estipulado'},
  ],
  Status: [
    {id: 1,value: 'Ligado'},
    {id: 2,value: 'Desligado'}
  ]
  
};

const clientes = [
  {
    id: 1,
    value: 'cliente 1',
  },
  {
    id: 2,
    value: 'cliente 2',
  },
  
];

const equipamento = [
  {
    id: 1,
    value: 'equipamento 1',
  },
  {
    id: 2,
    value: 'equipamento 2',
  },
  
];

const regiao = [
  {
    id: 1,
    value: 'norte',
  },
  {
    id: 2,
    value: 'sul',
  },
  {
    id: 3,
    value: 'leste',
  },
  {
    id: 4,
    value: 'oeste',
  },
];



// import TableMachines from "./TableMachines";

function GeneralMap() {

  const [filters, setFilter] = useState(null)

  function handleSelectMap(map) {
    setFilter(map)
  }

  return (
    <Main color="#eeeeee" title="Mapa Geral" selectMap = {handleSelectMap} >
        <Content>
          {console.log('Filters : ', filters)}
          {filters != null && 
            <FormFilters>
             <SearchkBox />
             <SelectDropdownMaterial title={filters} items={items[filters] || []}/>
             <SelectDropdownMaterial title={"Cliente"} items={clientes}/>
             <SelectDropdownMaterial title={"Equipamentos"} items={equipamento}/>
             <SelectDropdownMaterial title={"Região"} items={regiao}/>
           </FormFilters>
          }   
        </Content>
        <Container>
          <MapsLocation />
        </Container>
         
    </Main>
  );
}

export default GeneralMap;



