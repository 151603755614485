import React, { useState, useEffect, useRef } from 'react';

import Dropdown from "../../components/SelectDropdown";
import SelectDropdownMaterial from "../../components/SelectDropdownMaterial";
import SelectDate from "../../components/SelectDate"

import {Container, Header, ContainerGraph, ContainerHeader, ContainerOptions, Containerinfos, Legend, Title, LegendICon, ContentOptions} from './styles'
import { Bar } from 'react-chartjs-2'

const items = [
    {
      id: 1,
      value: '1236',
    },
    {
      id: 2,
      value: '5647',
    },
    
  ];
  
  const clientes = [
    {
      id: 1,
      value: 'cliente 1',
    },
    {
      id: 2,
      value: 'cliente 2',
    },
    
  ];
  
  const equipamento = [
    {
      id: 1,
      value: '800A',
    },
    {
      id: 2,
      value: '700A',
    },
    
  ];

const data = {
   
    title: {
        display: false,
    },
  labels: ['Mar 10,2020', 'Mar 11,2020', 'Mar 12,2020', 'Mar 13,2020', 'Mar 14,2020', 'Mar 15,2020'],
  datasets: [
    {
        
      data: [1000, 1500, 980, 1250, 1450, 1390],
      backgroundColor: [
        'rgba(125, 119, 255, 0.9)',
        'rgba(125, 119, 255, 0.9)',
        'rgba(125, 119, 255, 0.9)',
        'rgba(125, 119, 255, 0.9)',
        'rgba(125, 119, 255, 0.9)',
        'rgba(125, 119, 255, 0.9)',
      ],
    },
  ],
}

const options = {

  scales: {
    responsive:true, 
    maintainAspectRatio: false,
    yAxes: [
      {
        ticks: {
            autoSkip: true,
            max: 1500,
            min: 0,
            stepSize: 500,
            
          beginAtZero: true,
        },
      },
    ],
  },
}


function ConsumoFitaDash() {

    return(
        <>
            {/* <Container>
                <Header>
                <ContainerHeader>
                    
                    <Containerinfos>
                        <Legend>
                            <LegendICon/>
                            <p style={{marginBottom:0, marginLeft: 10}}>Consumo em m²</p>
                        </Legend>
                        <Title>
                            <p style={{marginBottom:0}}>Consumo de Fita Visão Geral</p>
                        </Title>
                    </Containerinfos> */}

                    <ContainerOptions>
                        <SelectDate title="Selecione um período"/>
                        {/* <SelectDropdownMaterial title={"Número Máquina"} items={items} /> */}
                        <SelectDropdownMaterial title={"Tipo Máquina"} items={equipamento}/>
                        <SelectDropdownMaterial title={"Cliente"} items={clientes}/>
                    </ContainerOptions>

               {/*  </ContainerHeader>
                </Header>
                <ContainerGraph>
                    <Bar data={data} options={{
                        responsive:true, 
                        maintainAspectRatio: false, 
                        legend: {
                            display: false
                        },
                         scales:{
                            yAxes: [
                                {
                                  ticks: {
                                      
                                      max: 1500,
                                      min: 0,
                                      stepSize: 500,
                                      
                                    beginAtZero: true,
                                  },
                                  gridLines: {
                                    display: true
                                },
                                },
                              ],
                            xAxes: [
                                {
                                    gridLines: {
                                        display: false
                                    },
                                }
                            ]    
                        }
                        
                    }} />
                </ContainerGraph>
            </Container> */}
        </>
    )
}

export default ConsumoFitaDash