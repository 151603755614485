export default function (cols, idleness) {
  return {
      colors: ['#2DE0C2', '#26A0FC'],
      chart: {
        type: 'bar',
        height: 350
      },
      annotations: {
        yaxis: [
          {
            y: idleness,
            strokeDashArray: 2,
            borderColor: "#b53f3f",
            label: {
              borderColor: "#b53f3f",
              style: {
                color: "#fff",
                background: "#b53f3f"
              },
            }
          },
         
        ],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '75%',
          dataLabels: {
            position: "top", // top, center, bottom
          },
          line: {
            horizontal: true,
            columnWidth: '100%'
          }
        },
      },
      dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(1) + "%";
          },
          offsetY: -25,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: cols,
      },
      fill: {
        opacity: 1
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          formatter: function(val) {
            return val.toFixed(1) + "%";
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "Ociosidade " + val.toFixed(1) + "%"
          }
        }
      }
    }
    
}