import React from 'react';
import Paper from '@material-ui/core/Paper';
import {Content, IconContainer} from './styles';
import forkIcon from '../../../../assets/icone_empilhadeira.png';

export default ({color, children}) => {
    return(
        <Paper >
            <IconContainer color={color}>
                <img width="25" height="auto" src={forkIcon} alt=""/>
            </IconContainer>
            <Content>
                {children}
            </Content>
        </Paper>
    )
};
