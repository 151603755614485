import styled from 'styled-components';

export const DivWrapper = styled.div`

    display: flex;
    flex-direction: row;
    min-height: 38px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 250px;
    height: 30px;
    box-sizing: border-box;
    margin-left: 20px;
    
`

export const DivHeader = styled.div`

    width: 100%;
    height: 100%;
    display:flex;
    font-size:16px;
    justify-content: space-between;
    align-items:center;
    background: white;
    border: 1px solid white;
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    transition: 0.3s;

    p {
        margin: 0;
        padding: 10px;
    }

    :focus{
        outline-style: none;
        border: 1px solid #D3D3D3;
        transition: 0.5s
    }
`

export const DivList = styled.ul`
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index:99999;
    border-radius: 0;

    li {
    list-style-type: none;

    button {
        display: flex;
        background-color: #C2CBD8;
        font-size: 16px;
        margin-top: 2px;
        padding: 15px 20px 15px 20px;
        border-radius: 0;
        border: 1px solid #ccc;
        width: 100%;
        text-align: left;
        transition: 0.2s
        color: white;
        font-weight: bold;
    
        &:hover, &:focus {
        cursor: pointer;
        background-color: #ccc;
        }
    }
    }
`


export const ContainerIMG = styled.div`
    display: flex;
    width: 40px;
    height: 100%;
    box-sizing: border-box;
    align-items:center;
    justify-content: space-between;
`

export const Placeholder = styled.span`
    color: #D3D3D3;
`





