import styled from 'styled-components';

export const Alert = styled.div`
    background: white;
    width: 100%;
    height: 80px;
    display:flex;
    color: black;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    transition: 0.3s;
    align-items: center;
 
    :hover{
        background: #f3f3f3de;  
    }

    :focus{
        outline-style: none;
      }
`
export const ImgAlert = styled.img`
      width: 40px;
      height:40px;

`

export const ContainerIMG = styled.div`
      width: 60px;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;
`

export const MsgAlert = styled.p`
    margin-bottom: 0 !important;
    margin-left: 20px !important;
    box-sizing: border-box;
    font-size: 22px;
`

export const ContainerMSG = styled.div`
      width: 80%;
      height:100%;
      display: flex;
      align-items: center;

`


export const ButtonExcludAlert = styled.button`
    background: #1298E8;
    width: 15%;
    height: 50px;
    margin-right:20px;
    color: white;
    border-radius: 4px;
    borde-box: box-sizing;
    border: 1px solid black;
    transition: 0.3s;
    font-size:14px;
    
    :hover{
        background: #1298BE;  
    }

    :focus{
        outline-style: none;
      }
`


