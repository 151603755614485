import React from "react";
import Grid from "@material-ui/core/Grid";

import { CounterContainer, Title, Value } from './styles';

export default ({title, value}) => {
  return (
    <Grid item xs={4}>
      <CounterContainer>
        <Title>{title}</Title>
        <Value>{value}</Value>
      </CounterContainer>
    </Grid>
  );
};
