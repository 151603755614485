import React, { useState } from "react";

// Styled Components
import { Sidebar } from "../../styles/Map";
import { MachinesContainer } from "./styles";

// @material-ui/core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// @material-ui/icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from '@material-ui/icons/Person'

//custom components
import Machine from "./Machine";
import Operation from "./Operation";

const options = ["Todos", "Movimento", "Parado", "Desligado"];

function SidebarContainer(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setSelectedValue] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedValue(index);
    setAnchorEl(null);
    props.filterSelect(options[index].toLowerCase());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setOpensidebar(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setOpensidebar(false);
  };

  return (
    <>
      <React.Fragment key="right">
        <Button
          style={{
            marginTop: 80,
            position: "absolute",
            float: "right",
            right: open ? 330 : 0,
            backgroundColor: "#fff",
            zIndez: open ? 9999 : 0,
            transition: "all .35s ease",
          }}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          {open ? <ChevronRightIcon /> : <PersonIcon />}
        </Button>
        <Drawer anchor="right" open={open} variant="persistent" >
          <Sidebar>
          <List
              component="nav"
              aria-label="main mailbox folders"
            
            >
          <MachinesContainer style={{ margin: 15 }}>
          <Grid container>
            {props.operations.map(({ name }) => (
              <Operation operation={name} />
            ))}
          </Grid>
          </MachinesContainer>
           
              <FormGroup>
              {props.children}
              </FormGroup>
              <Typography variant="subtitle1" gutterBottom style={{margin: 15, color: '#797979'}}>Pessoas</Typography>
              {props.data
                ? props.data.map((carro) => (
                    <Machine
                      navigate={props.changeViewport}
                      people={carro}
                      setColor={props.setColor}
                      selectedIndex={selectedIndex}
                      handleListItemClick={handleListItemClick}
                    />
                  ))
                : ""}
           </List>
          </Sidebar>
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default SidebarContainer;
