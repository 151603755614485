import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root:{
    display: "flex",
    width: '100%',
    minWidth:180,
    margin:0,
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    background: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
   
}));

export default function SelectDropdownMaterial(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{props.title}</InputLabel>
        <Select
            native
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          placeholder={props.title}
          onChange={handleChange}
          label={props.title}     
        >
          <option aria-label="None" value="" />
          {props.items.map((item) => {
            return <option value={item.id} >{item.value}</option>
          })}
        </Select>
      </FormControl>
     
    </div>
  );
}
