import React from "react";
import Main from "../../components/Main";

//custom components
import TableClients from "./TableClients";

function ModuleList() {
  return (
    <Main color="#eeeeee" title="Clientes">
      <TableClients />
    </Main>
  );
}

export default ModuleList;
