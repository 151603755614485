import styled from 'styled-components';


export const Container = styled.div`
   width: 100%;
   height: 90VH;
   background: white;
   display: flex;
   align-items: center;
//    justify-content: center;
   flex-direction:column;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   transition: all 0.3s cubic-bezier(.25,.8,.25,1);
   border-radius: 3px;
`;
export const Header = styled.div`
   width: 100%;
   height: 150px;
   display:flex
   align-items: center;
   justify-content: center;
   margin-bottom: 15px;
`;

export const ContainerHeader = styled.div`
   width: 80%;
   height: 100%;
   display:flex
   flex-direction:column;
  
`;

export const ContainerOptions = styled.div`
    
   width: 100%;
   height: 100%;
   display:flex
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-content: space-between;

`;

export const Legend = styled.div`
    width: 30%;
   height: 100%;
   display:flex
   align-items: center;
`;

export const Title = styled.div`
    width: 70%;
   height: 100%;
   display:flex
   align-items: center;
   font-size: 22px;
`;

export const LegendICon = styled.div`
    width: 20px;
   height: 20px;
   border: 0px solid black;
   border-radius: 5px;
   background: rgba(125, 119, 255, 1)
`;

export const Containerinfos = styled.div`
   height: 100%;
   display:flex
`;


export const ContainerGraph = styled.div`
   width: 90%;
   height: 80%;
   background: white;
   align-items: center;
   justify-content: center;
`;

export const ContentOptions = styled.div`
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;