import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

export default function WhirpoolSelect(props) {
  return (
    <>
      <FormControl
       fullWidth
      >
        <InputLabel>{props.label}</InputLabel>
        <Select
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          fullWidth
          displayEmpty={true}
        >
          <MenuItem value={0}> </MenuItem>
          {props.data.map((item) => {
            return <MenuItem value={item.module_id || item.cod_client || item.id}>{item.uf || item.name || item.module_id || item.resource.cod_client}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </>
  );
}
