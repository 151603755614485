import React, { useEffect } from "react";
import api from "../../../../../../core/services/api";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';


const currencies = [
  {
    value: '38',
    label: '38m',
  },
  {
    value: '45',
    label: '45m',
  },
  {
    value: '48',
    label: '48m',
  },
  {
    value: '50',
    label: '50m',
  },
  {
    value: '72',
    label: '72m',
  },
];

const clients = [
  {
    value: 'Cliente 1',
    label: 'Cliente 1',
  },
  {
    value: 'Cliente 2',
    label: 'Cliente 2',
  },
  {
    value: 'Cliente 3',
    label: 'Cliente 3',
  }
]

const modules = [
  {
    value: 'AWSDRSWETFC3',
    label: 'AWSDRSWETFC3',
  },
  {
    value: '45RTYUGHJDSE65',
    label: '45RTYUGHJDSE65',
  },
  {
    value: '2132DFSEDFRD',
    label: '2132DFSEDFRD',
  }
]

const maquinas = [
  {
    value: '700A',
    label: '700A',
  },
  {
    value: '800A',
    label: '800A',
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}));

function EditForm(props) {

  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [state, setState] = React.useState("");
  const [module_id, setModule] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState(modules.value);
  // const [inputValue, setInputValue] = React.useState('');
  const handleClickOpen = () => {
    setState({
      maquina: props.maquina,
      cliente: props.cliente,
      qtd_fita: props.qtd_fita,
      funcionamento: props.funcionamento,
      qtd_fita_utilizada: props.qtd_fita_utilizada,
      disabled: false,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id_maquina = target.id_maquina;

    setState((prev) => ({
      ...prev,
      [id_maquina]: value,
    }));
  };

  async function updateModules() {
    
    try {
      const oldModules = {
        module_id: state.modules.Module_id,
      }
      const newModules = {
        module_id: module_id,
      };
      
      if (module_id.module_id == state.modules.module_id){
        console.log("dados iguais, não atualizar")
      } else {
        setState((prev) => ({
          ...prev,
         disabled: true,
        }));
      //  await api.put(`forklift-update/` + state.forklift.id, newForklift);
        props.getData();
      }
      setState("")
      setOpen(false);
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#0C0C0B" }} className="icon-small" />
      </IconButton>

      <Dialog
        style={{ marginTop: 55}}
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
      >
        <DialogTitle>Editar Maquina {props.maquina}</DialogTitle>
        {state ? (  <DialogContent>
          <form className={classes.root}> 
            <div style={{marginBottom: 12}}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                      // error={state.error ? (forklift_number ? false : true) : false}
                      required
                      id="id_maquina"
                      name="id_maquina"
                      label="ID Maquina"
                      type="number"
                      fullWidth
                      value={props.maquina}
                      // onChange={(e) => setName(e.target.value)}    
                    />{" "}
                </Grid>
                <Grid item xs={8}>
                <TextField
                      // error={state.error ? (macaddress ? false : true) : false}
                    required
                    select
                    id="nome_maquina"
                    label="Nome Maquina"
                    type="text"
                    fullWidth
                    defaultValue="800A"
                    // onChange={(e) => setMacaddress(e.target.value)}
                  >
                    {maquinas.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={state.error ? (macaddress ? false : true) : false}
                    required
                    id="qtdFita"
                    label="Quantidade Fita Estipulada"
                    type="number"
                    // defaultValue="800"
                    defaultValue={props.qtd_fita}
                    // value={props.qtd_fita}
                    // onChange={(e) => setMacaddress(e.target.value)}
                    fullWidth
                  />{" "}
                </Grid>
               
                <Grid item xs={6}>
                <TextField
                    // error={state.error ? (macaddress ? false : true) : false}
                    required
                    select
                    id="largura_fita"
                    label="Largura da Fita"
                    type="number"
                    helperText="Selecione a Largura da fita"
                    defaultValue="50"
                    // value={macaddress}
                    // onChange={(e) => setMacaddress(e.target.value)}
                    fullWidth
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField> 
                  
                </Grid>
                <Grid item xs={6}>
                <Autocomplete
                  id="id_modulo"
                  options={modules}
                  required
                  getOptionLabel={(option) => option.value}
                  autoComplete
                  style={{ paddingTop: 2 }}
                  size='small'
                  defaultValue="45RTYUGHJDSE65"
                  // value={props.modulo} 
                  renderInput={(params) => <TextField {...params} label="ID Modulo" />}      
                />
                  {/* <TextField
                    // error={state.error ? (macaddress ? false : true) : false}
                    select
                    
                    required
                    id="id_modulo"
                    label="ID Módulo"
                    type="search"
                    // value={macaddress}
                    // onChange={(e) => setMacaddress(e.target.value)}
                    fullWidth
                  >
                    {modules.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))} 
                  </TextField>  */}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={state.error ? (macaddress ? false : true) : false}
                    select
                    required
                    id="id_cliente"
                    label="ID Cliente"
                    type="text"
                    // value={macaddress}
                    // onChange={(e) => setMacaddress(e.target.value)}
                    fullWidth
                  >
                     {clients.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}  
                  </TextField> 
                </Grid>
              </Grid>
            </div>
          </form>

          
        </DialogContent>) : ""}
        <DialogActions style={{ padding: 12 }}>
        <Grid 
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end">

          <FormControlLabel
            control={
              <Switch
                checked={state.active}
                onChange={(event) => handleChange(event)}
                name="active"
                color="primary"
              />
            }
            label={state.active ? "Ativo" : "Inativo"}
          />
          </Grid> 
          <Grid  
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end">
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
          disabled={state.disabled}
            // onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </Grid>
        </DialogActions>
        
      </Dialog>
    </div>
  );
}

export default EditForm;
