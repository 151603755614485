import React from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";

function Timeline(){


    const timeline = [
        {name: 'Luiz Fellype', subscription: '1234', place: 'Area de Vivências', enter_time: '10:00 AM', leave_time: '14:00 PM', perm_time: '03:12h', status: 'Perda'},
        {name: 'Luiz Fellype', subscription: '1234', place: 'Almoxarifado', enter_time: '10:00 AM', leave_time: '14:00 PM', perm_time: '03:12h', status: 'Perda'}
    ]
    return (
        <Main color="#f7f7f7" title="Histórico de Ativos">
              <MaterialTable
        columns={[
          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '10%' },
          },
          {
            title: "Inscrição",
            field: "subscription",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
          {
            title: "Local",
            field: "place",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
          {
            title: "Entrou",
            field: "enter_time",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
          {
            title: "Saiu",
            field: "leave_time",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
           {
            title: "Permanência",
            field: "perm_time",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
          {
            title: "Produção / Perda",
            field: "status",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
          },
        ]}
        data={timeline}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id == 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />
        </Main>
    )
}


export default Timeline;