export default function(cols, idleness) {
  return {
      colors: ["#7D77FF"],
      chart: {
        height: 350,
        type: "bar",
      },
       annotations: {
        yaxis: [
          {
            y: idleness,
            strokeDashArray: 2,
            borderColor: "#b53f3f",
            label: {
              borderColor: "#b53f3f",
              style: {
                color: "#fff",
                background: "#b53f3f"
              },
            }
          },
         
        ],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1) + "%";
        },
        offsetY: -25,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: cols,
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val.toFixed(1) + "%";
          },
        },
      },
    }
}