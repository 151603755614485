import React from 'react'

//@material-ui
import Grid from '@material-ui/core/Grid'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'

//@material icons


//custom components

import LoginIcon from '../../../assets/icons/login.svg';
import LogoutIcon from '../../../assets/icons/logout.svg';
import StatusCard from './StatusCard'

function MovementPanel(props){

    return (
        <Grid container direction="column" style={{paddingTop: 10, paddingLeft: 24, paddingRight: 12}}>
            {props.data ? (
                props.data.map((item) => {return (
                    <div style={{marginBottom: 35}}>
                    <StatusCard name={item.name} status={item.status} time={item.time} place={item.place}/>
                    </div>
                )})
            ) : ""}
        </Grid>
    )
}

export default MovementPanel;