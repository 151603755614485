import React from "react";
import Main from "../../components/Main";
import api from "../../../../core/services/api";

import { Content } from './styles'

//custom components
import SelectDate from "../../components/SelectDate"
import SearchkBox from "../../components/SearchBox";
import Dropdown from "../../components/SelectDropdown";
import SelectDropdownMaterial from "../../components/SelectDropdownMaterial";

import Alert from "../../components/SelectDropdown";
import TableAlerts from '../../components/TableAlerts'

//@material-ui
import Grid from "@material-ui/core/Grid";


const clientes = [
  {
    id: 1,
    value: 'cliente 1',
  },
  {
    id: 2,
    value: 'cliente 2',
  },
  
];

const maquinas = [
  {
    id: 1,
    value: '800A',
  },
  {
    id: 2,
    value: '700A',
  },
  
];

const dados = [
  {
    id: 1,
    value: 'São Paulo',
  },
  {
    id: 2,
    value: 'Campinas',
  },
  
];

function Alerts(props) {

  return (

      <Main color="#eeeeee" title="Alertas">
        <Content>
          <SearchkBox />
          <SelectDate title="Selecione um período"/>
          <SelectDropdownMaterial title="Tipo Máquina" items={maquinas}/>
          <SelectDropdownMaterial title="Cliente" items={clientes}/>
        </Content>
      
        <TableAlerts/>
      </Main>
   
  );
}

export default Alerts;


