import React, {useState} from 'react';
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//custom components
import {BlockedProgress, SegregatedProgress, StructuredProgress, TravelProgress} from './Progress'
import {DivWrapper, DivHeader, ContainerIMG, Placeholder} from './styles'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


function Table(){
    const [state, setState] = useState({
      operations_id: null,
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    })
    const handleStartDate = (date) => {
      setState((prev) => ({
        ...prev,
        startDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
    const handleEndDate = (date) => {
      setState((prev) => ({
        ...prev,
        endDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
   
    const forklifts = [
        {name: "143", type: '700A', consume: 10},
        {name: "555", type: '700B', consume: 80}
    ]
    

    return (
        <MaterialTable 
        columns={[
          {
            title: "Nº Máquina",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left", paddingRight: 80, border: "#fff", width: '10%' },
            headerStyle: {borderBottom: '#fff', borderRadius: '8px 0px 0px 0px'},
           
          },
          {
            title: "Modelo",
            field: "type",
            cellStyle: {paddingLeft: 24, textAlign: "left", paddingRight: 80, border: "#fff", width: '10%' },
            headerStyle: {borderBottom: '#fff'},
           
          },
          {
            title: "Consumo dentro do estipulado",
            field: "consume",
            cellStyle: {paddingLeft: 24, textAlign: "left", border: "#fff", width: '60%'},
            headerStyle: {borderBottom: '#fff', borderRadius: '0px 8px 0px 0px'},
            render: (rowData) => (<><Grid container direction="row"
            justify="flex-start"
            alignItems="center" spacing={2}><Grid item>{rowData.consume}%</Grid><Grid item md={8} lg={8} sm={8}><BlockedProgress variant="determinate" value={rowData.consume} /></Grid></Grid></>)
          }
        ]}
        data={forklifts}
        options={{
          pageSize: 5,
          actionsColumnIndex: -1,
          showFirstLastPageButtons: false,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#C2c9d4",
            color: "#fff",
            border: '#fff',
            //boderRadius: 30,
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
          Body: (props) => (
            <>
            <MTableBody {...props} />

            {/* <div style={{padding: '0px 10px'}}>
            
            <div style={{float: 'left', width: '33%'}}>Gran Total</div>
            <div style={{position: 'relative', width: '50%', left: 370}}> 66%</div>
            
            </div>
  
 */}
            
            </>
          ),
        }}
        style={{
          boxShadow: '0px 0px #fff'
        }}
      />   
       
    )
}

export default Table;