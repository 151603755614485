import React, {useState, useEffect} from 'react'
import api from '../../../../../core/services/api'


//materialui
import Grid from '@material-ui/core/Grid'

//custom
import MapScanners from './Map'


function MapPanel(){
    const [state, setState] = useState({
        scanners: [],
    })


    useEffect(() => {
        getScanners();
        const interval = setInterval(() => {
          getScanners();
          console.log('Atualizando...')
        }, 20000);
        return () => clearInterval(interval);
      }, []);

    async function getScanners() {
        try {
          const resp = await api.get(`dashboard-scanners`);
          setState((prev) => ({
            ...prev,
            scanners: resp.data.data || []
          }));
        } catch (err) {
          console.warn(err);
        }
      }

    return(
        <Grid container spacing={3}>
           <Grid item><MapScanners scanners={state.scanners} /></Grid>
        </Grid>
    )
}


export default MapPanel;