import css from "../css/common/dropdown.module.css";
import React, { Component } from 'react';

class DropDown extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            content: props.content,
            css: props.css || css
        };
    }

    componentDidMount() {
        window.addEventListener("mousedown", this.dismissList.bind(this));
        window.addEventListener("keydown", this.dismissList.bind(this));
    }

    dismissList(e) {
        const dropDown = this.refs.dropDown;
        const list = this.refs.list;

        if (!dropDown || !list)
            return;

        if (dropDown.contains(e.target))
            return;

        if (e.currentTarget !== dropDown || e.key === "Escape" || e.code === "Escape" || e.which === 27)
            list.style.display = "none";             
    }
    
    buttonHandler(e) {
        if (!this.state.content || this.state.content.length === 0)
            return;

        const list = this.refs.list;

        list.style.display = list.style.display === "none" || list.style.display === "" ? "block" : "none";

        if (this.props.onToggle)
            this.props.onToggle(e);
    }

    optionsHandler(e) {
        const list = this.refs.list;
        const button = this.refs.button;

        list.style.display = "none";
        button.textContent = e.target.textContent;

        if (this.props.onSelect)
            this.props.onSelect(e);
    }

    render()
    {
        return (
            <div {...this.props}>
                <div className={this.state.css.dropdown} ref="dropDown" onClick={this.dismissList.bind(this)}>
                    <div className={this.state.css.row} onClick={this.buttonHandler.bind(this)}>
                        <button className={this.state.css.dropbtn} ref="button">{this.props.initialValue || this.props.content[0] || 'Todos'}</button>
                        <div className={this.state.css.arrow} ref="arrow"></div>
                    </div>
                    <div className={this.state.css.dropDownContent} ref="list" onClick={this.optionsHandler.bind(this)}>
                        {this.state.content}
                    </div>
                </div>
            </div>
        )
    }
}

export default DropDown;