import React from "react";

// Material UI
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import Icon from "@material-ui/core/Icon";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";

// Utils
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export default function General({
  forkliftID,
  operationID,
  changeForkliftID,
  forklifts,
  classes,
  startDate,
  endDate,
  handleEndDate,
  handleStartDate,
  handleChangeDateRange,
  dateRange,
  dateRangeID,
  changeDateRange,
  loading,
  check_ociosity,
  handleChange,
  handleSubmit,
  enableBtnFilter,
  showTimeFilter,
  baseTime,
  handleTimeChange
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "left",
        flex: 1,
        marginBottom: "24px",
      }}
    >
      {showTimeFilter}
      <Select
        displayEmpty
        value={forkliftID}
        onChange={changeForkliftID}
        style={{ width: "223px", height: "48px", marginRight: "24px" }}
        placeholder="Empilhadeiras"
      >
        <MenuItem key="all" value={undefined}>
          Empilhadeiras
        </MenuItem>
        {forklifts.length > 0 &&
          forklifts
            .filter((item) => {
              if (!operationID) return true;
              if (operationID == item.operation_place_id) return true;
            })
            .map(({ id, forklift_number }) => (
              <MenuItem key={id} value={forklift_number}>
                {forklift_number}
              </MenuItem>
            ))}
      </Select>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ marginRight: "24px" }}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label="Data Inicial"
          value={moment(startDate, "YYYY-MM-DD")}
          onChange={handleStartDate}
          KeyboardButtonProps={{
            "aria-label": "state Inicial",
          }}
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ marginRight: "24px" }}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label="Data Final"
          value={moment(endDate, "YYYY-MM-DD")}
          onChange={handleEndDate}
          KeyboardButtonProps={{
            "aria-label": "Data Inicial",
          }}
        />
      </MuiPickersUtilsProvider>
      <Select
        style={{ marginRight: "24px" }}
        displayEmpty
        value={dateRangeID}
        onChange={handleChangeDateRange}
        style={{ width: "223px", height: "48px" }}
        placeholder="Período"
      >
        <MenuItem key="all" value={undefined}>
          Período
        </MenuItem>
        {dateRange.length > 0 &&
          dateRange.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
      </Select>
      {showTimeFilter ? (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check_ociosity}
                  onChange={(e) => handleChange(e)}
                  name="check_ociosity"
                  color="primary"
                />
              }
              label="Filtrar tempo"
            />
          </FormGroup>
          {check_ociosity ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <TextField onChange={(e) => handleTimeChange(e)} value={baseTime} name="baseTime" id="standard-basic" label="Tempo (minutos)" />
                }
              />
            </FormGroup>
          ) : null}
        </>
      ) : null}
      {/* <FormControlLabel
      control={
        <Checkbox
          checked={state.check_shift}
          onChange={handleChange}
          name="check_shift"
          color="primary"
        />
      }
      label="Somente Turnos"
    /> */}

      <div className={classes.btn}>
        <Button
          variant="contained"
          color="primary"
          disabled={enableBtnFilter}
          startIcon={<FilterListIcon />}
          onClick={handleSubmit}
        >
          Fazer Download
        </Button>
      </div>
      {/* <div className={classes.btn}>
    <Button
      variant="contained"
      color="primary"
      disabled={state.checkboxStatus}
      endIcon={<InsertChartIcon />}
      onClick={exportFile}
    >
      Exportar
  </Button>
  </div> */}
      {loading ? <CircularProgress color="primary" /> : ""}
    </div>
  );
}
