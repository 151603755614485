import React, { useEffect } from "react";
import api from "../../../../../core/services/api";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//custom components
import WhirpoolSelect from "../../../components/WhirpoolSelect";

function EditForm(props) {
    const [state, setState] = React.useState({
        username: "",
        email: "",
       
        groups: props.groups,
        system_client_id: "",
        error: false,
        disabled: false
      });
      const [username, setUsername] = React.useState("");
      const [password, setPassword] = React.useState("");
      const [password2, setPassword2] = React.useState("");
      const [email, setEmail] = React.useState("");
      const [group_id, setGroupid] = React.useState("");
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setState((prev) => ({
          ...prev,
          groups: props.groups,
          group_id: props.oldUser.group_id,
          oldUser: props.oldUser
        }));
        setUsername(props.oldUser.username);
        setEmail(props.oldUser.email);

        setOpen(true);
      };
    

  const handleClose = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setPassword2("");
    setState("");
    setOpen(false);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleSubmit() {
    if(!state.check){
      //SEM ALTERAR SENHA
      if (!email || !username || !state.group_id) {
        setState((prev) => ({
          ...prev,
          error: true,
        }));
      } else {  
        setState((prev) => ({
          ...prev,
        disabled: true,
        }));
        updateUser();   
      }
    } else {
      //ALTERANDO A SENHA
      if (!email || !username || !state.group_id || !password || !password2) {
        setState((prev) => ({
          ...prev,
          error: true,
        }));
        
      } else {
        if(password != password2){
          setState((prev) => ({
            ...prev,
            error: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
          disabled: true,
          }));
          updateUser();
        }
    }

    }
  }

  async function updateUser() {
    try {

      if(!state.check){
        //SEM ALTERAR A SENHA
        const oldUser = {
          username: state.oldUser.username,
          email: state.oldUser.email,
          group_id: state.oldUser.group_id,
        };
        const newUser = {
          id: state.oldUser.id,
          username: username.toLowerCase(),
          email: email.toLowerCase(),
          group_id: state.group_id
        };
        if (oldUser.username == newUser.username && oldUser.email == newUser.email && oldUser.group_id == newUser.group_id){
          console.log("dados iguais, não atualizar")
        } else {
         await api.put(`users`, newUser);
        }
      } else{
        //ALTERANDO A SENHA
        const oldUser = {
          username: state.oldUser.username,
          email: state.oldUser.email,
          group_id: state.oldUser.group_id,
          password: state.oldUser.password
        };
        const newUser = {
          id: state.oldUser.id,
          username: username.toLowerCase(),
          email: email.toLowerCase(),
          group_id: state.group_id,
          password: password
        };
        if (oldUser.username == newUser.username && oldUser.email == newUser.email && oldUser.group_id == newUser.group_id && oldUser.password == newUser.password){
          console.log("dados iguais, não atualizar")
        } else {
          await api.put(`users`, newUser);
        }
      }

        
      setState("");
      setOpen(false);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#c38148" }} className="icon-small" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Editar usuário {props.oldUser.username}</DialogTitle>
        {state ? (  
        <DialogContent>    
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (username ? false : true) : false}
                  id="username"
                  name="username"
                  label="Usuário"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (email ? false : true) : false}
                  required
                  id="email"
                  label="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (password ? false : true) : false}
                  required
                  id="password"
                  label="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{display: state.check ? 'block' : 'none'}}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (password2 == password  ? false : true) : false}
                  required
                  id="password2"
                  label="Confirme sua senha"
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  style={{display: state.check ? 'block' : 'none'}}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <WhirpoolSelect
                  error={state.error}
                  label="Grupo"
                  value={state.group_id}
                  name="group_id"
                  data={state.groups.data}
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
              
              <FormControlLabel
        control={
          <Checkbox
            checked={state.check}
            onChange={handleChange}
            name="check"
            color="primary"
          />
        }
        label="Alterar senha"
      /> 
        </div>
        </DialogContent>) : ""}
      
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{backgroundColor: state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff"}}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
