import React from "react";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function ({ journey }) {
  if (journey && journey.markers) {
    return (
      <Paper
        style={{
          marginTop: 50,
          marginBottom: 12,
          maxHeight: "50%",
          overflowY: "scroll",
          borderRadius: 0,
          display: 'block',
          position: 'relative',
          width: "100%",
        }}
      >
        <List>
          <ListItem>
            <ListItemText primary="Coleta" />
          </ListItem>
          <ListItem>
            <List>
              {!journey.markers.origin
                ? null
                : journey.markers.origin.map((item) => (
                    <React.Fragment>
                      <ListItem>
                        <ListItemText
                          primary={item.fullname}
                          secondary={
                            item.distance > 0
                              ? `Percorrido: ${(
                                  item.distance / 1000
                                ).toPrecision(3)}km`
                              : ""
                          }
                        />
                      </ListItem>
                      <List>
                        {item.tasks.map((task) => (
                          <ListItem>
                            <div>
                              <Typography variant="body1">
                                {task.name}
                              </Typography>
                              <Typography variant="body1">
                                {task.packed}x Embalagens de{" "}
                                {task.quantity_pack}
                              </Typography>
                              <Typography variant="body1">
                                Tempo da Tarefa:{" "}
                                {Math.round(task.time_supply_cart / 60)}min
                              </Typography>
                            </div>
                          </ListItem>
                        ))}
                      </List>
                    </React.Fragment>
                  ))}
            </List>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Distribuição" />
          </ListItem>
          <ListItem>
            <List>
              {!journey.markers.destiny
                ? null
                : journey.markers.destiny.map((item) => (
                    <React.Fragment>
                      <ListItem>
                        <ListItemText
                          primary={item.fullname}
                          secondary={`Percorrido: ${(
                            item.distance / 1000
                          ).toPrecision(3)}km`}
                        />
                      </ListItem>
                      {item.tasks.map((task) => (
                        <ListItem>
                          <div>
                            <Typography variant="body1">{task.name}</Typography>
                            <Typography variant="body1">
                              {task.packed}x Embalagens de {task.quantity_pack}
                            </Typography>
                            <Typography variant="body1">
                              Tempo da tarefa:{" "}
                              {Math.round(task.time_supply_line / 60)}min
                            </Typography>
                          </div>
                        </ListItem>
                      ))}
                    </React.Fragment>
                  ))}
            </List>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Retorno" />
          </ListItem>
          <ListItem>
            <List>
              {!journey.markers.end
                ? null
                : journey.markers.end.map((item) => (
                    <React.Fragment>
                      <ListItem>
                        <ListItemText
                          primary={item.fullname}
                          secondary={`Percorrido: ${(
                            item.distance / 1000
                          ).toPrecision(3)}km`}
                        />
                      </ListItem>
                      {item.tasks.map((task) => (
                        <ListItem>
                          <div>
                            <Typography variant="body1">{task.name}</Typography>
                            <Typography variant="body1">
                              {task.packed}x Embalagens de {task.quantity_pack}
                            </Typography>
                            <Typography variant="body1">
                              Tempo da Tarefa:{" "}
                              {Math.round(task.time_supply_return / 60)}min
                            </Typography>
                          </div>
                        </ListItem>
                      ))}
                    </React.Fragment>
                  ))}
            </List>
          </ListItem>
        </List>
      </Paper>
    );
  }

  return null;
}
