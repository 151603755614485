import React from 'react';
import Main from '../../components/Main'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//custom components
import PausesContent from './PausesContent'
import PausesHoursContent from './PausesHoursContent'
import DashboardContent from './DashboardContent'
import TravelContent from './TravelContent'

//@material-ui
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'





function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
  }));



//PAGE
function DashboardPanel(){
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Main color="#f7f7f7" >
  
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
     
        >
          {/* <Tab label="Máquinas"  />
          <Tab label="Distância Percorrida"  />
          <Tab label="Tempo Médio Viagens" />
          <Tab label="Produtos" />
          <Tab label="Operadores" /> */}
          <Tab label="Operação" />
          <Tab label="Pausas - Qtd" />
          <Tab label="Pausas - Horas" /> 
          {/* <Tab label="Viagens" />  */}
        </Tabs>
      </Paper>
    
        <TabPanel value={value} index={0}>
          <DashboardContent />
        </TabPanel> 
        <TabPanel value={value} index={1}>
          <PausesContent />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PausesHoursContent />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <TravelContent />
        </TabPanel> */}
     

        </Main>
    )
}

export default DashboardPanel;