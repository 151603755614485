import React, {useState, useEffect} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import Main from "../../components/Main";
import CreateForm from './CreateForm'
import ListItem from './ListItem'
import EditForm from './EditForm'
import ConfirmItem from './ConfirmItem'
import ConfirmReceivedItem from './ConfirmReceivedItem'

//material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CallReceived from '@material-ui/icons/CallReceived'
import CallMade from '@material-ui/icons/CallMade'
import Tooltip from '@material-ui/core/Tooltip'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'




function ManagementPackaging(){
    const [state, setState] = useState({
      plannings: []
    })
    const [packages, setPackage] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter] = useState("?status=all")
    useEffect(() => {
      getPackages(); 
      getData();   
    }, [filter])

    async function getPackages() {
      try {
        const { data } = await api.get(`packages`);
        setPackage(data.data);
      } catch (error) {
        console.warn(error);
      }
    }

    async function getData() {
      try{
        const { data } = await api.get(`planning`+filter);
        setState((prev) => ({
          ...prev,
          plannings: data.data
        }))
      } catch (error) {
        console.warn(error);
      }
    }
    async function deletePlanning(item) {
      try {
        if (
          window.confirm(
            "Tem certeza que deseja deletar a embalagem de IT " + item.it
          )
        ) {
          await api.delete(`planning/` + item.id);
        } else {
        }
        getData();
        setAnchorEl(null);
      } catch (error) {
        console.warn(error);
      }
    }

    async function confirmSend(item){
      try{
        if (
          window.confirm("Tem certeza que deseja confirmar envio de IT" +item.it)
        ) {
          const obj = {
            status: 'transit'
          }
            await api.put(`plannning-confirm/`+item.id, obj);
            setAnchorEl(null);
            getData();
        } 
        getData();
      } catch (error) {
        console.warn(error);
      }
    }
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    

    const handleChange = (event) => {
      setFilter(event.target.value);
    };

    return (
        <Main color="#f7f7f7" title="Planejamento de Embalagens">
          <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm packages={packages ? packages : []} getData={getData} />
        </Grid>
      </Grid>
      <Grid container justify="space-between"  alignItems="flex-end"  style={{paddingBottom: 12}}>
      <Grid item lg={7}><Typography variant="h5" >Lista de planejamentos</Typography></Grid>
        <Grid item lg={2}>
        <InputLabel>Filtro</InputLabel>
        <Select
          value={filter}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="?status=all">Todos</MenuItem>
          <MenuItem value="?status=waiting">Aguardando</MenuItem>
          <MenuItem value="?status=transit">Trânsito</MenuItem>
          <MenuItem value="?status=received">Entregue</MenuItem>
        </Select>
        
        </Grid>
      </Grid>
            <MaterialTable
    columns={[
      {
        title: "IT",
        field: "it",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Origem",
        field: "origin",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Fornecedor",
        field: "provider_id",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Situação",
        field: "send_receive",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => rowData.send_receive === 1 ? "Enviar" : "Receber"
      },
      {
        title: "Status",
        field: "status",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => rowData.status === 'waiting' ? 'Aguardando' : (rowData.status === 'transit' ? 'Trânsito' : 'Entregue')
      },
      {
        title: "Detalhes",
        field: "situation",
        cellStyle: {padding: 0, paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => (
          <>
           <FormGroup row>
                 <ListItem conference={rowData.conference} receiving_conference={rowData.receiving_conference} it={rowData.it} justification={rowData.justification} package_list={rowData.package_list} package_list_confirmed={rowData.package_list_confirmed} packages={packages}/>
            </FormGroup>
          </>
        ),
      },
      {
        field: "actions",
        title: "Ações",
        cellStyle: { padding: 0, paddingLeft: 40, border: "#fff" },
        render: (rowData) => (
          <>
           <FormGroup row>
      
        <IconButton onClick={handleClick}><MoreHorizIcon /></IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
          keepMounted
        >
          <ConfirmItem justification={rowData.justification} status={rowData.status} getData={getData} id={rowData.id} it={rowData.it} send_receive={rowData.send_receive} package_list={rowData.package_list} packages={packages}  disabled={rowData.status === 'waiting' ? false : true }>Confirmar envio</ConfirmItem>
          <ConfirmReceivedItem justification={rowData.justification} status={rowData.status} getData={getData} id={rowData.id} it={rowData.it} send_receive={rowData.send_receive} package_list={rowData.package_list} packages={packages} style={{ color: rowData.status === 'waiting' ? "#03cc37" : ""  }}  disabled={rowData.status === 'transit' ? false : true }>Confirmar recebimento</ConfirmReceivedItem>
          <EditForm id={rowData.id} it={rowData.it} origin={rowData.origin} provider_id={rowData.provider_id} send_receive={rowData.send_receive} package_list={rowData.package_list} packages={packages} getData={getData}>Editar planejamento</EditForm>
          <MenuItem   onClick={() => deletePlanning(rowData)}>Deletar planejamento</MenuItem>
        </Menu> 
           </FormGroup>
          </>
        ),
      },
    ]}
    data={state.plannings}        
    options={{
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        padding: 10,
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: (props) => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    style={{
      borderRadius: 5,
    }}
  />
        </Main>
    )
}

export default ManagementPackaging;