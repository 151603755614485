import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from "reactstrap";
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import moment from "moment";
import GraphicCard from "../../components/Dashboard/GraphicCard";
import ItemsCard from "../../components/Dashboard/ItemsCard";
import BeaconCard from "../../components/Dashboard/BeaconCard";
import GraphCard1 from "../../components/Dashboard/GraphCard1";
import css from "../../css/dashboard/dashboard_view.module.css";
import playlist from "../../assets/icons/ic_playlist_add_check_48px.svg";
import time from "../../assets/icons/ic_access_time_48px.svg";

function DashboardSanofi(){
    const [currentDate, setCurrentdate] = useState(moment().format("YYYY-MM-DD"));
    const [state, setState] = useState({
      fullCycleStartDate: currentDate,
      fullCycleEndDate: currentDate,
      averageLoadTimeStartDate: currentDate,
      averageLoadTimeEndDate: currentDate,
      averageUnloadTimeStartDate: currentDate,
      averageUnloadTimeEndDate: currentDate,
      averageCycleTimeStartDate: currentDate,
      averageCycleTimeEndDate: currentDate,
      weightLoadedStartDate: currentDate,
      weightLoadedEndDate: currentDate,
      vehiclesStartDate: currentDate,
      vehiclesEndDate: currentDate,
      fullCycleError: false,
      averageLoadTimeError: false,
      averageUnloadError: false,
      averageCycleTimeError: false,
      weightLoadedError: false,
      vehiclesError: false,
      conferidosDia: "",
      pendentesDia: ""
    });
    const [graph0, setGraph0] = useState({
        xAxis: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
        yAxis: { labels: ["0", "1k", "2k", "4k", "8k"], min: 0, max: 8 },
        bars: [
          [1.9, 3.6],
          [1.4, 1.8],
          [1.5, 1.7],
          [1.5, 0.5],
          [1.5, 1.6],
          [2.2, 4.2],
          [1.9, 3]
        ],
        colors: ["#A4A1FB", "#56D9FE"],
        legends: ["Entradas", "Saídas"]
      });

    const [graph1, setGraph1] = useState({
        xAxis: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
        yAxis: { labels: ["0", "1k", "2k", "4k", "8k"], min: 0, max: 8 },
        bars: [
          [1.9, 3],
          [1.4, 1.75],
          [1.3, 2.6],
          [1.4, 0.5],
          [1.3, 1.6],
          [2.3, 4.2],
          [4, 8]
        ],
        colors: ["#A4A1FB", "#56D9FE"],
        legends: ["Entradas", "Saídas"]
      });

    const _listDashboard = () => {
        const dashboard = {
          fullCycleStartDate: state.fullCycleStartDate,
          fullCycleEndDate: state.fullCycleEndDate,
          averageLoadTimeStartDate: state.averageLoadTimeStartDate,
          averageLoadTimeEndDate: state.averageLoadTimeEndDate,
          averageUnloadTimeStartDate: state.averageUnloadTimeStartDate,
          averageUnloadTimeEndDate: state.averageUnloadTimeEndDate,
          averageCycleTimeStartDate: state.averageCycleTimeStartDate,
          averageCycleTimeEndDate: state.averageCycleTimeEndDate,
          weightLoadedStartDate: state.weightLoadedStartDate,
          weightLoadedEndDate: state.weightLoadedEndDate,
          vehiclesStartDate: state.vehiclesStartDate,
          vehiclesEndDate: state.vehiclesEndDate
        };
      };
  
    useEffect(() => {
    getConferidos();
    getPendentes();   
    }, [])
    
    async function getConferidos() {
        try {
          const conferidos = await api.get(`/conferidos`);
          // const orders = await api.get(`/orders-analytics`);
            setState(
            { ...state, conferidosDia: conferidos.data.conferidosDia },
            () => console.log(state)
          );
        } catch (err) {
          console.warn(err);
        }
      }
    
      async function getPendentes() {
        try {
          const pendentes = await api.get(`/pendentes-dia`);
          // const orders = await api.get(`/orders-analytics`);
        setState(
            { ...state, pendentesDia: pendentes.data.pendentesDia },
            () => console.log(state)
          );
        } catch (err) {
          console.warn(err);
        }
      }

    
  const _handleChangeCard = async (e, card) => {
    switch (card) {
      case "complete_cycles":
        if (e.target.name === "startDate") {
          await setState({
            fullCycleStartDate: e.target.value
          });
        } else if (e.target.name === "endDate") {
          await setState({
            fullCycleEndDate: e.target.value
          });
        }

        break;
      case "average_time_load":
        if (e.target.name === "startDate") {
          await setState({
            averageLoadTimeStartDate: e.target.value
          });
        } else if (e.target.name === "endDate") {
          await setState({
            averageLoadTimeEndDate: e.target.value
          });
        }
        break;
      case "average_time_cycles":
        if (e.target.name === "startDate") {
          await setState({
            averageCycleTimeStartDate: e.target.value
          });
        } else if (e.target.name == "endDate") {
          await setState({
            averageCycleTimeEndDate: e.target.value
          });
        }
        break;
      case "average_time_unload":
        if (e.target.name === "startDate") {
          await setState({
            averageUnloadTimeStartDate: e.target.value
          });
        } else if (e.target.name === "endDate") {
          await setState({
            averageUnloadTimeEndDate: e.target.value
          });
        }
        break;
      case "weight_loaded":
        if (e.target.name === "startDate") {
          await setState({
            weightLoadedStartDate: e.target.value
          });
        } else if (e.target.name === "endDate") {
          await setState({
            weightLoadedEndDate: e.target.value
          });
        }
        break;
      case "vehicles":
        if (e.target.name === "startDate") {
          await setState({
            vehiclesStartDate: e.target.value
          });
        } else if (e.target.name === "endDate") {
          await setState({
            vehiclesEndDate: e.target.value
          });
        }
        break;
      default:
        return "";
    }

    await setState({
      ...state,
      fullCycleError: false,
      averageLoadTimeError: false,
      averageUnloadError: false,
      averageCycleTimeError: false,
      weightLoadedError: false,
      vehiclesError: false
    });

    var isValid = true;

    if (
      moment(state.fullCycleStartDate) >
      moment(state.fullCycleEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        fullCycleError: true
      });
    }

    if (
      moment(state.averageLoadTimeStartDate) >
      moment(state.averageLoadTimeEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        averageLoadTimeError: true
      });
    }

    if (
      moment(state.averageUnloadTimeStartDate) >
      moment(state.averageUnloadTimeEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        averageUnloadError: true
      });
    }

    if (
      moment(state.averageCycleTimeStartDate) >
      moment(state.averageCycleTimeEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        averageCycleTimeError: true
      });
    }

    if (
      moment(state.weightLoadedStartDate) >
      moment(state.weightLoadedEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        weightLoadedError: true
      });
    }

    if (
      moment(state.vehiclesStartDate) > moment(state.vehiclesEndDate)
    ) {
      isValid = false;
      await setState({
        ...state,
        vehiclesError: true
      });
    }

    if (isValid) {
      // consultar dashboard
      var dashboard = {
        fullCycleStartDate: state.fullCycleStartDate,
        fullCycleEndDate: state.fullCycleEndDate,
        averageLoadTimeStartDate: state.averageLoadTimeStartDate,
        averageLoadTimeEndDate: state.averageLoadTimeEndDate,
        averageUnloadTimeStartDate: state.averageUnloadTimeStartDate,
        averageUnloadTimeEndDate: state.averageUnloadTimeEndDate,
        averageCycleTimeStartDate: state.averageCycleTimeStartDate,
        averageCycleTimeEndDate: state.averageCycleTimeEndDate,
        weightLoadedStartDate: state.weightLoadedStartDate,
        weightLoadedEndDate: state.weightLoadedEndDate,
        vehiclesStartDate: state.vehiclesStartDate,
        vehiclesEndDate: state.vehiclesEndDate
      };
    }
  };


  const dropDownData = new Array(12).fill(<a>Tempo real</a>);
  const dropDownData1 = new Array(10).fill(<a>Europe</a>);
  const lineData = [
    { text: "Conferência Interna", number: "" },
    { text: "Conferência do Produto", number: state.conferidosDia }
  ];
  const lineData1 = [
    { color: "#5ED237", number: "" },
    { color: "#FF8800", number: "" },
    { color: "#E32C51", number: "" }
  ];



    return (
        <Main>
        
              <Container fluid={true}>
                <Row>
                  <Col lg="6">
                    <GraphicCard
                      className={css.card0}
                      color0="#12B0B5"
                      color1="#0FC6CC"
                      icon={playlist}
                      info0={state.conferidosDia}
                      info1={<div>Volumes Conferidos no dia</div>}
                    />
                  </Col>
                  <Col lg="6">
                    <GraphicCard
                      className={css.card1}
                      color0="#964ABB"
                      color1="#B65DE2"
                      icon={time}
                      info0={state.pendentesDia}
                      info1={<div>Volumes Pendentes Totais</div>}
                      iconCSS={{ height: "42px" }}
                    />
                  </Col>

                  <Col lg="6">
                    <ItemsCard
                      className={css.itemsCard}
                      title="Itens por processo"
                      dropDownData={dropDownData}
                      lineData={lineData}
                    />
                  </Col>

                  <Col lg="6">
                    <BeaconCard
                      className={css.beaconCard}
                      title="Farol"
                      lineData={lineData1}
                    />
                  </Col>

                  <Col lg="12">
                    <GraphCard1
                      className={css.graphCard1}
                      title="Entradas e Saídas"
                      graph={graph0}
                      dropDownData={dropDownData1}
                    />
                  </Col>
                </Row>
              </Container>
        
      </Main>
    )
}

export default DashboardSanofi