import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { MenuContainer, Icon, MenuItem } from "./styles";
import trackage from "../../assets/favicon.png";
import dashboard from "../../assets/icons/ic_tv_-1.svg";
import map from "../../assets/icons/map.svg";
import forklift from "../../assets/icons/forklift.svg";
import report from "../../assets/icons/assignment-black-18dp.svg";
import heat from "../../assets/icons/speed.svg";
import orders from "../../assets/icons/ic_playlist_add_check_48px.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import ic_settings_24px from "../../assets/icons/ic_settings_24px.svg";
import arrows from "../../assets/icons/arrows.svg";
import event from "../../assets/icons/event-24px.svg";
import box from '../../assets/icons/box.svg';
// import { logout } from '../../config/auth';



export class Menu extends Component {
  logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("usuario");
    console.log(localStorage.getItem("usuario"));
    this.props.history.push("/login");
  }
  render() {
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const { pathname } = this.props.location;
    
    return (
      <MenuContainer>
        <img
          src={trackage}
          style={{ marginBottom: 30 }}
          alt="logo trackage"
          width="24"
          height="24"
        />
      
       
          {usuario?.menu.map((menu) => {
            return ( <>
                <MenuItem>
                <Link to={"/"+menu.resource.route}>
                  <Icon src={menu.resource.icon} alt={menu.name} rota={"/"+menu.resource.route} rotaAtual={pathname} width="19.69"  height="16" />
                  </Link>
                </MenuItem>
            </>)
          }) }

<MenuItem onClick={() => this.logout()}>
          <Icon
            src={logoutIcon}
            rota="/sair"
            rotaAtual={pathname}
            alt="Sair"
            width="19.69"
            height="16"
          />
        </MenuItem>
      </MenuContainer>
    );
  }
}
export default withRouter(Menu);
