import React, {useState, useEffect} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import Main from "../../components/Main";
import CreateForm from './CreateForm'
import EditForm from './EditForm'

//material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'




function Packaging(){
  const [state, setState] = useState("");
  useEffect(() => {
    getData();    
  }, [])

  async function getData() {
    try {
      const { data } = await api.get(`packages`);
      setState((prev) => ({
        ...prev,
        packages: data.data,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

 async function deletePackage(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o pacote " + item.name
        )
      ) {
        await api.delete(`packages/` + item.id);
      } else {
      }
      getData();
    } catch (error) {
      console.warn(error);
    }
  }

    return (
        <Main color="#f7f7f7" title="Embalagens">
          <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm getData={getData}  />
        </Grid>
      </Grid>
            <MaterialTable
    columns={[
      {
        title: "Nome",
        field: "name",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        field: "actions",
        title: "Ações",
        cellStyle: { padding: 0, textAlign: "left", border: "#fff" },
        render: (rowData) => (
          <>
          <FormGroup row>
            <EditForm
              name={rowData.name}
              id={rowData.id}
              //datas
              getData={getData}
              
            />
            <IconButton
              color="secondary"
              onClick={() => deletePackage(rowData)}
            >
              <DeleteIcon />
            </IconButton>{" "}
          </FormGroup>
        </>
        ),
      },
    ]}
    data={state.packages}        
    options={{
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        padding: 10,
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: (props) => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    style={{
      borderRadius: 5,
    }}
  />
        </Main>
    )
}

export default Packaging;