import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import green from '@material-ui/core/colors/green';
import ModuleList from '../../../ModulesList'
import Select from '@material-ui/core/Select';
import WhirpoolSelect from "../../../../components/WhirpoolSelect";


function CreateForm(props) {

  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    id_machine: [],
    cod_client: [],
    cod_client_list: [],
    running_time: [],
    amount_of_tape: [],
    used_tape: [],
    tape_width: [],
    machine_type: [],
    machineList: [],
    /* machine: [], */
    module_id: [],
    module_list: [],
    typeList: [],
    tapeList: [],
  })

  const [id_machine, setIdMachine] = useState("");
  //const [cod_client, setCodClient] = useState("");
  const [running_time, setRunningTime] = useState("");
  const [amount_of_tape, setAmountOfTape] = useState("");
  const [machine_type, setMachineType] = useState("");
  const [used_tape, setUsedTape] = useState("");
  const [tape_width, setTapeWidth] = useState("");
  /* const [machine, setMachine] = useState(""); */
  //const [module_id, setModuleId] = useState("");
  const [open, setOpen] = useState(false);

 

  const handleClickOpen = () => {
    setState({
      error: false,
      disabled: false,
      id_machine: "",
      cod_client: "",
      cod_client_list: state.clients,
      running_time: "",
      amount_of_tape: "",
      used_tape: "",
      tape_width: "",
      /* machine: "", */
      module_id: "",
      module_list: state.modulo,
      tapeList: [{ id: '38', name: '38' }, { id: '45', name: '45' }, { id: '48', name: '48' }, { id: '50', name: '50' }, { id: '72', name: '72' }],
      machineList: [{ id: '700', name: 'Mod. 700A' }, { id: '800', name: 'Mod. 800A' }]

    })
    setIdMachine("");
    //setCodClient("");
    setRunningTime("");
    setAmountOfTape("");
    setUsedTape("");
    setTapeWidth("");
    /* setMachine(""); */
    //setModuleId("");
    setOpen(true);
  };

  useEffect(() => {
      //getData();
      getModulos();
      getClients();
      //getTelemetries(); 
      
  console.log('start')
  }, [])

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    let cod_client = state.cod_client_list.filter((item) => {return item.id === state.cod_client})
   
    const machines = {
      resource: {
        id_machine: id_machine,        
        cod_client: cod_client[0].resource.cod_client,
        running_time: running_time,
        amount_of_tape: amount_of_tape,
        used_tape: used_tape,
        tape_width: state.tape_width,
        machine_type: state.machine_type,        
        module_id: state.module_id,
      },
      active: true,
    }
    try {
     await api.post(`machines`, machines);
     console.log('salvando')
     console.log('Teu objeto que tu ta salvando: ', machines);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    setOpen(false)
  }


 
  async function getClients() {
    try {
      const { data } = await api.get(`clientes/free`);
      console.log('data')
      console.log(data)
      setState((prev) => ({
        ...prev,
        clients: data || []
      }))
    } catch (error) {
      console.warn(error);
      
    }
  }

  async function getModulos() {
    try {
      const { data } = await api.get(`modulo/free`);
      setState((prev) => ({
        ...prev,
        modulos: data || []
      }))
    } catch (error) {
      console.warn(error);
    }
  }
console.log('clients')
console.log(state.clients)
console.log('modulos')
console.log(state.modulos)
console.log('form')
console.log(handleSubmit)

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm"  >
        <DialogTitle>Cadastar nova Máquina</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="id_machine"
              label="ID Máquina"
              type="text"
              value={id_machine}
              onChange={(e) => setIdMachine(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Modelo da máquina"
              value={state.machine_type}
              name="machine_type"
              data={state.machineList}
              onChange={(event) => handleChange(event)}
            />
            {/*  {type.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField> */}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="amount_of_tape"
              label="Quantidade Fita Estipulada (m²)"
              type="number"
              value={amount_of_tape}
              onChange={(e) => setAmountOfTape(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Largura da Fita em (mm)"
              value={state.tape_width}
              name="tape_width"
              data={state.tapeList}
              onChange={(event) => handleChange(event)}
            />
            {/* {tape.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField> */}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="ID do Modulo"
              value={state.module_id}
              name="module_id"
              data={state.module_list}
              onChange={(event) => handleChange(event)}
            />
            {/* {states.modulos.map((option) => (
                <option key={option.module_id} value={option.module_id}>
                  {option.module_id}
                </option>
              ))} */}
            {/* </TextField> */}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Cod. Cliente"
              value={state.cod_client}
              name="cod_client"
              data={state.cod_client_list}
              onChange={(event) => handleChange(event)}
            />
            {/* {stado.clients.map((option) => (
                <option key={option.resource.cod_client} value={option.resource.cod_client}>
                  {option.resource.cod_client}
                </option>
              ))} */}
            {/* </TextField> */}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
