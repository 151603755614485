import React from "react";
import Main from "../../components/Main";

//custom components
import TableFenches from "./TableFenches";

function FencheList() {
  return (
    <Main color="#eeeeee" title="Cercas">
      <TableFenches />
    </Main>
  );
}

export default FencheList;
