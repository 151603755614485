import React from "react";
import Main from "../../components/Main";

//custom components
import TableModules from "./TableModules";

function ModuleList() {
  return (
    <Main color="#eeeeee" title="Modulos">
      <TableModules />
    </Main>
  );
}

export default ModuleList;
