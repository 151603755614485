import React, { useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../core/services/api";
import Main from '../../components/Main'
import moment from 'moment'

//custom components


//@material-ui
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getDefaultNormalizer } from "@testing-library/react";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";

function ManagementBeacons() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    beacons: [
      //{forklift_number: 3004	, macaddress: 'AC233F61D2E9'	, last_view: '2021-10-11 01:12:55.159533'}
    ],
  });

  useEffect(() => {
    getData();
  }, []);



  async function getData() {
    try {
      const { data } = await api.get(`dashboard-beacons`);
      setState((prev) => ({
        ...prev,
        beacons: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }


  return (
    <Main color="#eeeeee" title="Beacons">
      <MaterialTable
        columns={[
          {
            title: "Número Máquina",
            field: "forklift_number",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },

          {
            title: "Macaddress",
            field: "macaddress",
            cellStyle: { padding: 10, textAlign: "left", border: "#fff" },
          },
          {
            title: "Última Atualização",
            field: "last_view",
            cellStyle: { padding: 10, textAlign: "left", border: "#fff" },
            render: (rowData) => moment(rowData.last_view, "YYYY-MM-DD HH:mm:ss.SSS").subtract(3, 'hour').format("HH:mm:ss DD/MM/YYYY")
          },
        ]}
        data={state.beacons}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            padding: 10,
            textAlign: "left",
            backgroundColor: "#e8ebef",
            border: "#fff",
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />
      </Main>
  );
}

export default ManagementBeacons;
