import React from "react";

import CallMade from "@material-ui/icons/CallMade";
import CallReceived from "@material-ui/icons/CallReceived";

import { Title, Value, Prev, PrevValue, PrevLabel } from "./styles";

export default ({ title, value, last_value }) => {
  console.log(last_value);
  return (
    <div
      style={{
        padding: "0 25px",
        margin: "10px 0 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Title>{title}</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Value>{value}</Value>
        <Prev>
          <PrevValue
            color={
              last_value && last_value.result
                ? last_value && last_value.direction == "up"
                  ?  "#5ED237"
                  :"#EC273B"
                : "#909090"
            }
          >
            {last_value && last_value.result ? (
              last_value && last_value.direction == "up" ? (
                <CallMade />
              ) : (
                <CallReceived />
              )
            ) : (
              ""
            )}
            {last_value && last_value.result
              ? last_value.result.toFixed(1)
              : 0.0}
            %
          </PrevValue>
          <PrevLabel>em relação ao periodo anterior</PrevLabel>
        </Prev>
      </div>
    </div>
  );
};
