import React, { Component } from 'react';
import css from '../../css/dashboard/graphic_card.module.css';

class GraphicCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color0: props.color0 || '#12B0B5',
      color1: props.color1 || '#0FC6CC',
      icon: props.icon,
      info0: props.info0 || '',
      info1: props.info1 || '',
      iconCSS: props.iconCSS || {}
    };
  }

  render() {
    return (
      <div {...this.props}>
        <div className={css.container}>
          <div
            className={css.iconArea}
            style={{ backgroundColor: this.state.color0 }}
          >
            <img
              src={this.state.icon}
              style={this.state.iconCSS}
              alt='ícone'
            ></img>
          </div>
          <div
            className={css.infoArea}
            style={{ backgroundColor: this.state.color1 }}
          >
            <div>
              <div className={css.number}>{this.props.info0}</div>
              <div className={css.info}>{this.state.info1}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraphicCard;
