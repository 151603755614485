export default function (legends) {
  return {
    colors: ["#26a0fc", "#24dff5"],
    chart: {
      height: 350,
      type: "bar",
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      offsetY: 10,
      style: {
        fontSize: "12px",
        colors: ["#2e2e2e"],
      },
    },
    xaxis: {
      categories: legends,
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
  };
}
