import styled from "styled-components";

export const Title = styled.p`
  color: #7c8796;
  font-size: 16px;
  text-align: center;
  margin: 0;
`;

export const Value = styled.h1`
  color: #1d2329;
  font-size: 80px;
  font-weight: bolder;
`;

export const Prev = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PrevValue = styled.span`
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const PrevLabel = styled.span`
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  color: #7c8796;
`;
