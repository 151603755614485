import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import green from '@material-ui/core/colors/green';
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Cep from "react-simple-cep-mask";

//custom components
import SDSelect from "../../../../components/SDSelect";

function CepMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
CepMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, ' - ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}


PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function CnpjMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
CnpjMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const currencies = [
  {
    value: 'Norte',
    label: 'Norte',
  },
  {
    value: 'Nordeste',
    label: 'Nordeste',
  },
  {
    value: 'Centro-Oeste',
    label: 'Centro-Oeste',
  },
  {
    value: 'Sul',
    label: 'Sul',
  },
  {
    value: 'Sudeste',
    label: 'Sudeste',
  },
];

const states = [
  {
    value: 'AC',
    label: 'AC'
  },
  {
    value: 'AL',
    label: 'AL'
  },
  {
    value: 'AP',
    label: 'AP'
  },
  {
    value: 'AM',
    label: 'AM'
  },
  {
    value: 'BA',
    label: 'BA'
  },
  {
    value: 'CE',
    label: 'CE'
  },
  {
    value: 'DF',
    label: 'DF'
  },
  {
    value: 'ES',
    label: 'ES'
  },
  {
    value: 'GO',
    label: 'GO'
  },
  {
    value: 'MA',
    label: 'MA'
  },
  {
    value: 'MT',
    label: 'MT'
  },
  {
    value: 'MS',
    label: 'MS'
  },
  {
    value: 'MG',
    label: 'MG'
  },
  {
    value: 'PA',
    label: 'PA'
  },
  {
    value: 'PB',
    label: 'PB'
  },
  {
    value: 'PR',
    label: 'PR'
  },
  {
    value: 'PE',
    label: 'PE'
  },
  {
    value: 'PI',
    label: 'PI'
  },
  {
    value: 'RJ',
    label: 'RJ'
  },
  {
    value: 'RN',
    label: 'RN'
  },
  {
    value: 'RS',
    label: 'RS'
  },
  {
    value: 'RO',
    label: 'RO'
  },
  {
    value: 'RR',
    label: 'RR'
  },
  {
    value: 'SC',
    label: 'SC'
  },
  {
    value: 'SP',
    label: 'SP'
  },
  {
    value: 'SE',
    label: 'SE'
  },
  {
    value: 'TO',
    label: 'TO'
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}));


function CreateForm(props) {

  const classes = useStyles();


  const color = green[500]

  const [state, setState] = useState({
    uf: 0,
    active: true,
    error: false,
    disabled: false,
    name: [],
    cod_client: [],
    region: [],
    phone: [],
    celphone: [],
    cnpj: [],
    cep: [],
    address: [],
    person_in_charge: [],
    address_n: [],
    complement: [],
    city: [],
    email: [],
  });

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [celphone, setCelPhone] = useState("");
  const [region, setRegion] = useState("");
  const [estate, setEstate] = useState("");
  const [person_in_charge, setPersonInCharge] = useState("");
  const [cod_client, setCod_Client] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [address_n, setAddressN] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getUfs();
  }, []);

  const handleClickOpen = () => {
    setState({
      uf: 0,
      error: false,
      disabled: false,
      name: '',
      phone: '',
      celphone: '',
      region: region,
      estate: estate,
      response_name: '',
      cod_cliente: '',
      cnpj: '',
      cep: '',
      address: '',
      address_n: '',
      complement: '',
      city: '',
      email: ''
    })
    setName("");
    setPhone("");
    setCelPhone("");
    setRegion("");
    setUf("");
    setEstate("");
    setPersonInCharge("");
    setCod_Client("");
    setCnpj("");
    setCep("");
    setAddress("");
    setAddressN("");
    setComplement("");
    setCity("");
    setUf("");
    setEmail("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


    async function getUfs() {
      try {
        const data = await api.get(`support_data?name='uf_select'`);
        setState((prev) => ({
          ...prev,
          uf_list: data.data.rows[0].resource || [],
        }));
      } catch (error) {
        console.warn(error);
      }
    }
  async function searchCep(cep) {
    var thenum = cep.replace(" ", "").replace("-", "");

    setCep(cep);
    if (thenum.length === 8) {
      try {
        const res = await axios.get(`https://viacep.com.br/ws/` + thenum + `/json/`)

        setAddress(res.data.logradouro);
        setAddressN("");
        setComplement(res.data.complemento);
        setCity(res.data.localidade);
        const uf = state.uf_list.filter((item) => { return item.uf == res.data.uf })

        setState((prev) => ({ ...prev, uf_id: uf[0].id }))
        setUf(res.data.uf)
        console.log('UF')
        console.log(uf)
      } catch (error) {
        console.warn(error)
      }
    }
  }

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    postClient();
    setOpen(false)
  }
  async function postClient() {
    try {
      const client = {
        resource: {
          name: name,
          phone: phone,
          celphone: celphone,
          region: region,
          estate: estate,
          person_in_charge: person_in_charge,
          cod_client: cod_client,
          cnpj: cnpj,
          cep: cep,
          address: address,
          address_n: address_n,
          complement: complement,
          uf: uf,
          city: city,
          email: email,
        },
        client_id: state.id || "",
        active: true,
      }
      console.log('criando cliente: ', client)
      await api.post(`clients`, client);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm"  >
        <DialogTitle> Cadastar novo Cliente </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 12, marginTop: 12 }}>
            <TextField
              id="nome"
              name="Nome"
              label="Nome"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="cod_cliente"
              label="Cód. Cliente"
              value={cod_client}
              type="text"
              onChange={(e) => setCod_Client(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">CNPJ</InputLabel>
              <Input
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                inputComponent={CnpjMask}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">Telefone</InputLabel>
              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                inputComponent={PhoneMask}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel htmlFor="formatted-text-mask-input">Celular</InputLabel>
              <Input
                value={celphone}
                onChange={(e) => setCelPhone(e.target.value)}
                inputComponent={PhoneMask}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl>
              <InputLabel htmlFor="formatted-text-mask-input">CEP</InputLabel>
              <Input
                value={cep}
                required
                id="cep"
                label="CEP"
                type="text"
                onChange={(e) => searchCep(e.target.value)}
                placeholder="Digite seu cep"
                inputComponent={CepMask}
                name="textmask"
                id="formatted-text-mask-input"
                fullWidth
              />
            </FormControl>
            {/* <TextField
                    // error={state.error ? (macaddress ? false : true) : false}
                    required
                    id="cep"
                    label="CEP"
                    type="text"
                    value={cep}
                    onChange={(e) => searchCep(e.target.value)}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />{" "} */}

          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              // error={state.error ? (macaddress ? false : true) : false}
              required
              id="end"
              label="Endereço"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="numero"
              label="N°"
              type="text"
              value={address_n}
              onChange={(e) => setAddressN(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="complemento"
              label="Complemento"
              type="text"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="city"
              label="Cidade"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              select
              id="regiao"
              label="Região"
              type="text"
              helperText="Selecione a Região"
              value={uf}
              onChange={(e) => setUf(e.target.value)}
              fullWidth
            >
              {currencies.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              select
              id="state"
              label="Estado"
              type="text"
              helperText="Selecione o Estado"
              value={estate}
              onChange={(e) => setEstate(e.target.value)}
              fullWidth
            >
              {states.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField

              id="responsavel"
              label="Nome Responsável"
              type="text"
              value={person_in_charge}
              onChange={(e) => setPersonInCharge(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <TextField
              id="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />{" "}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
