import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomSelect from '../../../../components/CustomSelect'
import WhirpoolSelect from '../../../../components/WhirpoolSelect';


function EditForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    id_machine: [],
    cod_client: [],
    cod_client_list: [],
    running_time: [],
    amount_of_tape: [],
    used_tape: [],
    tape_width: [],
    machine_type: [],
    machineList: [],
    module_id: [],
    module_list: [],
    typeList: [],
    tapeList: [],
    
  });

  const [id_machine, setIdMachine] = useState("");
  const [cod_client, setCodClient] = useState("");
  const [running_time, setRunningTime] = useState("");
  const [amount_of_tape, setAmountOfTape] = useState("");
  const [machine_type, setMachineType] = useState("");
  const [used_tape, setUsedTape] = useState("");
  const [tape_width, setTapeWidth] = useState("");
  /* const [machine, setMachine] = useState(""); */
  const [module_id, setModuleId] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      id_machine: props.id_machine,
      cod_client: props.cod_client,
      cod_client_list: props.clients,
      running_time: "",
      amount_of_tape: "",
      used_tape: "",
      tape_width: props.tape_width,
      module_id: props.module_id,
      module_list: props.modulos,
      tapeList: [{ id: '38', name: '38' }, { id: '45', name: '45' }, { id: '48', name: '48' }, { id: '50', name: '50' }, { id: '72', name: '72' }],
      machineList: [{ id: '700', name: 'Mod. 700A' }, { id: '800', name: 'Mod. 800A' }]
    })
    setIdMachine(props.machines.resource.id_machine);
    setCodClient(props.machines.resource.cod_client);
    //setClient(props.machines.resource.client);
    setRunningTime(props.machines.resource.running_time);
    setAmountOfTape(props.machines.resource.amount_of_tape);
    setUsedTape(props.machines.resource.used_tape);
    setTapeWidth(props.machines.resource.tape_width);
    /* setMachine(props.machines.resource.machine); */
    setModuleId(props.machines.resource.module_id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIdMachine("");
    setCodClient("");
    setRunningTime("");
    setAmountOfTape("");
    setUsedTape("");
    setTapeWidth("");
    //setMachine(""); 
    setModuleId("");
  };


  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    try {
      const machine = {
        resource: {
          id_machine: props.id_machine,
          cod_client: props.cod_client,
          running_time: props.running_time,
          amount_of_tape: props.amount_of_tape,
          used_tape: props.used_tape,
          tape_width: props.tape_width,
          machine_type: props.machine_type,
         /*  machine: machine ,*/
          module_id: props.module_id,
        },
        active: props.active
      }
      await api.put(`machines/${props.machines.id}`, machine);
      props.getData();

    } catch (error) {
      console.warn(error)
    }
    console.log('Maquina editada com sucesso !'.machines)
    handleClose()
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Editar Máquinas  </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 12 }}>
            <TextField
              id="id_machine"
              label="ID Máquina"
              type="text"
              value={id_machine}
              onChange={(e) => setIdMachine(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Modelo da máquina"
              value={state.machine_type}
              id="machine_type"
              name="machine_type"
              data={state.machineList}
              onChange={(event) => handleChange(event)}
            />
            {/*  {type.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField> */}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="amount_of_tape"
              label="Quantidade Fita Estipulada"
              type="number"
              value={amount_of_tape}
              onChange={(e) => setAmountOfTape(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Largura da Fita em (mm)"
              id="tape_width"
              value={state.tape_width}
              name="tape_width"
              data={state.tapeList}
              onChange={(event) => handleChange(event)}
            />
            {/* {tape.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField> */}
          </div>

        {/*   <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              select
              id="tape_width"
              label="Largura da Fita"
              type="number"
              helperText="Selecione a Largura da fita"
              value={tape_width}
              onChange={(e) => setTapeWidth(e.target.value)}
              fullWidth
            >
            {tape.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
             </TextField>
          </div> */}
           <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="ID do Modulo"
              value={state.module_id}
              name="module_id"
              data={state.module_list}
              onChange={(event) => handleChange(event)}
            />
            {/* {states.modulos.map((option) => (
                <option key={option.module_id} value={option.module_id}>
                  {option.module_id}
                </option>
              ))} */}
            {/* </TextField> */}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <WhirpoolSelect
              label="Cod. Cliente"
              value={state.cod_client}
              name="cod_client"
              data={state.cod_client_list}
              onChange={(event) => handleChange(event)}
            />
            {/* {stado.clients.map((option) => (
                <option key={option.resource.cod_client} value={option.resource.cod_client}>
                  {option.resource.cod_client}
                </option>
              ))} */}
            {/* </TextField> */}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
