import React, { useState, useEffect } from "react";

import { debounce } from "lodash";
import { easeCubic } from "d3-ease";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Location from "@material-ui/icons/LocationOn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Mapbox Lib
import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, {
  Marker,
  FlyToInterpolator,
  Popup,
  NavigationControl,
} from "react-map-gl";

// Api
import api from "../../../../core/services/api";

// Custom Components
import Main from "../../components/Main";
import Sidebar from "./Sidebar";
import { MapContainer } from "./styles";
import PopupItem from "./PopupItem";

// Utils
import { countForklifts, setColor, randomCoordinates } from "./Utils";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#908bff",
    "&$checked": {
      color: "#908bff",
    },
    "&$checked + $track": {
      backgroundColor: "#908bff",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function () {
  const token =
    "pk.eyJ1IjoibHlwZWt1biIsImEiOiJja29hY2NwcWUwMDdrMm9vZXk1a2RtbnRkIn0.TpprfiUZGKn7gqjK8Wy3Fg";
  const [state, setState] = useState({
    is_open: false,
    forklifts: [],
    filteredForklifts: [],
    forklifts_filter: [],
    operations: [],
    filter: "",
    popupInfo: null,
    machine_status: null,
    operation_status: null,
    forklift_status: null,
    satelite: true,
    status: {
      total: 0,
      inactives: 0,
      actives: 0,
      clamps: 0,
      forks: 0,
    },
  });

  const navigateTo = (latitude, longitude) => {
    const viewport = {
      ...state.viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: 18,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    };
    setState((prev) => ({ ...prev, viewport }));
  };

  async function getCarros() {
    try {
      const resp = await api.get(`scanners`);
      const counter = countForklifts(resp.data);

      const carros = resp.data.map((forklift) => {
        const latitude = forklift.latitude;
        const longitude = forklift.longitude;
        const coordinates = randomCoordinates({ latitude, longitude }, 10);
        forklift.latitude = coordinates.latitude;
        forklift.longitude = coordinates.longitude;
        return forklift;
      });
      setState((prev) => ({
        ...prev,
        status: counter,
        forklifts: carros,
        forklifts_filter: carros,
      }));
      localStorage.setItem("oldstate", JSON.stringify(carros));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getCarros2() {
    try {
      const resp = await api.get(`scanners`);
      const counter = countForklifts(resp.data);
      const oldstate = JSON.parse(localStorage.getItem("oldstate"));

      const carros = resp.data.map((forklift) => {
        if (forklift.active) {
          const latitude = forklift.latitude;
          const longitude = forklift.longitude;
          const coordinates = randomCoordinates({ latitude, longitude }, 10);
          forklift.latitude = coordinates.latitude;
          forklift.longitude = coordinates.longitude;
          return forklift;
        } else {
          const oldForklift = oldstate.filter((item) => {
            return item.name === forklift.name;
          });
          forklift.latitude = oldForklift[0].latitude;
          forklift.longitude = oldForklift[0].longitude;
          return forklift;
        }
      });
      setState((prev) => ({
        ...prev,
        status: counter,
        forklifts: carros,
        forklifts_filter: carros,
      }));
      localStorage.setItem("oldstate", JSON.stringify(carros));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getOperations() {
    try {
      const { data } = await api.get("/operation-places");
      let operations = data;
     
      setState((prev) => ({
        ...prev,
        operations,
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getSettings() {
    const settings = await api.get("/settings");
    setState((prev) => ({
      ...prev,
      viewport: {
        width: "100%",
        height: "100vh",
        latitude: settings.data.resource.coordinates.latitude || [],
        longitude: settings.data.resource.coordinates.longitude || [],
        zoom: 16,
      },
    }));
  }

  function resetPopup() {
    setState((prev) => ({
      ...prev,
      popupInfo: null,
    }));
  }

  function openPopup(forklift) {
    setState((prev) => ({
      ...prev,
      popupInfo: forklift,
    }));
  }

  function renderPopup() {
    return (
      state.popupInfo && (
        <Popup
          closeButton={false}
          tipSize={5}
          anchor="bottom-right"
          longitude={state.popupInfo.longitude}
          latitude={state.popupInfo.latitude}
          onClose={() => resetPopup()}
          closeOnClick={true}
        >
          <Grid container>
            <Grid item xs={8}>
              <PopupItem title="Máquina" value={state.popupInfo.name} />
            </Grid>
            <Grid item xs={4}>
              <PopupItem title="Tipo" value={state.popupInfo.type} />
            </Grid>
            <Grid item xs={8}>
              <PopupItem
                title="Área de Operação"
                value={state.popupInfo.place}
              />
            </Grid>
            <Grid item xs={4}>
              <PopupItem title="Área" value={state.popupInfo.scanner} />
            </Grid>
          </Grid>
        </Popup>
      )
    );
  }

  function setFilter() {
    const forklifts = filterForklifts();

    setState((prev) => ({
      ...prev,
      filteredForklifts: forklifts,
    }));
  }

  function filterForklifts() {
    if (state.forklifts.length == 0) return state.forklifts;

    let data = state.forklifts;

    if (state.filter.length > 0) {
      data = data.filter((item) => {
        const name = item.name.toString();
        if (name.includes(state.filter)) return true;
      });
    }

    if (state.machine_status != null) {
      data = data.filter((item) => {
        if (state.machine_status == item.active) return true;
      });
    }

    if (state.forklift_status) {
      data = data.filter((item) => {
        const name = item.name.toString();
        if (name.includes(state.forklift_status)) return true;
      });
    }

    if (state.operation_status) {
      data = data.filter((item) => {
        if (state.operation_status == item.place) return true;
      });
    }

    return data;
  }

  function renderMarker(index, forklift) {
    //const { latitude, longitude } = forklift;
    //const coordinates = randomCoordinates({ latitude, longitude }, 10);

    return (
      <Marker
        style={{
          zIndex: 9999,
          display: "block",
        }}
        key={index}
        longitude={forklift.longitude}
        latitude={forklift.latitude}
      >
        <div onClick={() => openPopup(forklift)}>
          <Location
            style={{
              display: "flex",
              position: "absolute",
              color: setColor(forklift.active ? forklift.place : "Ociosa"),
            }}
          />
        </div>
      </Marker>
    );
  }

  useEffect(() => {
    getCarros();
    getOperations();
    getSettings();
    const interval = setInterval(() => {
      getCarros2();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setFilter();
  }, [
    state.filter,
    state.forklifts,
    state.machine_status,
    state.operation_status,
    state.forklift_status,
  ]);

  function toggleSatelite(e) {
    setState((prev) => ({
      ...prev,
      satelite: e.target.checked,
    }));
  }

  return (
    <Main style={{ margin: 0, padding: 0, overflow: "hidden" }}>
      <MapContainer>
        <Grid container>
          <Grid item xs={9}>
            <MapGL
              {...state.viewport}
              mapboxApiAccessToken={token}
              mapStyle={`mapbox://styles/mapbox/${
                state.satelite ? "satellite-v9" : "streets-v11"
              }`}
              onViewportChange={(viewport) =>
                setState((prev) => ({ ...prev, viewport }))
              }
            >
              <div
                style={{
                  position: "inherit",
                  margin: 25,
                  bottom: "7%",
                  right: 0,
                  transition: "all .35s ease",
                }}
              >
                {" "}
                <NavigationControl />
              </div>
              <Paper
                style={{
                  position: "inherit",
                  paddingLeft: 12,
                  paddingRight: 12,
                  margin: 25,
                  top: 0,
                  left: 0,
                  transition: "all .35s ease",
                }}
              >
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={state.satelite}
                      onChange={toggleSatelite}
                      name="satellite"
                    />
                  }
                  label="Satélite"
                />
              </Paper>

              {state.filteredForklifts.map((forklift, index) => (
                <Marker
                  style={{
                    zIndex: 9999,
                    display: "block",
                  }}
                  key={index}
                  longitude={forklift.longitude}
                  latitude={forklift.latitude}
                >
                  <div onClick={() => openPopup(forklift)}>
                    <Location
                      style={{
                        display: "flex",
                        position: "absolute",
                        color: setColor(
                          forklift.active ? forklift.place : "Ociosa"
                        ),
                      }}
                    />
                  </div>
                </Marker>
              ))}
              {renderPopup()}
            </MapGL>
          </Grid>
          <Grid item xs={3}>
            <Sidebar
              status={state.status}
              machines={state.filteredForklifts}
              operations={state.operations}
              setFilter={setState}
              filter={state.setState}
              changeViewport={navigateTo}
            >
              <TextField
                value={state.filter}
                onChange={(e) => {
                  e.persist();
                  setState((prev) => ({ ...prev, filter: e.target.value }));
                }}
                label="Buscar"
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <FormControl style={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={state.machine_status}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      machine_status: e.target.value,
                    }))
                  }
                >
                  <MenuItem key="all" value={null}>
                    Status
                  </MenuItem>
                  <MenuItem value={true}>Em Operação</MenuItem>
                  <MenuItem value={false}>Ociosa</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={state.operation_status}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operation_status: e.target.value,
                    }))
                  }
                >
                  <MenuItem key="all" value={null}>
                    Operação
                  </MenuItem>
                  {state.operations.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={state.forklift_status}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      forklift_status: e.target.value,
                    }))
                  }
                >
                  <MenuItem key="all" value={null}>
                    Equipamento
                  </MenuItem>
                  {state.forklifts_filter.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Sidebar>
          </Grid>
        </Grid>
      </MapContainer>
    </Main>
  );
}
