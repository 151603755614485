import './Card.css'
import CardList from './CardList';
import CardInfo from './CardInfo';
import CardFiller from './CardFiller';
import React from 'react'

function factoryCard(props) {
    switch(props.type) {
        case 'info':
            console.log('info', props)
            return ( <CardInfo
                        title={props.title} 
                        entity={props.entity} /> )
        case 'list':
            return ( <CardList 
                        title={props.title} 
                        entity={props.entity}/> )
        case 'filler':
            /* Filler entity é um número 0% a 100% */
            return ( <CardFiller
                        title={props.title} 
                        entity={props.entity}/> )
        default:
            return ( <CardList
                        title={props.title}
                        entity={props.entity}/> )
    }

}

function Card(props) {
    return factoryCard(props) 
    
}

export default Card