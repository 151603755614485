import React, {useEffect, useState}from 'react';
import { Source, Layer } from "react-map-gl";
import api from '../../../core/services/api';

export default function () {
    const [pois, setPois] = useState(null);

    useEffect(() => {
      getPois();
    }, []);

    async function getPois() {
      const { data } = await api.get("/roads/pois");
      
      setPois(data.geom);
    }

    if(pois) {
        return(
            <Source key="pois_fill_layer" type="geojson" data={pois}>
              <Layer
                key="pois_fill"
                id="pois_fill"
                type="fill"
                paint={{
                  "fill-opacity": 0.8,
                  "fill-color": ["get", "color"],
                }}
              />
            </Source>
        )
    } 
    
    return null;
};
