import React, { Component } from 'react';
import css from '../../css/dashboard/graph_card_1.module.css';
import DropDown from '../../components/DropDown';
import dropDownCSS from '../../css/common/dropdown1.module.css';

class GraphCard1 extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
            title: props.title || "Title",
            dropDownData: props.dropDownData || new Array(12).fill(<a>Item</a>),
            graph: props.graph ||
            {
                xAxis: [ 1, 2, 3, 4, 5 ],
                yAxis: { labels: ["A", "B", "C", "D", "E"], min: 0, max: 1 },
                bars: [ [1, 1], [2, 2], [3, 3], [4, 4], [5, 5] ],
                colors: [ "black", "black" ],
                legends: [ "Tipo 1", "Tipo 2" ]
            }
        };
    }

    render() {
        const rowRatio = 1 / (this.state.graph.yAxis.labels.length - 1);

        return (
            <div {...this.props}>
                <div className={css.container}>
                    <div className={css.row0}>
                        <div>{this.state.title}</div>
                        <div className={css.legends}>
                            <div className={css.firstLegend}>
                                <div className={css.legend} style={{border:`3px solid ${this.state.graph.colors[0]}`}}></div>
                                <div>{this.state.graph.legends[0]}</div>
                            </div>
                            <div>
                                <div className={css.legend} style={{border:`3px solid ${this.state.graph.colors[1]}`}}></div>
                                <div>{this.state.graph.legends[1]}</div>
                            </div>
                        </div>
                    </div>
                    <div className={css.graph}>
                        <div className={css.grid}>
                            {
                                this.state.graph.yAxis.labels.map(item =>
                                {
                                    const height = (rowRatio * 100) + "px";
                                    return <div className={css.gridLine} style={{height, borderBottom:"1px solid #EAF0F4"}}><div>{item}</div></div>
                                })
                            }
                        </div>
                        <div className={css.xAxis} style={{height:(rowRatio * 100 * (this.state.graph.yAxis.labels.length - 1)) + "px"}}>
                            {
                                this.state.graph.xAxis.map((item, index) =>
                                {
                                    const range = this.state.graph.yAxis.max - this.state.graph.yAxis.min;
                                    const bars = this.state.graph.bars[index];
                                    const height0 = ((bars[0] / range) * 100) + "%";
                                    const height1 = ((bars[1] / range) * 100) + "%";

                                    return (
                                        <div className={css.barsArea}>
                                            <div className={css.bars}>
                                                <div style={{margin:"0 1px", width:"5px", height:height0, backgroundColor:this.state.graph.colors[0]}}></div>
                                                <div style={{margin:"0 1px", width:"5px", height:height1, backgroundColor:this.state.graph.colors[1]}}></div>
                                            </div>
                                            <div>{item}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GraphCard1;