import React from 'react';
import ListItemText from "@material-ui/core/ListItemText";

export default function ({title, children}) {
    return (
        <ListItemText
            primary={title}
            secondary={children}
            style={{ marginRight: 12 }}
        />
    )
};
