import styled from 'styled-components';

const dhlCor = '##ececec';

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #FFFFFF;
  padding: 0;
  margin: 0;
  /* border-bottom: 1px solid #c1d3ec; */
  position: fixed;
  z-index: 3;
`;

export const Logo = styled.img`
  width: 75px;
  margin-left:22px;
`;
