import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display:flex;
    height:150px;
    background:blue
    box-sizing: border-box;
    padding: 0;
`;

export const ContainerContent = styled.div`
    display:flex;
    width: 70%; 
    height:150px;
    background: blue;
`;

export const ContainerIcon = styled.div`
    display:flex;
    width: 30%; 
    height:150px;
    background: green;
    align-items:center;
    justify-content: center;
`;

export const Icon = styled.img`
    filter: invert(100%);
`;

export const Content = styled.div`
    display:flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%
    align-items:center;
    justify-content: space-between;
`;

export const ContentValue = styled.div`
    width: 40%;
    height: 100%
    font-size: 60px;
    margin-left: 20px;
    display:flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
   
`;

export const ContentText = styled.div`
    width: 45%;
    height: 70%
    display:flex;
    text-align: left;
    font-size: 17px;
    color: white;
    margin-right:10px
    justify-content: center;
    align-items: center;
`;




