import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import RoomIcon from "@material-ui/icons/Room";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

//mapbox lib
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapContainer, Pin } from "../../../../styles/Map";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#1e824c",
    "&$checked": {
      color: "#1e824c",
    },
    "&$checked + $track": {
      backgroundColor: "#1e824c",
    },
  },
  checked: {},
  track: {},
})(Switch);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function MapModal(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
    satelite: false,
    is_open: false,
    loading: true,
    properties: [],
    fences: [],
    filteredProperties: [],
    counters: [],
    filter: "",
    viewport: {
      width: "100%",
      height: "70vh",
      latitude: -13.3864706,
      longitude: -51.9170506,
      zoom: 5,
    },
  });
  const [viewport, setViewport] = useState({
    ...state.viewport,
   // latitude: props.latitude,
    //longitude: props.longitude,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setState({ ...state, satelite: event.target.checked });
  };
  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  return (
    <div>
      <Button
        style={{ color: "#1473e6", fontSize: 12 }}
        onClick={handleClickOpen}
        endIcon={<RoomIcon />}
      >
        Localização
      </Button>
      
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container style={{ paddingTop: 12 }}>
          <Grid item lg>
            {" "}
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
              Localização do veículo {props.board}
            </DialogTitle>
          </Grid>
          <Grid item lg={2} style={{ marginTop: 12 }}>
            {" "}
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={state.satelite}
                  onChange={handleChange}
                  name="checkedA"
                />
              }
              label="Satélite"
            />
          </Grid>
        </Grid>

        <DialogContent>
          <MapContainer>
            <MapGL
              {...viewport}
              mapboxApiAccessToken={token}
              mapStyle={`mapbox://styles/mapbox/${
                state.satelite ? "satellite-v9" : "streets-v11"
              }`}
              onViewportChange={(viewport) => setViewport(viewport)}
            >
              <div
                style={{
                  position: "inherit",
                  margin: 25,
                  bottom: "7%",
                  right: 0,
                }}
              >
                {" "}
                <NavigationControl />
              </div>

              <Marker
                style={{ zIndex: 9999, display: "block" }}
                key={props.id}
                longitude={parseFloat(props.longitude)}
                latitude={parseFloat(props.latitude)}
              >
                <Pin color={"#1e824c"}>
                  <span>{props.board}</span>
                </Pin>
              </Marker>
            </MapGL>
          </MapContainer>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default MapModal;
