import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Container, ContainerContent, ContainerIcon, Icon, Content, ContentValue, ContentText } from "./styles";
import { findLastIndex } from 'lodash';




const useStyles = makeStyles(({ spacing }) => ({
  card: {
    display:"flex",
    flexDirection: 'row',
    // marginTop: 40,
    borderRadius: spacing(0.5),
    transition: '0.3s',
    width: '100%',
    minWidth:'300px',
    height: '150px',
    boxSizing:'border-box',
  
    // overflow: 'initial',
    background: '#ffffff',
  },
  content: {
    padding: 0,
    textAlign: 'left',
    width: '100%',
  },
 
}));

// React.memo(function ElevatedHeaderCard() {

function Cards(props) {

  // console.log("CORRRRR : " + props.colors.color2)

  const classes = useStyles();

  return (
    <Card className={classes.card}>
     
      <CardContent className={classes.content}>
        <Container>

          <ContainerIcon style={{background: props.colorIcon }}>
           <Icon src={props.icon}  width="45"  height="45"/>
          </ContainerIcon> 

          <ContainerContent style={{background: props.colorInfo }}>
            <Content>

              <ContentValue><span>{props.value}</span></ContentValue>
              <ContentText><span>{props.text}</span></ContentText>

            </Content>
          </ContainerContent>

        </Container>
       
      </CardContent>
    </Card>
  );
};

export default Cards