import React from "react";
import Main from "../../components/Main";

//custom components
import TableForklifts from "./TableForklifts";

function ManagementForklifts() {
  return (
    <Main color="#eeeeee" title="Empilhadeiras">
      <TableForklifts />
    </Main>
  );
}

export default ManagementForklifts;
