export default function options(cols) {
  return {
    colors: ["#8480FC", "#1E87FF", "#FFAE36", "#F65CCD", "#4FC2B7", "#252525", "#6dd827"],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return parseFloat(val).toFixed(1) + "%";
      },
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "top",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: cols,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return parseFloat(val).toFixed(1) + "%";
        },
      },
    },
  };
}
