// @format
import axios from "axios";
import { getToken } from "../config/auth";

/*
 * Cria um instância do axios com o nome api e define
 * uma URL padrão.
 */
const api = axios.create({
  baseURL: //"http://127.0.0.1:3333/api/v1",
  //"https://move-dev.trackage.io/api/v1",
  "https://move-homolog.trackage.io/api/v1",
});

/*
 * Adiciona um interceptor com o header de autorização,
 * desta forma cada request enviará as credenciais
 * do usuário.
 */

api.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
