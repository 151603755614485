import React, { useState, useEffect } from 'react';
import Routes from './routes';
import ConfigContext from '../core/config';
import './App.css';

function App() {
  const [config, setConfig] = useState({ loading: true, data: {
    menu: []
  }});

  const { loading, data } = config;

  useEffect(() => {
    function getConfigAsync(){
      let data = localStorage.getItem('usuario');
      data = data ? JSON.parse(data) : {menu: []};
      setConfig({ data, loading: false });
    }
    getConfigAsync();
  }
  , []);

  return( 
  <ConfigContext.Provider value={data}>
    <Routes routes={data.menu} />
  </ConfigContext.Provider> 
  )
}

export default App;
