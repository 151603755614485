import React, {useState} from 'react'
import MaterialTable, {MTableToolbar} from 'material-table'


function TableItem(props){

    const [packages, setPackages] = useState(props.packages);
    const [package_list, setList] = useState(props.package_list);
    function getPackageName(id){
        const obj = packages.filter((packs) => {return packs.id === id})
        return obj[0]?.name || ''
    }

    return (
        <>
        {package_list ? (<MaterialTable
          columns={[
            { title: 'Nome', field: 'package_id', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },  render: (rowData) => getPackageName(rowData.package_id), editable: 'never' },
            { title: 'Quantidade', field: 'amount', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, editable: 'never' },
            { title: 'Enviadas', field: 'amount_send', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, editable: 'onUpdate'  },
            { title: 'Recebidas', field: 'amount_received', cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" }, editable: 'never'  }
          ]}
          data={package_list}
          options={{
        
            actionsColumnIndex: -1,
            search: false,
            showTitle: false,
            headerStyle: {
              padding: 10,
              paddingLeft: 40,
              textAlign: "left",
              backgroundColor: "#e8ebef",
              border: "#fff",
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f3f5f8" };
              }
            },
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ display: "none" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          style={{
            borderRadius: 5,
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...package_list];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setList([...dataUpdate]);
                  props.setObj([...dataUpdate]);
    
                  resolve();
                }, 1000)
              })
          }}
        />) : ""}
        
        </>
    )
}

export default TableItem;