export default [
    {
        "place_name": "AC233FC09034",
        "name": "Porto"
    },
    {
        "place_name": "AC233FC09012",
        "name": "Descarga"
    },
    {
        "place_name": "AC233FC09032",
        "name": "Desapeação"
    }
]