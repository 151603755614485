import React, {useState, useEffect} from 'react'
import api from '../../../../../core/services/api'


//materialui
import Grid from '@material-ui/core/Grid'


//custom components
import Main from '../../../components/Main'
import CardScanner from './CardScanner'
import ModalScanner from './ModalScanner'

function ScannersPanel(){
    const [state, setState] = useState({
        scanners: [],
    })


    useEffect(() => {
        getScanners();
        const interval = setInterval(() => {
          getScanners();
          console.log('Atualizando...')
        }, 20000);
        return () => clearInterval(interval);
      }, []);

    async function getScanners() {
        try {
          const resp = await api.get(`dashboard-scanners`);
          setState((prev) => ({
            ...prev,
            scanners: resp.data.data || []
          }));
        } catch (err) {
          console.warn(err);
        }
      }

    return(
        <Grid container spacing={3}>
            {state.scanners.map((item) =>{
                return (
                    <Grid item ><ModalScanner name={item.name} status={item.scanner_active} macaddress={item.macaddress} avaliability={parseInt(item.avaliability)*10} /></Grid>
                )
            })}
       
        </Grid>
    )
}


export default ScannersPanel;