import React from "react";

//@material-ui
import Grid from "@material-ui/core/Grid";

//content
import AverageTimeTotalTravel from './AverageTimeTotalTravel'
import QuantityTravelsRealized from './QuantityTravelsRealized'


//custom components

function TravelContent() {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <AverageTimeTotalTravel />
      </Grid>
      <Grid item>
        <QuantityTravelsRealized />
      </Grid>
    </Grid>
  );
}

export default TravelContent;
