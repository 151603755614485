import React, { useState } from 'react';
import { makeStyles,  lighten, withStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";



const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    maxHeight: 100,
    minWidth: 250,
    minHeight: 100,
    userSelect: 'none',
    position: 'relative',
    //backgroundColor: '#5ad545',
    color: '#fff'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    textAlign: 'right',
    marginBottom: 12,
  },
});

function CardScanner(props) {
  const classes = useStyles();

  function getStatus(status){
      if(status == 'green'){
        return '#5ad545'
      } else {
        return '#ec273b'
      }
  }

  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#5ad545', 0.7),
    },
    bar: {
      borderRadius: 3,
      backgroundColor:'#5ad545',
    },
  })(LinearProgress);

  return (
    <Card className={classes.root} style={{backgroundColor: getStatus(props.status)}} >
      <CardContent>
        <Typography variant="h5" component="p" style={{textAlign: 'center', lineHeight: 3}}>
          {props.name}
        </Typography>
      </CardContent>
      <div style={{ marginTop: 2, position: "relative" }}>
        <BorderLinearProgress
          style={{ height: 10 }}
          variant="determinate"
          value={5}
        />
      </div>

    </Card>)
}
export default CardScanner;