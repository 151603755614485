import './Card.css'
import React, { useState } from 'react'

function roundInterval(min, multiplier, interval) {
    return Math.ceil(min + (multiplier * interval))
}

function roundHalf(min, max) {
    return Math.round((min + max) / 2)
}

function CardFiller({ entity, title }) {
    /* Range -40 até 130 divido em 10 partes */
    const rate = 17
    const [state, setState] = useState({
        items: []
    })
    const { 
        max, 
        min, 
        value, 
        unit
    } = entity

    /* Um número entre 0 e 10 */
    const percent = Math.trunc((value / max) * 100)
    const part = (percent * 10) / 100
    const count = -40 + (rate * part) <= 130 ? -40 + (rate * part) : 130;

    const interval = (max + min) / 7

    return (
        <div className='Card Filler'>
            <div className='CardHeader'>
                <p>{ title }</p>
                {/* <div className='ImageCropper'>
                    <img src={Share} />
                </div> */}
            </div>
            <div className='Cropper'>
                <p className='Unit'>{min}{unit}</p>
                <p className='Unit'>{roundInterval(min, 1, interval)}{ unit }</p>
                <p className='Unit'>{roundInterval(min, 2, interval)}{ unit }</p>
                <p className='Unit'>{roundHalf(min, max)}{ unit }</p>
                <p className='Unit'>{roundInterval(min, 4, interval)}{ unit }</p>
                <p className='Unit'>{roundInterval(min, 5, interval)}{ unit }</p>
                <p className='Unit'>{ max }{ unit }</p>
                <p className='Value'>{ value }<i>{ unit }</i></p>
                <svg viewBox='0 0 10 10'>
                    <circle cx='5' cy='5' r='3.5' />
                    <circle cx='5' cy='5' r='3.5' />
                    <circle cx='5' cy='5' r='3.5' />
                    <polygon 
                        style={{transform: `rotate(${count}deg) rotate(-45deg)`}}
                        class="Pointer"
                        points="4.80 1, 5 1.5, 5.2 1" />
                </svg>
            </div>
            <div className='Legends'>
                <p><div className='Purple' />Tempo gasto</p>
                <p><div className='Blue' />Tempo Target</p>
            </div>
        </div>
    )
}

export default CardFiller