import React, { useState, useEffect } from "react";
import Main from "../../components/Main";
import MTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import api from "../../../../core/services/api";
import XLSX from "xlsx";
import Alert from "../../components/Alert";

// Components
import General from './General';


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 200,
    marginBottom: 24,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btn: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

export default (props) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    tableDataSource: [],
    tableDataSourceFilter: [],
    excel: [],
    excelFilter: [],
    baseTime: 0,
    check_ociosity: false,
    enableBtnFilter: false,
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    operations: [],
    forklifts: [],
    forkliftID: undefined,
    tableTitle: "Permanência da empilhadeira",
    durationForklift: "",
    checkboxIdleness: false,
    checkboxStatus: true,

    dateRangeID: undefined,
    dateRange: [
      { id: 1, name: "Últimos 7 dias" },
      { id: 2, name: "Últimos 14 dias" },
      { id: 3, name: "Últimos 20 dias" },
      { id: 4, name: "Últimos 30 dias" },
      { id: 5, name: "Hoje" },
      { id: 6, name: "Ontem" },
      { id: 7, name: "Essa Semana" },
      { id: 8, name: "Última Semana" },
      { id: 9, name: "Esse Mês" },
      { id: 10, name: "Último Mês" },
      { id: 11, name: "Este Ano" },
    ],
  });

  const [loading, setLoading] = useState(false);
  const classes = useStyles();


  async function getReport() {
    try {
      let endpoint = '';
      let reportName = '';
      switch (value) {
        case 0:
          endpoint = 'report';
          reportName = 'scanners-geral.xlsx';
          break;
        case 1:
          endpoint = 'report-tracked';
          reportName = 'consolidado-scanners.xlsx';
          break;
        case 2:
          endpoint = 'report-untracked';
          reportName = 'consolidado-nao-rastrado.xlsx';
          break;
        default:
          break;
      }
      // alert(endpoint);
      setLoading(true);
      let url = `/${endpoint}?start_at=${state.startDate}&end_at=${state.endDate}`;
      url += state.forkliftID ? "&fk_number=" + state.forkliftID : "";

      // Verifica o tempo base então passa para API se for maior que 3 altera
      if (state.check_ociosity && endpoint == 'report') {
        let time = state.baseTime > 3 ? state.baseTime : 3;
        url += `&minutes=${time}`;
      };

      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url, { responseType: 'blob' }).then(blobFile => {
        if (endpoint !== 'report') {
          const url = window.URL.createObjectURL(new Blob([blobFile.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${reportName}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoading(false);
        } else {
          setOpen(true);
        }
      })
    } catch (err) {
      console.warn(err);
    }
  }

  useEffect(() => {
    //
    initData();
  }, []);

  async function initData() {
    const forklifts = await api.get("/forklifts");
    setState((prev) => ({
      ...prev,
      forklifts: forklifts.data,
    }));
  }

  const handleSubmit = (e) => {
    getReport();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleTimeChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    console.log('handleEndDate')
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const changeForkliftID = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      forkliftID: value,
      enableBtnFilter: false,
    }));
  };

  const handleChangeDateRange = (e) => {
    console.log('handleChangeDateRange');
    const { value } = e.target;
    if (value == 1) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 2) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 3) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(20, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 4) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 5) {
      setState((prev) => ({
        ...prev,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 6) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 7) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 8) {
      const firsDayWeek = moment().startOf("isoWeek");
      const lastWeek = firsDayWeek.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastWeek.startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: lastWeek.endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 9) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 10) {
      const firstDayMonth = moment().startOf("month");
      const lastMonth = firstDayMonth.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: lastMonth.endOf("month").format("YYYY-MM-DD"),
        resultBy: "day",
      }));
    }
    if (value == 11) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: "month",
      }));
    }

  };

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Main color="#f7f7f7">
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Scanner Geral" />
          <Tab label="Consolidado - Scanner" />
          <Tab label="Consolidado - Não rastreado" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <General
          classes={classes}
          forklifts={state.forklifts}
          forkliftID={state.forkliftID}
          changeForkliftID={changeForkliftID}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleChangeDateRange={handleChangeDateRange}
          operationID={state.operationID}
          startDate={state.startDate}
          endDate={state.endDate}
          dateRange={state.dateRange}
          dateRangeID={state.dateRangeID}
          loading={state.loading}
          check_ociosity={state.check_ociosity}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleTimeChange={handleTimeChange}
          enableBtnFilter={state.enableBtnFilter}
          baseTime={state.baseTime}
          showTimeFilter={true}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <General
          classes={classes}
          forklifts={state.forklifts}
          forkliftID={state.forkliftID}
          changeForkliftID={changeForkliftID}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleChangeDateRange={handleChangeDateRange}
          operationID={state.operationID}
          startDate={state.startDate}
          endDate={state.endDate}
          dateRange={state.dateRange}
          dateRangeID={state.dateRangeID}
          loading={state.loading}
          check_ociosity={state.check_ociosity}
          handleChange={handleChange}
          handleTimeChange={handleTimeChange}
          handleSubmit={handleSubmit}
          enableBtnFilter={state.enableBtnFilter}
          showTimeFilter={false}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <General
          classes={classes}
          forklifts={state.forklifts}
          forkliftID={state.forkliftID}
          changeForkliftID={changeForkliftID}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleChangeDateRange={handleChangeDateRange}
          operationID={state.operationID}
          startDate={state.startDate}
          endDate={state.endDate}
          dateRange={state.dateRange}
          dateRangeID={state.dateRangeID}
          loading={state.loading}
          check_ociosity={state.check_ociosity}
          handleChange={handleChange}
          handleTimeChange={handleTimeChange}
          handleSubmit={handleSubmit}
          enableBtnFilter={state.enableBtnFilter}
          showTimeFilter={false}
        />
      </TabPanel>
     <Alert open={open} message="O relatório será enviado via email em alguns minutos" setOpen={() => setOpen()}/>
    </Main>
  );
};
