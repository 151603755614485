import styled from 'styled-components'

export const Container = styled.div`
padding: 0;
margin: 0;
width: 100%;
height: 80VH;

`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  // max-width: 700px;
`;

export const FormFilters = styled.form`

    display:flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    margin-bottom: 10px
    margin-top: -16px
    justify-content: space-between;
   

`