import React, { useState } from "react";
import MapGl, { Source, Layer, Marker } from "react-map-gl";

// Components
import Button from "@material-ui/core/Button";

import Streets from "./streets";
import Pois from "./pois";
import Route from "./route";
import { Paper, Typography } from "@material-ui/core";

const token =
  "pk.eyJ1IjoiZ2FsaWxldXUiLCJhIjoiY2syNDBka3FyMDBhazNudGNrNzM2NWU3ZyJ9.hLvLb-t9Xc9h9C4vxDL8gw";

export default function ({ journey, open, setModal }) {
  const [viewport, setViewportState] = useState({
    width: "100%",
    height: "100vh",
    latitude: -0.001,
    longitude: 0.0091,
    zoom: 16.2,
  });
  const [filter, setFilter] = useState(null);

  function renderMarkers() {
    if (journey && journey.markers) {
      let colors = {
        origin: "#3a539b",
        destiny: "#1e824c",
        end: "#f7ca18",
      };

      const raw_data = [];
      let counter = 1;

      Object.keys(journey.markers).forEach((item) => {
        journey.markers[item].forEach((subitem) => {
          let obj = {
            step: counter,
            color: colors[item],
            name: subitem.name,
            lng: subitem.longitude,
            lat: subitem.latitude,
          };

          raw_data.push(obj);

          counter += 1;
        });
      });

      const markerList = raw_data.map((item) => (
        <Marker latitude={item.lat} longitude={item.lng} anchor="top">
          <div
            style={{
              border: "1px solid",
              borderColor: item.color,
              color: item.color,
              background: "white",
              borderRadius: "4px",
              padding: "2px 4px",
            }}
          >
            <Typography variant="body2">
              {item.step} - {item.name}
            </Typography>
          </div>
        </Marker>
      ));

      return markerList;
    }

    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <Paper
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          justifyContent: "flex-start",
        }}
      >
        <Route journey={journey} />
        <Button
          style={{ alignContent: "flex-end" }}
          onClick={() => setModal(false)}
          variant="contained"
          color="primary"
        >
          Fechar
        </Button>
      </Paper>
      <MapGl
        {...viewport}
        mapboxApiAccessToken={token}
        onViewportChange={(viewport) =>
          setViewportState((prev) => ({ ...prev, viewport }))
        }
        containerStyle={{
          width: "70%",
          height: "100%",
        }}
      >
        <Pois />
        <Streets />
        <Source
          id="origin"
          type="geojson"
          data={journey.dinamic_route.origin.geom}
        >
          <Layer
            id="origin_route"
            type="line"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": filter == "origin" ? ["get", "color"] : "orange",
              "line-width": 8,
              "line-opacity": 0.8,
            }}
          />
        </Source>
        {!journey.dinamic_route.destiny ? null : (
          <Source
            id="destiny"
            type="geojson"
            data={journey.dinamic_route.destiny.geom}
          >
            <Layer
              id="destiny_route"
              type="line"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": filter == "destiny" ? ["get", "color"] : "orange",
                "line-width": 8,
                "line-opacity": 0.8,
              }}
            />
          </Source>
        )}
        <Source id="end" type="geojson" data={journey.dinamic_route.end.geom}>
          <Layer
            id="end_route"
            type="line"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-width": 8,
              "line-color": filter == "end" ? ["get", "color"] : "orange",
              "line-opacity": 0.8,
            }}
          />
        </Source>
        {renderMarkers()}
      </MapGl>
    </div>
  );
}
