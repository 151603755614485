import {RENDER_STATE} from 'react-map-gl-draw';
import styled from 'styled-components';

const verde =
  'invert(66%) sepia(90%) saturate(387%) hue-rotate(54deg) brightness(90%) contrast(94%)';
const vermelho =
  'invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(1200%) contrast(2)';


export const Icon = styled.img`
  filter: ${props => (props.cerca ? verde : vermelho)};
`; 
