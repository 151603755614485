import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";
//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";

//@material-ui
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

function TableShifts(props) {
  function getHeaderColor(id) {
    const colors = [
      "#8480fc99",
      "#8480fc99",
      "#1e87ff99",
      "#4fc2b799",
      "#ffae3699",
      "#f65dcd99",
      "#9fe57099",
      "#000000",
      "#8480fc99",
      "#1e87ff99",
      "#4fc2b799",
      "#ffae3699",
      "#f65dcd99",
      "#9fe57099",
      "#79b35399",
      "#cee8a1",
      "#d3dc99",
      "#d7ea58",
      "#fba2a2",
      "#c56fff"
    ];
    return colors[id];
  }
  function getWeekendDays(list) {
    const days = [
      "Seg, ",
      "Seg, ",
      "Ter, ",
      "Qua, ",
      "Qui, ",
      "Sex, ",
      "Sab, ",
      "Dom, ",
    ];
    const numberToDays = list?.map((item) => {
      return days[item];
    });
    return numberToDays;
  }

  async function deleteOperationshift(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o turno " + item.resource.work_shift
        )
      ) {
        await api.delete(`operation-shifts-delete/` + item.id);
      } else {
      }
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }
  return (
    <MaterialTable
      title={props.title}
      columns={[
        {
          title: "Local",
          field: "operation_place_id",
          render: (rowData) => (
            <p>{props.getOperationName(rowData.operation_place_id)}</p>
          ),
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
        {
          title: "Turno",
          field: "resource",
          render: (rowData) => <p>{rowData.resource.work_shift}</p>,
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
        {
          title: "Início",
          field: "resource",
          render: (rowData) => <p>{rowData.resource.start_at}</p>,
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
        {
          title: "Fim",
          field: "resource",
          render: (rowData) => <p>{rowData.resource.end_at}</p>,
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
        {
          title: "Dias da Semana",
          field: "resource",
          render: (rowData) => (
            <p>{getWeekendDays(rowData.resource.week_days)}</p>
          ),
          cellStyle: {
            paddingLeft: 40,
            border: "#fff",
            width: "15%",
          },
        },

        {
          title: "Status",
          field: "active",
          render: (rowData) =>
            rowData.active ? (
              <p
                style={{
                  backgroundColor: "#c9ffc9",
                  borderRadius: 20,
                  width: "fit-content",
                  padding: 7,
                }}
              >
                Ativo
              </p>
            ) : (
              <p
                style={{
                  backgroundColor: " #ffc9c9",
                  borderRadius: 20,
                  width: "fit-content",
                  padding: 7,
                }}
              >
                Inativo
              </p>
            ),
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
        {
          field: "actions",
          title: "Ações",
          cellStyle: { padding: 0, textAlign: "left", border: "#fff" },
          render: (rowData) => (
            <>
              <FormGroup row>
                <EditForm
                  title={props.getOperationName(rowData.operation_place_id)}
                  //campos
                  work_shift={rowData.resource.work_shift}
                  operation_place_id={rowData.operation_place_id}
                  start_at={rowData.resource.start_at}
                  end_at={rowData.resource.end_at}
                  week_days={rowData.resource.week_days}
                  active={rowData.active}
                  resource={rowData.resource}
                  rowData={rowData}
                  //methods
                  getData={() => props.getData()}
                />
                <IconButton color="secondary"  onClick={() => deleteOperationshift(rowData)}>
                  <DeleteIcon />
                </IconButton>{" "}
              </FormGroup>
            </>
          ),
          cellStyle: { paddingLeft: 40, border: "#fff" },
        },
      ]}
      data={props.data}
      actions={[
        {
          icon: () => (
            <CreateForm
              rowData={props.data}
              title={props.getOperationName(props.data[0].operation_place_id)}
              operation_place_id={props.data[0].operation_place_id}
              //methods
              getData={() => props.getData()}
            />
          ),
          tooltip: "Adicionar",
          isFreeAction: true,
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        search: false,
        headerStyle: {
          fontWeight: "bold",
          padding: 10,
          paddingLeft: 40,
          backgroundColor: "#fff",
          border: "#fff",
        },
        rowStyle: (x) => {
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f3f5f8" };
          }
        },
      }}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              backgroundColor: getHeaderColor(props.data[0].operation_place_id),
              color: props.data[0].operation_place_id == 7 ? "#fff" : "#212529"
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
      }}
    />
  );
}

export default TableShifts;
