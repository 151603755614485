import React from 'react'
import {useStyles} from './styles'
import boxes from "../../../assets/icons/boxes.svg";
import settings2 from '../../../assets/icons/settings2.svg';


//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import CallMade from "@material-ui/icons/CallMade";
import CallReceived from "@material-ui/icons/CallReceived";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

//material icons
import PersonIcon from '@material-ui/icons/Person'
import LensIcon from '@material-ui/icons/Lens'
import SettingsIcon from '@material-ui/icons/Settings'
import BuildIcon from '@material-ui/icons/Build'

//others components
import { LoadingContainer, Title, Value, Prev, PrevValue, PrevLabel } from "./styles";


function Flow({title, value, last_value}){
    const classes = useStyles();
    return(
        <Card className={classes.root}>
       
  
        <CardContent>
        <Grid container direction="column" justify="center" alignItems="center"> 
        <Grid item><Typography variant="h6" gutterBottom>Fluxo de Pessoas</Typography></Grid>
        
        <Grid container direction="row">
            <Grid item lg={3} md={3} sm={3} xs={3}>
            <Grid container direction="column" justify="center" alignItems="center">
            <p>Oficina Central</p>
            <SettingsIcon style={{ color: "#797979", fontSize: 60 }} />
            <Typography variant="h6" gutterBottom>5</Typography>
            </Grid>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={3}>
            <Grid container direction="column" justify="center" alignItems="center">
            <p>Laboratórios</p>
            <SettingsIcon style={{ color: "#797979", fontSize: 60 }} />
            <Typography variant="h6" gutterBottom>5</Typography>
            </Grid>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={3}>
            <Grid container direction="column" justify="center" alignItems="center">
            <p>Sala de Máquinas</p>
            <img src={settings2} width="60" style={{filter: 'opacity(0.6)'}}/>
            <Typography variant="h6" gutterBottom>5</Typography>
            </Grid>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={3}>
            <Grid container direction="column" justify="center" alignItems="center">
            <p>Logística</p>
            <img src={boxes} width="60" style={{filter: 'opacity(0.6)'}}/>
            
            <Typography variant="h6" gutterBottom>5</Typography>
            </Grid>
            </Grid>
        </Grid>

        </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    )
}


export default Flow;