import styled from 'styled-components';

export const MapContainer = styled.div`
    overflow: hidden;
    position: absolute;
    min-width: 96.5%
   
    height: 93%;
    left: 50px;
    top: 50px;
`

export const Sidebar = styled.div`
    padding: 0px 20px;
`

export const OperationBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
`
export const OperationDot = styled.div`
    width: 10px;
    height: 10px;
    background: ${props => props.color || 'black'};
    margin-right: 10px;
    border-radius: 10px;
`
export const OperationTitle = styled.p`
    margin: 0;
    color: #1D2329;
    font-size: 14px;
`

export const SidebarTitle = styled.h5`
    margin: 10px 0;
    color: #7C8796;
    font-size: 14px;
`

export const MachinesContainer = styled.div`
    
    width: 100%;
    max-height: 350px;
`

export const CounterContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 5px ;
`

export const Title = styled.h6`
    margin: 0;
    font-size: 12px;
    color: #7C8796;
`

export const Value = styled.h1`
    margin: 0;
    color: #1D2329;
    font-weight: bolder;
    font-size: 32px;
`

export const Vehicle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #ECEFF1;
    padding-right: 10px;
`

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: ${props => props.color || 'black'};
    color: white;
    border-radius: 40px;
    margin-right: 15px;
`

export const VehicleData = styled.div`
    display: flex;
    align-items: center;
`

export const VehicleNumber = styled.div`
    margin: 0;
    color: #1D2329;
`

export const VehicleIcon = styled.div`

`

export const PopupContainer = styled.div`
    margin-top: 5px;
`

export const PopupValue = styled.h3`
    color: #1D2329;
    margin: 0;
    font-size: 15px;
`

export const PopupTitle = styled.p`
    color: #ADB6C3;
    margin: 0;
    font-size: 12px;
`