import React, {useState, useEffect} from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import RankYear from "./RankYear";
import RankMonth from "./RankMonth";
import api from '../../../../../../core/services/api';

export default function (props) {
  const [state, setState] = useState({
    year: [],
    month: []
  })
   
  async function getData() {
    const config = await api.get('/settings');
    const years = await api.get('/forklift-idleness-year-rank');
    const months = await api.get('/forklift-idleness-month-rank');

    setState({
      year: years.data.data,
      month: months.data.data,
      idleness_goals: parseInt(config.data.resource.idleness_goals)-2 < 0 ? 0 : parseInt(config.data.resource.idleness_goals)-2
    })
  } 

  useEffect(() => {
    getData()
  }, [])

  return (
    <Paper>
      <div style={{ paddingBottom: "15px", zIndex: '-1' }}>
        <Grid container spacing={2}>
        <h5 style={{width: '100%', color: "grey", textAlign: "center", fontWeight: "bolder" }}>Ranking Ociosidade de Máquinas - Número Máquina</h5>
          <Grid item xs={12}>
            <div style={{padding: '0 10px'}}>
              <Grid container spacing={10}>
                <RankMonth data={state.month} idleness_goals={state.idleness_goals} />
                <RankYear data={state.year} idleness_goals={state.idleness_goals} />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
