import React, { useState, useEffect } from "react";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import TKGListItem from "./TKGListItem";
import api from "../../../core/services/api";

export default function ({ journey }) {
  const [routes, setRoutes] = useState();

  useEffect(() => {
    async function getRoutes() {
      const { data } = await api.get(
        "/static_routes"
      );
      setRoutes(data);
    }

    getRoutes();
  }, []);

  function getPercentage({distance, rt_distance}) { 
    let percentual = ((distance * 100) / rt_distance) - 100;
    return {
      percentual,
      difference: distance - rt_distance
    };
   }

  function filterRoute(data) {
    
    let resp = Object.keys(data).map(route => {
      const {percentual, difference} = getPercentage(data[route]);
      const is_short = percentual <= 0;
        return (
          <TKGListItem title={route}>
          <ul>
            <li>{data[route].distance.toPrecision(3)} mts</li>
            <li>{data[route].rt_distance.toPrecision(3)} mts</li>
            <li style={{color: is_short ? 'green' : 'red'}}>{percentual.toPrecision(3)} %</li>
            <li style={{color: is_short ? 'green' : 'red'}}>Diferença: {difference.toPrecision(3)} mts</li>
          </ul>
        </TKGListItem>
        )
    });

    return resp
  }

  return (
    <div style={{ width: "60%" }}>
      <Paper style={{ padding: 10, height: "100%", borderRadius: 0 }}>
        <Typography variant="h5" style={{ padding: 10 }}>
          {" "}
          Resultado{" "}
        </Typography>
        {!journey ? null : (
          <List>
            <ListItem>
              <ListItemText primary="Tempos" />
            </ListItem>
            <ListItem>
              <TKGListItem title="Buffer/Origem">
                {journey.times.time_supply_cart} min
              </TKGListItem>
              <TKGListItem title="Gravitacional">
                {journey.times.time_supply_line} min
              </TKGListItem>
              <TKGListItem title="Retorno">
                {journey.times.time_supply_return} min
              </TKGListItem>
              <TKGListItem title="Paradas">
                {journey.times.time_supply_total} min
              </TKGListItem>
              <TKGListItem title="Percurso">
                {journey.times.eta} min
              </TKGListItem>
              <TKGListItem title="Retornando">
                {journey.times.total} min
              </TKGListItem>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Distâncias" />
            </ListItem>
            <ListItem>
              <TKGListItem title="Recolhendo">{journey.origin} km</TKGListItem>
              <TKGListItem title="Distribuindo">
                {journey.destiny} km
              </TKGListItem>
              <TKGListItem title="Retornando">{journey.end} km</TKGListItem>
              <TKGListItem title="Total">
                {journey.total.toPrecision(3)} km
              </TKGListItem>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Comparativo Rotas" />
            </ListItem>
            <ListItem>
              {filterRoute(journey.static_routes)}
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Rebocadores" />
            </ListItem>
            <ListItem>
              <TKGListItem title="Suma Box">
                {journey.size.suma_box}
              </TKGListItem>
              <TKGListItem title="Suma Pallet">
                {journey.size.suma_pallet}
              </TKGListItem>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Volume Total" />
            </ListItem>
            <ListItem>
              <TKGListItem title="Caixas M3">{journey.size.box}</TKGListItem>
              <TKGListItem title="Pallets M2">
                {journey.size.pallet}
              </TKGListItem>
            </ListItem>
          </List>
        )}
      </Paper>
    </div>
  );
}
