import React, {useState} from 'react';
import moment from 'moment'
import {useStyles} from './styles'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

//custom components
import Table from './Table'


function RankingMachineConsumption(){
    const classes = useStyles();
    const [state, setState] = useState({          
      values: [],
      values2: [],
      cols: [],
      periods_id: null,
      operations_id: null,
      machines_id: null,
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
    })
    const handleStartDate = (date) => {
      setState((prev) => ({
        ...prev,
        startDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
    const handleEndDate = (date) => {
      setState((prev) => ({
        ...prev,
        endDate: moment(date).format("YYYY-MM-DD"),
      }));
    };
    const clients = [
      {id: 1, name: "Cliente A"},
      {id: 2, name: "Cliente B"}
    ]
    const machines = [
      {id: 1, name: "154"},
      {id: 2, name: "245"},
      {id: 3, name: "333"}
    ]
    const machine_type = [
      {id: 1, name: "Tipo Máquina 1"},
      {id: 2, name: "Tipo Máquia 2"},
      {id: 3, name: "Tipo 3"},
    ]
    const periods = [
      {id: 1, name: "Periodo A"},
      {id: 2, name: "Periodo B"},
      {id: 3, name: "Periodo C"}
    ]
    return (
        <Card className={classes.root}>
        <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Ranking de Máquinas por Consumo
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
       <Select
       MenuProps={{ disableScrollLock: true }}
        fullWidth
        variant="outlined"
         displayEmpty
         value={state.operations_id}
         onChange={(e) =>
           setState((prev) => ({
             ...prev,
             operations_id: e.target.value,
           }))
         }
         //input={<BootstrapInput />}
         
        
       >
         <MenuItem key="all" value={null}>
           Selecione um período
         </MenuItem>
         {periods.map((item) => {return (
           <MenuItem key={item.id} value={item.id}>
             {item.name}
           </MenuItem>
         )})}
       </Select>
    
</Grid>
  
        <Grid item lg={3} md={3} sm={3} xs={3}>
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  } 
                >
                  <MenuItem key="all" value={null}>
                    Número Máquina
                  </MenuItem>
                  {machines.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Tipo Máquina
                  </MenuItem>
                  {machine_type.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
       <Select
       MenuProps={{ disableScrollLock: true }}
        fullWidth
        variant="outlined"
         displayEmpty
         value={state.operations_id}
         onChange={(e) =>
           setState((prev) => ({
             ...prev,
             operations_id: e.target.value,
           }))
         }
         //input={<BootstrapInput />}
         
        
       >
         <MenuItem key="all" value={null}>
           Cliente
         </MenuItem>
         {clients.map((item) => {return (
           <MenuItem key={item.id} value={item.id}>
             {item.name}
           </MenuItem>
         )})}
       </Select>
    
</Grid>           
       



            <Grid item container lg={6} md={6} sm={6} xs={6}>
                <Grid item>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Ranking de Máquinas - Acumulado Ano
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Table />
                </Grid>
            </Grid>
            <Grid item container lg={6} md={6} sm={6} xs={6}>
                <Grid item>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Ranking de Máquinas - Acumulado Mês
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Table />
                </Grid>
            </Grid>
        </Grid>
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default RankingMachineConsumption;

