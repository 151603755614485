import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Divider from "@material-ui/core/Divider";
import {IconContainer} from './styles';


//@material ui 
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


//@material icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const useStyles = makeStyles({
  root: {
    minWidth: 1080,
    padding: 0,
    
  },
});


function TimeAverageCard(){
    const classes = useStyles();
    const [state, setState] = useState({
        periods_id: null,
        operations_id: null,
        machines_id: null,
        endDate: moment(Date.now()).format("YYYY-MM-DD"),
        startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
      })
      const handleStartDate = (date) => {
        setState((prev) => ({
          ...prev,
          startDate: moment(date).format("YYYY-MM-DD"),
        }));
      };
      const handleEndDate = (date) => {
        setState((prev) => ({
          ...prev,
          endDate: moment(date).format("YYYY-MM-DD"),
        }));
      };
        
    const periods = [
        {id: 1, name: "Últimos 7 dias"},
        {id: 2, name: "Mês passado"}
      ]
      const forkliftslist = [
        {id: 1, name: "Empilhadeira 1"},
        {id: 2, name: "Empilhadeira 2"},
        {id: 3, name: "Empilhadeira 3"},
      ]
    
    return (
        <Grid container  justify="center" alignItems="center" spacing={3}>
         <Grid item lg={3} md={3} sm={3} xs={3}>
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.periods_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      periods_id: e.target.value,
                    }))
                  }
                  
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Selecione um período
                  </MenuItem>
                  {periods.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.machines_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      machines_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Número Máquina
                  </MenuItem>
                  {forkliftslist.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Local Operação - Cadastro
                  </MenuItem>
                  {periods.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
       <Select
       MenuProps={{ disableScrollLock: true }}
        fullWidth
        variant="outlined"
         displayEmpty
         value={state.operations_id}
         onChange={(e) =>
           setState((prev) => ({
             ...prev,
             operations_id: e.target.value,
           }))
         }
         //input={<BootstrapInput />}
         
        
       >
         <MenuItem key="all" value={null}>
           Operador
         </MenuItem>
         {periods.map((item) => {return (
           <MenuItem key={item.id} value={item.id}>
             {item.name}
           </MenuItem>
         )})}
       </Select>
    
</Grid>
        
        
        <Grid item lg={12} sm={12} md={12}> 
        <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{marginBottom: '-24px'}}>
                <Grid item>
                    <IconContainer color="#7D77FF">
                    <AccessTimeIcon style={{fontSize: 44}}/> 
                    </IconContainer>
                </Grid>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Tempo Médio por Viagem Geral</Typography></Grid>
                    <Grid item><p style={{fontSize: 80, fontWeight: 'bolder'}}>67:52</p></Grid>
                </Grid>
                <Grid item container direction="column" justify="center" lg={1} sm={1} md={1}>
                    <Grid item><KeyboardArrowRightIcon style={{fontSize: 80, opacity: '0.6'}} /></Grid>
                </Grid>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Tempo Médio por Viagem Blocado</Typography></Grid>
                    <Grid item><p style={{fontSize: 80, fontWeight: 'bolder'}}>67:52</p></Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginBottom: 20}}/>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Tempo Médio por Viagem Segregado</Typography></Grid>
                    <Grid item><p style={{fontSize: 80, fontWeight: 'bolder'}}>05:52</p></Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginBottom: 20}}/>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Tempo Médio por Viagem Estruturado</Typography></Grid>
                    <Grid item><p style={{fontSize: 80, fontWeight: 'bolder'}}>05:52</p></Grid>
                </Grid>
                 
          </Grid>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
    )
}

export default TimeAverageCard;