import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";
import COMPONENTS from "./components/componentMapper";

const Login = COMPONENTS['LoginPage'];

function Routes({ routes }) {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact={true} />
        <Route path="/login" component={Login} />
        {(routes && routes.length) > 0 && routes.map((item) => {
          const Component = COMPONENTS[item.resource.component];
          return (
            <Route key={item.name} path={`/${item.resource.route}`}>
              <Component />
            </Route>
          );
        })}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
