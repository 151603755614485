import React, { useEffect, useState, useRef} from "react";

import 'date-fns';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {DivWrapper, DivHeader, ContainerIMG, Placeholder} from './styles'


function SelectDate({ title }) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const [selectedDateInicio, setSelectedDateInicio] = useState(null);
  const [selectedDateFim, setSelectedDateFim] = useState(null);

  const toggle = () => setOpen(state => !state);
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateInicioChange = (date) => {
    setSelectedDateInicio(date);
  };

  const handleDateFimChange = (date) => {
    setSelectedDateFim(date);
  };



  return (
    <DivWrapper>
      <DivHeader
        tabIndex={0}
        role="button"
        onKeyPress={(e) => toggle()}
        onClick={(e) => handleClickOpen()}

        onMouseEnter={(e)=>{
          setHover(true);
        }}

        onMouseLeave={(e)=>{
          setHover(false);
        }}
      >
        <p><Placeholder> {title} </Placeholder></p>
          <ContainerIMG>
            <ArrowDropDownIcon 
              style={{
                color: ( hover ? 'black' : 'rgb(133, 133, 133)' ),
                cursor:'pointer',
                width: '25px',
                height: '25px',
                fontSize:40,
                transition:'0.3s',
                }} 
                fontSize='inherit' 
            />
          </ContainerIMG>
            
      </DivHeader>
  
         <Dialog
         open={open}
         onClose={handleClose}
         fullWidth={true}
         maxWidth={"xs"}
        >
          <DialogTitle>Selecione um período</DialogTitle>
  
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  id="dataInicio"
                  label="Data Início"
                  value={selectedDateInicio}
                  onChange={handleDateInicioChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  id="dataFim"
                  label="Data Fim"
                  value={selectedDateFim}
                  onChange={handleDateFimChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              
              </Grid>
            </MuiPickersUtilsProvider>
                     
          </DialogContent>
  
          <DialogActions style={{ padding: 12 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              // onClick={handleSubmit}
            
            >
              Concluir
            </Button>
          </DialogActions>
        </Dialog>
      
    </DivWrapper>
  );
}

export default SelectDate
