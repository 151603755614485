import React, { Component } from 'react';
import css from '../../css/dashboard/beacon_card.module.css';

class BeaconCard extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
            title: props.title || "Title",
            lineData: props.lineData ||
            [
                { color: "black", number: 0 },
                { color: "black", number: 0 },
                { color: "black", number: 0 }
            ]
        };
    }

    render() {

        return (
            <div {...this.props}>
                <div className={css.container}>
                    <div>{this.state.title}</div>
                    <div className={css.row1}>
                        <div className={css.numbers}>
                            <div></div>
                            {
                                this.state.lineData.map(item => <div className={css.number}>{item.number}</div>)
                            }
                            <div></div>
                        </div>
                        <div className={css.line}>
                            <div className={css.dots}>
                                <div className={css.smallDot}></div>
                                {
                                    this.state.lineData.map(item => <div className={css.dotContainer}><div className={css.roundOutline} style={{border:"1px solid " + item.color}}></div><div className={css.bigDot} style={{backgroundColor:item.color}}></div></div>)
                                }
                                <div className={css.smallDot}></div>
                            </div>
                            <hr></hr>
                        </div>
                        <div className={css.legends}>
                            <div></div>
                            {
                                this.state.lineData.map(item => <div className={css.legend}>{item.text}</div>)
                            }
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BeaconCard;