import React, { useEffect, useState } from "react";

import axios from "axios";

// Components
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";

// Form
import Checkbox from "@material-ui/core/Checkbox";

// Tables
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// Custom Components
import WorkCharge from "../../components/workCharge";
import TKGMap from "../../components/map";
import Journey from "../../components/journey";
import Main from "../../components/Main";

// Styles
import { Container } from "./styles";

// Util
import {
  processSizes,
  processDistance,
  processTimes
} from "./utils";
import api from "../../../../core/services/api";

const token =
  "pk.eyJ1IjoiZ2FsaWxldXUiLCJhIjoiY2syNDBka3FyMDBhazNudGNrNzM2NWU3ZyJ9.hLvLb-t9Xc9h9C4vxDL8gw";

export default function () {
  const [tasks, setTasks] = useState({ loading: true, list: [] });
  const [tasksSelection, setTasksSelection] = useState([]);
  const [journey, setJourney] = useState(null);
  const [open, setModal] = useState(false);

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    const { data } = await api.get("/tasks");

    setTasks({
      loading: false,
      list: data,
    });
  };

  function addTask(task) {
    if (tasksSelection.indexOf(task) > -1) {
      let newSelection = tasksSelection;
      newSelection.splice(tasksSelection.indexOf(task), 1);
      setTasksSelection([...newSelection]);
    } else {
      let newSelection = tasksSelection;
      newSelection.push(task);
      setTasksSelection([...newSelection]);
    }

    console.log(tasksSelection);
    console.log(task);

    if (tasksSelection) {
      checkJourney();
    }
  }

  async function checkJourney() {
    try {
      const {
        data,
      } = await api.post(
        "/partnumbers/generate",
        { tasks: tasksSelection }
      );
      const { origin, destiny, end, total } = processDistance(data);
      const times = processTimes(data);
      const size = processSizes(data);
      setJourney({
        origin,
        destiny,
        end,
        total,
        size,
        times,
        static_routes: data.static_routes,
        dinamic_route: data.dinamic_route,
        markers: data.markers,
        parts_data: data.parts_data
      });
    } catch (error) {
      console.log(error);
    }
  }

  // Limpa a jornada atual
  function cleanJourney() {
    setTasksSelection([]);
    setJourney(null);
  }

  // Render Components
  const listTasks = tasks.list.map((task) => {
    return (
      <TableRow>
        <TableCell>
          <Typography>{task.mission_id}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{task.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{task.quantity}</Typography>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={tasksSelection.includes(task)}
            onChange={() => addTask(task)}
          />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Main>
    <Container>
      <Modal
        open={open}
        onClose={() => alert("Jornada Finalizada")}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {!open ? null : (
          <TKGMap setModal={setModal} open={open} journey={journey} />
        )}
      </Modal>
      <div style={{ width: "40%", height: "100%" }}>
        <Paper style={{ padding: 10, height: "100%", borderRadius: 0 }}>
          <img src="" />
          <WorkCharge />
          {tasks.loading ? (
            <Typography variant="h6"> Carregando Tasks ... </Typography>
          ) : (
            <TableContainer style={{ maxHeight: "70%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Task ID</TableCell>
                    <TableCell>Peça</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Selecionado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{listTasks}</TableBody>
              </Table>
            </TableContainer>
          )}
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              width: 250,
              margin: "auto",
              paddingTop: 20,
            }}
          >
            <Button
              onClick={() => cleanJourney()}
              variant="contained"
              color="secondary"
            >
              Limpar
            </Button>
            <Button
              onClick={() => setModal(!open)}
              variant="contained"
              color="primary"
            >
              Iniciar
            </Button>
          </div>
        </Paper>
      </div>
      <Journey journey={journey} />
    </Container>
    </Main>
  );
}
