const colors = {
  "almoxarifado": '#8480fc',
  "setor a": '#4fc2b7',
  "recebimento": '#1e87ff',
  "setor b": '#6ed829',
  "elétrica": '#ffae36',
  "ociosa": '#EC273B',
  "gestores": '#f65ccd'
  }
  
  export function setColor(place) {
    return colors[place.toLowerCase()]
  }
  
  
  
  // Gera Uma Posição Aleatória No Raio de x
  export function randomCoordinates({latitude, longitude}, distance) {
    const radius = distance / 113020
  
    const u = Math.random()
    const v = Math.random()
  
    const w = radius * Math.sqrt(u)
    const t = 2 * Math.PI * v
  
    const x = w * Math.cos(t);
    const y = w * Math.sin(t);
  
    let new_longitude = x / Math.cos(degrees2radians(latitude))
  
    let new_latitude = y / Math.cos(degrees2radians(longitude))
  
    new_latitude += latitude
    new_longitude += longitude
  
    return {
      latitude: new_latitude,
      longitude: new_longitude
    }
  }
  
  function degrees2radians(degrees) {
    const pi = Math.PI
    return degrees * (pi/180)
  }
  