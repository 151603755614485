import React, {useState} from 'react';
import Main from '../../components/Main'
import {useStyles} from './styles'


//chart
import options from './options'
import Chart from "react-apexcharts";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'


function StackedBar(){
    const classes = useStyles();

    const [state,setState] = useState({
        series: [{
          name: 'Almoxarifado',
          data: [31, 55, 41, 37]
        }, {
          name: 'Refeitório Interno',
          data: [53, 32, 33, 52]
        }, {
          name: 'Vivências',
          data: [12, 17, 11, 9]
        }, {
          name: 'Pipeshop interno',
          data: [9, 7, 5, 8]
        }, {
          name: 'Vestiário',
          data: [25, 12, 19, 20]
        }],
        local_id: null,
        local_list: [{id: 1, name: "Local A"}],
        shift_id: null,
        shift_list: [{id: 1, name: "Turno 1"}]
      
      
      });
    

    return (
    <Main color="#f7f7f7" title="Controle de Áreas">
        <Card className={classes.root}>
        <CardContent>
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Visão geral - Todas as Tags em 19/08/2019 19:09
        </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Select
          MenuProps={{ disableScrollLock: true }}
            fullWidth
            variant="outlined"
            displayEmpty
            value={state.local_id}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                local_id: e.target.value,
              }))
            } 
          >
            <MenuItem key="all" value={null}>
              Selecione uma Área
            </MenuItem>
            {state.local_list.map((item) => {return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            )})}
          </Select> 
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Select
          MenuProps={{ disableScrollLock: true }}
            fullWidth
            variant="outlined"
            displayEmpty
            value={state.shift_id}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                shift_id: e.target.value,
              }))
            } 
          >
            <MenuItem key="all" value={null}>
              Selecione um Turno
            </MenuItem>
            {state.shift_list.map((item) => {return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            )})}
          </Select> 
        </Grid>
        </Grid>
        
        <Chart options={options()} series={state.series} type="bar" height={350} />
        </CardContent>
            <CardActions></CardActions>
      </Card>
    </Main>
    )
}

export default StackedBar;