import React from 'react';

import Grid from '@material-ui/core/Grid';

import { setColor } from "./Utils";

import {OperationTitle, OperationBox, OperationDot} from './styles';

export default ({operation}) => {
    return(
        <Grid item xs={6}>
            <OperationBox>
                <OperationDot color={setColor(operation)} />
                <OperationTitle>{operation}</OperationTitle>
            </OperationBox>
        </Grid>
    )
};
