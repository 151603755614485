export default function(cols, idleness) {
    return {
        colors: ["#5ed237", '#e32c51'],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            position: 'top'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val+"%";
          },
          offsetY: 10,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        xaxis: {
          categories:['Jan 2020', 'Feb 2020', 'Mar 2020', 'Apr 2020'],
          
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val+"%";
            },
          },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent']
        },
      }
  }