import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, withStyles } from "@material-ui/core/styles";

export const ForksProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten("#2DE0C2", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#2DE0C2",
    },
  })(LinearProgress);
  
export const ClampsProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten("#26A0FC", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#26A0FC",
    },
  })(LinearProgress);


  export const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: '#fff0'
    },
    bar: {
     size: 10,
     backgroundColor: '#b53f3f',
    }
  })(LinearProgress);