import React from 'react'
import {useStyles} from './styles'


//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import CallMade from "@material-ui/icons/CallMade";
import CallReceived from "@material-ui/icons/CallReceived";
import Grid from '@material-ui/core/Grid'

//material icons
import PersonIcon from '@material-ui/icons/Person'
import LensIcon from '@material-ui/icons/Lens'

//others components
import { LoadingContainer, Title, Value, Prev, PrevValue, PrevLabel } from "./styles";


function Total({title, value, last_value}){
    const classes = useStyles();
    return(
        <Card className={classes.root}>
       
  
        <CardContent>
        <Grid container direction="column" justify="center" alignItems="center"> 
        <Grid item><PersonIcon style={{ color: "#5ed237", fontSize: 34 }} /></Grid>
        <Grid item><Value>123</Value></Grid>
        <Grid item><p>Total de colaboradores na planta</p></Grid>
        <Grid container direction="column" style={{marginBottom: '-12px'}}>
          <p style={{marginBottom: '-2px'}}><LensIcon style={{fontSize: 12, color: "#5ed237"}}/> 0 ativos hoje</p>
          <p style={{marginBottom: '-2px'}}><LensIcon style={{fontSize: 12, color: "#d2a237"}}/> 0 ativos entre 2 a 4 dias</p>
          <p style={{marginBottom: '-2px'}}><LensIcon style={{fontSize: 12, color: "#d23737"}}/> 30 ativos a mais de 5 dias</p>
        </Grid>
        </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    )
}


export default Total;