import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

export default function WhirpoolSelect(props) {
  return (
    <>
      <FormControl
        required
        style={{ width: "100%" }}
        error={props.error ? (props.value ? false : true) : false}
      >
        <InputLabel>{props.label}</InputLabel>
        <Select
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          fullWidth
          defaultValue={1}
        >
          {props.data.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </>
  );
}
