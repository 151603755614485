import React, { useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";

//@materialui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";

//datepicker
import { KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

function CreateForm(props) {
  const [state, setState] = React.useState({
    disabled: false,
  });
  const [work_shift, setWorkshift] = React.useState("");
  const [start_at, setStarttime] = React.useState("2018-01-01T00:00:00.000");
  const [end_at, setEndtime] = React.useState("2018-01-01T00:00:00.000");
  const [week_days, setWeekdays] = React.useState({
    seg: false,
    ter: false,
    qua: false,
    qui: false,
    sex: false,
    sab: false,
    dom: false,
  });

  //validação
  const [validateweek, setValidateweek] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // Main UseEffect
  useEffect(() => {
    if (open) {
      setValidateweek(validateWeeks());
    } else {
    }
  }, [week_days, state.errorMsg]);

  const handleClickOpen = () => {
    setState({
      operation_place_id: props.operation_place_id,
      error: false,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    const week = [];
    if (week_days.seg) {
      week.push(1);
    }
    if (week_days.ter) {
      week.push(2);
    }
    if (week_days.qua) {
      week.push(3);
    }
    if (week_days.qui) {
      week.push(4);
    }
    if (week_days.sex) {
      week.push(5);
    }
    if (week_days.sab) {
      week.push(6);
    }
    if (week_days.dom) {
      week.push(7);
    }

    const shifts = {
      work_shift: work_shift,
      start_at: moment(start_at).format("HH:mm:ss"),
      end_at: moment(end_at).format("HH:mm:ss"),
      week_days: week,
    };
    const obj = {
      operation_place_id: state.operation_place_id,
      active: active,
      resource: shifts,
    };

    if (!work_shift || !start_at || !end_at || !validateweek) {
      setState((prev) => ({
        ...prev,
        error: true,
      }));
    } else {
      const validation = await api.get(
        `operation-shifts-validation?operation_place_id=${state.operation_place_id}&start_at=${shifts.start_at}&end_at=${shifts.end_at}`
      );
      if (validation.data == true) {
        setState((prev) => ({
          ...prev,
          error: true,
          errorMsg: "Existe um turno dentro desse horário",
        }));
      } else {
        setState((prev) => ({
          ...prev,
          disabled: true,
        }));
        await api.post(`operation-shifts`, obj);
        //console.log("objeto a ser enviado", obj);
        props.getData();
        setOpen(false);
      }
    }
  }

  const handleChange = (event) => {
    setWeekdays((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };
  function validateWeeks() {
    for (var o in week_days) {
      if (week_days[o] == true) {
        return true;
      }
    }
    return false;
  }
  function validateStart() {
    if (start_at == "2018-01-01T00:00:00.000") {
      return false;
    }
  }
  return (
    <div>
      <Button style={{ backgroundColor: "#fff" }} onClick={handleClickOpen}>
        Adicionar Turno
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Criar novo turno {props.title}</DialogTitle>

        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              error={state.error ? (work_shift ? false : true) : false}
              id="work_shift"
              label="Turno"
              type="text"
              value={work_shift}
              onChange={(e) => setWorkshift(e.target.value)}
              fullWidth
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                error={
                  state.error
                    ? start_at == "2018-01-01T00:00:00.000"
                      ? true
                      : false
                    : false
                }
                fullWidth
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Horário de Início"
                value={start_at}
                onChange={setStarttime}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                error={
                  state.error
                    ? end_at == "2018-01-01T00:00:00.000"
                      ? true
                      : false
                    : false
                }
                fullWidth
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Horário de Término"
                value={end_at}
                onChange={setEndtime}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormLabel component="legend">Dias da semana</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Seg</span>}
                  checkedIcon={<span>Seg</span>}
                  color="primary"
                />
              }
              name="seg"
              checked={week_days.seg}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Ter</span>}
                  checkedIcon={<span>Ter</span>}
                  color="primary"
                />
              }
              name="ter"
              checked={week_days.ter}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Qua</span>}
                  checkedIcon={<span>Qua</span>}
                  color="primary"
                />
              }
              name="qua"
              checked={week_days.qua}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Qui</span>}
                  checkedIcon={<span>Qui</span>}
                  color="primary"
                />
              }
              name="qui"
              checked={week_days.qui}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Sex</span>}
                  checkedIcon={<span>Sex</span>}
                  color="primary"
                />
              }
              name="sex"
              checked={week_days.sex}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Sab</span>}
                  checkedIcon={<span>Sab</span>}
                  color="primary"
                />
              }
              name="sab"
              checked={week_days.sab}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Dom</span>}
                  checkedIcon={<span>Dom</span>}
                  color="primary"
                />
              }
              name="dom"
              checked={week_days.dom}
              onChange={handleChange}
            />
            <Typography
              variant="caption"
              style={{
                color: "red",
                display: state.error
                  ? validateWeeks()
                    ? "none"
                    : "block"
                  : "none",
              }}
              gutterBottom
            >
              Selecione pelo menos 1 dia
            </Typography>
            <Typography
              variant="caption"
              style={{
                color: "red",
                display: state.error
                  ? state.errorMsg
                    ? "block"
                    : "none"
                  : "none",
              }}
              gutterBottom
            >
              Já existe um turno dentro desse horário
            </Typography>
          </div>

          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
                name="active"
                color="primary"
              />
            }
            label={active ? "Ativo" : "Inativo"}
          />
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            variant="contained"
            onClick={handleSubmit}
            style={{
              backgroundColor: state.disabled ? "" : "#5ed237",
              color: state.disabled ? "" : "#fff",
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
