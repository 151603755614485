import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

export  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: 250,
      minWidth: 345,
      padding: 12,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));


  
export const LoadingContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export const Title = styled.p`
    color: #7C8796;
    font-size: 16px;
    text-align: center;
    margin: 0;
`

export const Value = styled.h1`
    color: #1D2329;
    font-size: 80px;
    font-weight: bolder;
`

export const Prev = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const PrevValue = styled.span`
    font-weight: bold;
    color: ${props => props.color};
`

export const PrevLabel = styled.span`
    padding: 0 10px;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    color: #7C8796;
`
