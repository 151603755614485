import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/Inbox'

//material-ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//custom components
import TableItem from './TableItem'

function ListItem(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <InboxIcon />
        </IconButton>
      <Dialog
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        <DialogTitle>Lista de Pacotes de IT {props.it}</DialogTitle>
        <TableItem package_list={props.package_list} packages={props.packages} />
        <DialogTitle>Justificativa de IT {props.it}</DialogTitle>
        {props.justification ? (
          <>
          <DialogContentText style={{paddingLeft: 24}}>
            {props.justification}
          </DialogContentText>
          </>
        ) : (
          <>
          <DialogContentText style={{paddingLeft: 24}}>
            Planejamento sem justificativa
          </DialogContentText>
          </>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ListItem;