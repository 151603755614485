import React from "react";

//@material-ui
import Grid from "@material-ui/core/Grid";

//custom components
import ProductsPerPause from "./ProductsPerPause";
import ProductsPerPauseReason from "./ProductsPerPauseReason";
import ProductsPerPauseOperator from "./ProductsPerPauseOperator";
import ProductsPerPauseForklift from "./ProductsPerPauseForklift";
import RankingReasonPause from "./RankingReasonPause";
import RankingReasonPauseParticipation from "./RankingReasonPauseParticipation";
import RankingReasonPauseParticipationPercent from "./RankingReasonPauseParticipationPercent";
import RankingReasonPauseParticipationPercentStacked from "./RankingReasonPauseParticipationPercentStacked";

function PausesHoursContent() {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <ProductsPerPause />
      </Grid>
      <Grid item>
        <ProductsPerPauseReason />
      </Grid>
      <Grid item>
        <ProductsPerPauseOperator />
      </Grid>
      <Grid item>
        <ProductsPerPauseForklift />
      </Grid>
      <Grid item>
        <RankingReasonPause />
      </Grid>
      <Grid item>
        <RankingReasonPauseParticipation />
      </Grid>
      <Grid item>
        <RankingReasonPauseParticipationPercent />
      </Grid>
      <Grid item>
        <RankingReasonPauseParticipationPercentStacked />
      </Grid>
    </Grid>
  );
}

export default PausesHoursContent;
