import React, { useEffect } from "react";
import api from "../../../../../core/services/api";
import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';

//custom components
import WhirpoolSelect from "../../../components/WhirpoolSelect";

function EditForm(props) {
  const [state, setState] = React.useState({
    menu_pages_id: [],
    menu_pages: props.menu_pages,
    error: false,
    disabled: false,
    oldGroup: []
  });
  const [name, setName] = React.useState("");
  const [menu_pages_id, setMenupages] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      menu_pages: props.menu_pages,
      oldGroup: props.oldGroup,
      name: props.oldGroup.name
    }));
    setName(props.oldGroup.name);
    setMenupages(props.oldGroup.menu_pages_id);


    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleSubmit() {
    if (!name || !menu_pages_id ) {
        setState((prev) => ({
          ...prev,
          error: true,
        }));
        
    } else {
        if(name == state.oldGroup.name && menu_pages_id == state.oldGroup.menu_pages_id){
            console.log('dados iguais, nao atualizar')
        } else {
            setState((prev) => ({
                ...prev,
            disabled: true,
            }));
            postGroup();
        }
    }
  }
  async function postGroup() {
    try {
      const newGroup = {
        id: state.oldGroup.id,
        name: name,
        menu_pages_id: menu_pages_id
      };
     //TROCAR PELA REQUISIÇÃO
      await api.put(`groups`, newGroup);
      setState((prev) => ({
        ...prev,
    disabled: false,
    }));
      setOpen(false);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }

  function getPagename(id){
    const name = state.menu_pages ? state.menu_pages.data.filter((page) => {return page.id === id}).map((page) => {return page.name}) : id;
    return name;
  }
  const handleDelete = (id) => () => {
    setMenupages((pages) => menu_pages_id.filter((page) => page !== id));
  };
  
  return (
    <div>
     <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#c38148" }} className="icon-small" />
      </IconButton>
     

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
         {state ? ( <>
        <DialogTitle>Editar grupo {state?.oldGroup?.name}</DialogTitle>

        <DialogContent>
         
          
              {" "}
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (name ? false : true) : false}
                  id="name"
                  name="name"
                  label="Nome do grupo"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                  <Grid container  alignItems="flex-end" justify="space-between" spacing={2}>
                      <Grid item xs={8}> <WhirpoolSelect
                  error={state.error}
                  label="Páginas"
                  value={state.menu_pages_id}
                  name="menu_pages_id"
                  data={state.menu_pages.data}
                  onChange={(event) => handleChange(event)}
                /></Grid>
                <Grid item>
                <Button
                 onClick={() => setMenupages([...menu_pages_id, state.menu_pages_id])}>Adicionar</Button>
                </Grid>
                  </Grid>
              </div>
<div style={{display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none'}}>
              {menu_pages_id.map((data) => {
        return (
          <li key={data}>
        
            <Chip
              label={state.menu_pages.data ? getPagename(data): ""}
              onDelete={handleDelete(data)}
            />
          </li>
        );
      })}</div>

           
        </DialogContent>
 </>
 ) : (
   ""
 )}
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
          disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
