import React from 'react'

//material ui
import Grid from '@material-ui/core/Grid'

//custom components
import LoginIcon from '../../../../assets/icons/login.svg';
import LogoutIcon from '../../../../assets/icons/logout2.svg';

function StatusCard(props){
    return (
        <>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3} style={{ borderRadius: 5, backgroundColor: props.status == 'in' ? '#e3f7e8' : '#ffe8e8bf', height: 'fit-content', minWidth: 350}}>
        <Grid item lg={2} md={2}>
            {props.status == 'in' ? (<img src={LoginIcon} width="20"  height="16" style={{marginTop: '-5px', filter: 'invert(66%) sepia(90%) saturate(387%) hue-rotate(54deg) brightness(90%) contrast(94%)'}} />) :
            (<img src={LogoutIcon} width="20"  height="16" style={{marginTop: '-5px', filter: 'invert(66%) sepia(90%) saturate(387%) hue-rotate(300deg) brightness(90%) contrast(94%)'}} />) }
        </Grid>
        <Grid container direction="column" justify="center" alignItems="stretch" lg={10} md={10} style={{marginTop: 10}}>
           <Grid item><p style={{fontSize: 12}}>{props.status == 'in' ? 'Entrada' : 'Saída'} - {props.name} - {props.place}</p></Grid>
           <Grid item><p style={{fontSize: 10, opacity: '0.5', marginTop: '-10px'}}>{props.time}</p></Grid>
        </Grid>
      </Grid>
      </>
    )
}

export default StatusCard