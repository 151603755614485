import React from "react";
import Grid from "@material-ui/core/Grid";
import MTable from "material-table";

import {ClampsProgress, ForksProgress, BorderLinearProgress} from './Utils';

export default ({data, title, idleness_goals}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p
            style={{
              width: "100%",
              color: "grey",
              textAlign: "left",
              margin: '0 0 0 10px',
            }}
          >
            {title}
          </p>
        </Grid>
        <Grid item xs={12}>
          <MTable
            columns={[
              {
                title: "Local Operação - Cadastro",
                field: "operation_place",
                sorting: false,
              },
              {
                title: "Garfos",
                field: "garfo",
                sorting: false,
                render: ({ garfo }) => {
                  return (
                    <>
                      <span>{parseFloat(garfo).toFixed(1)} %</span>
                      <ForksProgress variant="determinate" value={garfo} /><BorderLinearProgress value={5} variant="determinate" style={{marginLeft: idleness_goals+'%', bottom: 10}}/>
                    </>
                  );
                },
              },
              {
                title: "Clamps",
                field: "clamp",
                sorting: false,
                render: ({ clamp }) => {
                  return (
                    <>
                      <span>{parseFloat(clamp).toFixed(1)} %</span>
                      <ClampsProgress variant="determinate" value={clamp} /><BorderLinearProgress value={5} variant="determinate" style={{marginLeft: idleness_goals+'%', bottom: 10}}/>
                    </>
                  );
                },
              },
              {
                title: "Total",
                field: "total",
                sorting: false,
                render: ({total}) => (
                  <span>{total.toFixed(1)}%</span>
                ),
              },
            ]}
            data={data}
            options={{
              toolbar: false,
              showFirstLastPageButtons: false,
              pageSizeOptions: [],
              cellStyle: {
                padding: "10px",
                textAlign: "center",
              },
              rowStyle: (rowData, i) => {
                if (i % 2) {
                  return { backgroundColor: "#F3F5F866" };
                } else {
                  return { backgroundColor: "#F3F5F8" };
                }
              },
              headerStyle: {
                padding: "10px",
                fontWeight: "bold",
                color: "white",
                textAlign: "center",
                backgroundColor: "#C2C9D4",
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
