import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TrackageLogo from '../assets/logo_tkg.png';
import swal from 'sweetalert';
import { login } from '../config/auth';
import api from '../services/api';
import { LoginContainer, LoginBox, Logo, Input, Label, Button } from './styles';
import Axios from 'axios';

export default function ({props}) {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  async function efetuaLogin(event) {

    event.preventDefault();

    console.log(api)
    try {
      const request = await api.post('/auth', {email, password});
      if (request.data.message) {
        swal('Não Autorizado!', 'Usuário ou senha incorretos!', 'warning');
      } else {
      
        await login(request.data.token);

        localStorage.setItem('usuario', JSON.stringify(request.data));
        
        let home = request.data.menu.find(item => {
          if(item.resource.order == 1) return true;
        });
        history.push(home.resource.route);
        history.go();
      }
    } catch (err) {
      console.warn(err);
    }
  }

  return (
    <LoginContainer>
      <Logo src={TrackageLogo} alt='Trackage logo' />
      <LoginBox>
        <h2 style={{ marginBottom: 20 }}>Entrar</h2>
        <Label>E-mail</Label>
        <Input
          name='email'
          type='text'
          onChange={e => setEmail(e.target.value)}
        />
        <Label>Senha</Label>
        <Input
          name='password'
          type='password'
          onChange={e => setPassword(e.target.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={efetuaLogin.bind(this)}>ENTRAR</Button>
        </div>
      </LoginBox>
    </LoginContainer>
  );
}