import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, withStyles } from '@material-ui/core/styles';
import { red } from "@material-ui/core/colors";


export const BlockedProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: 'inherit',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
      },
})(LinearProgress);

export const SegregatedProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: 'inherit',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#24dff5',
      },
})(LinearProgress);

export const StructuredProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: 'inherit',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#e93093',
      },
})(LinearProgress);

export const TravelProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: 'inherit',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#fca626',
      },
})(LinearProgress);


