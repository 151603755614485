import React, {useState} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import { Typography } from '@material-ui/core';

//custom components
import ListItem from './ListItem'

// Utils
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";

  //material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';

function TablePackage(props){
const [state, setState] = useState({
  plannings: props.plannings
});
const [startDate, setStartDate] = useState(moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"));
const [endDate, setEndDate] = useState( moment(Date.now()).format("YYYY-MM-DD"));
const handleStartDate = (date) => {
    setStartDate(date);
  };
const handleEndDate = (date) => {
    setEndDate(date);
}




 return (
     <>
     <MaterialTable
    columns={[
      {
        title: "IT",
        field: "it",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Origem",
        field: "origin",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Fornecedor",
        field: "provider_id",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Situação",
        field: "send_receive",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => rowData.send_receive === 1 ? "Enviar" : "Receber"
      },
      {
        title: "Status",
        field: "status",
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => rowData.status === 'waiting' ? 'Aguardando' : (rowData.status === 'transit' ? 'Trânsito' : 'Entregue')
      },
      {
        title: "Detalhes",
        field: "situation",
        cellStyle: {padding: 0, paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => (
          <>
           <FormGroup row>
                 <ListItem conference={rowData.conference} receiving_conference={rowData.receiving_conference} justification={rowData.justification} it={rowData.it} package_list={rowData.package_list} package_list_confirmed={rowData.package_list_confirmed} packages={props.packages}/>
            </FormGroup>
          </>
        ),
      },
    ]}
    data={props.plannings}        
    options={{
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        padding: 10,
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: (props) => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    style={{
      borderRadius: 5,
    }}
  /></>
 )
}

export default TablePackage;