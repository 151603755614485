import React, { Component } from "react";
import { TopBar, Logo } from "./styles";
import Menu from "../Menu";
import logoTrackage from "../../assets/tkg_move.png";
import logoWhirlpool from "../../assets/logo-whirlpool.png";
import { Link, withRouter } from "react-router-dom";

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PersonIcon from '@material-ui/icons/Person'

// import { Container } from './styles';
const usuario = JSON.parse(localStorage.getItem("usuario"));

const logo =
  usuario && usuario.system_client_id == 5 ? logoWhirlpool : logoTrackage;

function Main({ children, color, title }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: "whitesmoke",
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Menu />
      <div style={{ width: "100%", marginLeft: "50px", background: color }}>
        <TopBar style={{ zIndex: 99999 }}>
          <Logo src={logo} />
          {title ? (
            <p
              style={{
                margin: 0,
                borderLeft: "0.5px solid #7d7d7dfc",
                paddingLeft: 20,
                marginLeft: 20,
                fontWeight: "initial",
                color: "#1b1919",
              }}
            >
              {title}
            </p>
          ) : null}
          <Tooltip title="Perfil">
          
          <IconButton color="primary" style={{position: 'absolute', right: 72}}>
          <Link to={"/profile"}>
          <PersonIcon />
          </Link>
          </IconButton>
         
          </Tooltip>
        </TopBar>
        <div style={{ margin: 20, paddingTop: 60, background: color }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Main;
