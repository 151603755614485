import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';

//custom components
import Table from "./Table";

function ProductsPerPauseOperator() {
  const classes = useStyles();
  const [state, setState] = useState({
    operations: [],
    operation_id: null,
    reasons: [],
    reason_id: null,
    operators: [],
    operator_id: null,
    forklifts: [],
    forklift_id: null,
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(1, "year").format("YYYY-MM-DD"),
    final_pauses: [],
  });

  useEffect(() => {
    getFilters();
    getData();
  }, [
    state.operation_id,
    state.reason_id,
    state.forklift_id,
    state.operator_id,
    state.endDate,
    state.startDate,
  ]);

  async function getFilters() {
    try {
      const operations = await api.get(`/operations-filter`);
      const reasons = await api.get(`/reasons-filter`);
      const operators = await api.get(`/operators-filter`);
      const forklifts = await api.get(`/forklifts-filter`);
      setState((prev) => ({
        ...prev,
        operations: operations.data || [],
        reasons: reasons.data || [],
        operators: operators.data || [],
        forklifts: forklifts.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getData() {
    try {
      let operations = "";
      let reasons = "";
      let operators = "";
      let forklifts_filter = "";

      if (state.operation_id != null)
        operations = `&operation_place=${state.operation_id}`;
      if (state.reason_id != null) reasons = `&reason=${state.reason_id}`;
      if (state.operator_id != null)
        operators = `&operator=${state.operator_id}`;
      if (state.forklift_id != null)
        forklifts_filter = `&forklift=${state.forklift_id}`;

      const data = await api.get(
        `/quantity-pauses-operator?start_day=${state.startDate}&end_day=${state.endDate}` +
          operations +
          reasons +
          operators +
          forklifts_filter
      );
      let forklifts = data.data;
      let final_pauses = [];
      forklifts.forEach((item, index) => {
        let exist = final_pauses.filter((item2) => item2.group == item.group);
        if (exist[0]) {
          let idx = final_pauses.indexOf(exist[0]);
          if (item.forklift_type == "Clamps") {
            final_pauses[idx].quantity_clamps = parseFloat(item.quantity);
            final_pauses[idx].percent_clamps = parseFloat(item.percent);
            final_pauses[idx].total_percent += parseFloat(item.percent);
          } else {
            final_pauses[idx].quantity_garfo = parseFloat(item.quantity);
            final_pauses[idx].percent_garfo = parseFloat(item.percent);
            final_pauses[idx].total_percent += parseFloat(item.percent);
          }
        } else {
          let obj = {
            group: item.group,
            quantity_clamps: 0,
            quantity_garfo: 0,
            percent_clamps: 0,
            percent_garfo: 0,
            total: parseFloat(item.total),
            total_percent: 0,
          };
          if (item.forklift_type == "Clamps") {
            obj.quantity_clamps = parseFloat(item.quantity);
            obj.percent_clamps = parseFloat(item.percent);
            obj.total_percent = parseFloat(item.percent);
          } else {
            obj.quantity_garfo = parseFloat(item.quantity);
            obj.percent_garfo = parseFloat(item.percent);
            obj.total_percent = parseFloat(item.percent);
          }
          final_pauses.push(obj);
        }
      });

      setState((prev) => ({
        ...prev,
        final_pauses: final_pauses,
      }));
    } catch (error) {
      console.warn(error);
    }
  }


  async function handleReportDownload(){
    try {
      let operations = "";
      let reasons = "";
      let operators = "";
      let forklifts_filter = "";
      let reportName = "quantityPausesOperator.xlsx";
      if (state.operation_id != null)
        operations = `&operation_place=${state.operation_id}`;
      if (state.reason_id != null) reasons = `&reason=${state.reason_id}`;
      if (state.operator_id != null)
        operators = `&operator=${state.operator_id}`;
      if (state.forklift_id != null)
        forklifts_filter = `&forklift=${state.forklift_id}`;

      let url = `/quantity-pauses-operator?report=true&start_day=${state.startDate}&end_day=${state.endDate}` +operations +reasons +operators +forklifts_filter;
      

      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url, { responseType: 'blob' }).then(blobFile => {
          const url = window.URL.createObjectURL(new Blob([blobFile.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${reportName}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        
      })

    } catch (error) {
      console.warn(error);
    }
  }


  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.operation_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  operation_id: e.target.value,
                }))
              }
            >
              <MenuItem key="all" value={null}>
                Operações
              </MenuItem>
              {state.operations.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.reason_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  reason_id: e.target.value,
                }))
              }
            >
              <MenuItem key="all" value={null}>
                Motivo Pausa
              </MenuItem>
              {state.reasons.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.reason}>
                    {item.reason}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.operator_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  operator_id: e.target.value,
                }))
              }
              //input={<BootstrapInput />}
            >
              <MenuItem key="all" value={null}>
                Operador
              </MenuItem>
              {state.operators.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Inicial"
                value={moment(state.startDate, "YYYY-MM-DD")}
                onChange={handleStartDate}
                KeyboardButtonProps={{
                  "aria-label": "Data Inicial",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                label="Data Final"
                value={moment(state.endDate, "YYYY-MM-DD")}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "Data Final",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}> 
          <Button fullWidth variant="outlined" size="large" onClick={() => handleReportDownload()} endIcon={<FilterListIcon />} style={{paddingTop: 14, paddingBottom: 14}}>
            Baixar
          </Button>
          </Grid>

          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Ranking Motivo Pausa: Geral Ano e Mês - Operador e Tipo Equipamento
          </Typography>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table final_pauses={state.final_pauses} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default ProductsPerPauseOperator;
