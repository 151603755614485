import styled from 'styled-components';
export const IconContainer = styled.div`
    color: white;
    margin: 0 auto;
    top: 0px;
    position: relative;
    width: 72px;
    height: 72px;
    border-radius: 100px;
    background: ${props => props.color ? props.color : 'lightgrey'};
    display: flex;
    justify-content: center;
    align-items: center;
`