import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Slider, Divider, ListItem, ListItemText } from "@material-ui/core";
import api from "../../../core/services/api";

export default function () {
  const [charge, setCharge] = useState(0); 
  
  useEffect(() => {
    getWorkCharge();
  }, [])

  const getWorkCharge = async () => {
    const {data} = await api.get('/work_charge');
    console.log(data);
    setCharge(data);
  }

  async function handleChange(e, value) {
    try {
      await api.put('/work_charge/update', {value: value});
      setCharge(() => value);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <ListItem>
        {/* <ListItemText secondary="Cadência do Processo" /> */}
        {/* <Slider
          id="work_charge"
          name="work_charge"
          min={1}
          max={68}
          value={charge ? charge : 0}
          onChange={handleChange}
          valueLabelDisplay="on"
        /> */}
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}
