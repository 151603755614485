import React, { useState, useEffect } from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";

// Components
import RankTable from "./RankTable";

import api from '../../../../../../core/services/api';

export default () => {
  const [state, setState] = useState({
    ranking: [],
    previousRanking: [],
    monthStart: moment().startOf('month').format("YYYY-MM-DD"),
    monthEnd: moment().format("YYYY-MM-DD"),
    yearStart: moment().startOf('year').format("YYYY-MM-DD"),
    yearEnd: moment().format('YYYY-MM-DD'),
  });

  async function checkLastRank(monthStart, monthEnd){
    let result = [];
    let aux = 0;
    let check = false
    while(check < 1) {
      const ms = moment(monthStart, "YYYY-MM-DD").subtract(aux, "months").format("YYYY-MM-DD");
      const me = moment(monthEnd, "YYYY-MM-DD").subtract(aux, "months").format("YYYY-MM-DD");
      const ranking = await api.get(
        `/forklift-idleness-operation-place-rank?start=${ms}&end=${me}`
      );
     result = ranking.data.data;
     aux += 1;   
     if(!result.length == 0){
       check =  check+1;
     }
    }
    return result;
  }

  async function getData() {
    const config = await api.get('/settings');
    const ranking = await checkLastRank(state.monthStart, state.monthEnd);
    const previousRanking = await api.get(
      `/forklift-idleness-operation-place-rank?start=${state.yearStart}&end=${state.yearEnd}`
    );

    setState((prev) => ({
      ...prev,
      ranking: ranking,
      previousRanking: previousRanking.data.data,
      idleness_goals: parseInt(config.data.resource.idleness_goals)-2 < 0 ? 0 : parseInt(config.data.resource.idleness_goals)-2
    }));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper style={{padding: 10}}>
      <Grid container spacing={5}>
        <Grid style={{padding: '20px 0 10px 0'}} item xs={12}>
          <h5
            style={{
              width: "100%",
              color: "grey",
              textAlign: "center",
              fontWeight: "bolder",
            }}
          >
            Ranking Ociosidade de Máquinas - Local de Operação
          </h5>
        </Grid>
        <Grid item xs={6}>
          <RankTable title="Ranking Ociosidade Mensal" idleness_goals={state.idleness_goals} data={state.ranking}/>
        </Grid>
        <Grid item xs={6}>
          <RankTable title="Ranking Ociosidade Anual" idleness_goals={state.idleness_goals} data={state.previousRanking}/>
        </Grid>
      </Grid>
    </Paper>
  );
};
