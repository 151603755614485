import React from 'react';
import {useStyles} from './styles'

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'

//custom components
import Table from './Table'

function RankingReasonPauseParticipation(){
    const classes = useStyles();
    return (
        <Card className={classes.root}>
        <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Ranking Motivo Pausa: Geral Ano e Mês - Motivo Pausa e Participação Tipo Equipamento
        </Typography>
        <Table />
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default RankingReasonPauseParticipation;

