import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import green from '@material-ui/core/colors/green';


//custom components
import SDSelect from "../../../../components/SDSelect";


function CreateForm(props) {

  const color = green[500]

  const [state, setState] = useState({
    active: true,
    error: false,
    disabled: false,
    module_id: [],
  })

  const [module_id, setModuleId] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      error: false,
      disabled: false,
      module_id: ''
    })
    setModuleId("");
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    postModulo();
    setOpen(false)
  }
  
  async function postModulo() {
    try {
      const modulo = {
        module_id: module_id,
        active: true,
      }
      console.log('criando modulo: ', modulo)
      await api.post(`modulo`, modulo);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
  }


  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm"  >
        <DialogTitle> Cadastar novo Módulo </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 12, marginTop: 12 }}>
            <TextField
              id="module_id"
              label="ID do Modulo"
              type="text"
              fullWidth
              value={module_id}
              onChange={(e) => module_id.length < 12 ? setModuleId(e.target.value) : setModuleId(module_id.slice(0, -1))}
            />{" "}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default CreateForm;
