import React, { useEffect } from "react";
import api from "../../../../../core/services/api";
import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

//custom components
import WhirpoolSelect from "../../../components/WhirpoolSelect";

function CreateForm(props) {
  const [state, setState] = React.useState({
    username: "",
    email: "",
    group_id: "",
    groups: props.groups,
    system_client_id: "",
    error: false,
    disabled: false,
  });
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [group_id, setGroupid] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      groups: props.groups
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setPassword2("");
    setState("");
    setOpen(false);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleSubmit() {
    if (!email || !username || !state.group_id || !password || !password2) {
        setState((prev) => ({
          ...prev,
          error: true,
        }));
        
    } else {
          if(password != password2){
            setState((prev) => ({
              ...prev,
              error: true,
            }));
          } else {
            setState((prev) => ({
              ...prev,
            disabled: true,
            }));
            postUser();
          }
         
    }
  }
  async function postUser() {
    try {
      const newUser = {
        username: username.toLowerCase(),
        email: email.toLowerCase(),
        group_id: state.group_id,
        password: password,
        system_client_id: 5
      };
     //TROCAR PELA REQUISIÇÃO
      await api.post(`users`, newUser);
      setState("");
      setUsername("");
      setEmail("");
      setGroupid("");
      setPassword("");
      setOpen(false);
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }
  
  return (
    <div>
      <Fab
        color="primary"
        style={{ zIndex: 9999, position: "fixed", bottom: "10%", right: "1%" }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Cadastar novo usuário</DialogTitle>

        <DialogContent>
          {state ? (
            <>
              {" "}
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (username ? false : true) : false}
                  id="username"
                  name="username"
                  label="Usuário"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (email ? false : true) : false}
                  required
                  id="email"
                  label="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (password ? false : true) : false}
                  required
                  id="password"
                  label="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  error={state.error ? (password2 == password  ? false : true) : false}
                  required
                  id="password2"
                  label="Confirme sua senha"
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <WhirpoolSelect
                  error={state.error}
                  label="Grupo"
                  value={state.group_id}
                  name="group_id"
                  data={state.groups.data}
                  onChange={(event) => handleChange(event)}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
          disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
