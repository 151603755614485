import React from "react";

import { PopupContainer, PopupTitle, PopupValue } from "./styles";

export default ({ title, value }) => (
    <PopupContainer>
        <PopupTitle>{title}</PopupTitle>
        <PopupValue>{value}</PopupValue>
    </PopupContainer>
)
