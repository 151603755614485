import React from 'react';

//Custom components
import Main from '../../components/Main';
import TablePallets from './TablePallets';

function SanofiPallets(){
    return (
        <Main title="Sanofi - Inventário">
            <TablePallets />
        </Main>  
    )
}

export default SanofiPallets;