import './Card.css'
import Share from './truck.svg'
import React, { useState } from 'react'

function CardInfo(props) {
    const [state, setState] = useState({
        items: []
    })
    return (
        <div className='Card Info'>
            <div className='CardHeader'>
                <p>{ props.title }</p>
                <div className='ImageCropper'>
                    <img src={Share} />
                </div>
            </div>
            <p className='Number'>{ props.entity.value }<span>{ props.entity.unit ? props.entity.unit : ""}</span></p>
        </div>
    )
}

export default CardInfo