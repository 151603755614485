import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";
import { Content } from './styles'
import './styles.css'
import { makeStyles } from "@material-ui/core/styles";
import Main from '../../../components/Main'
import moment from 'moment'


//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
import MapModal from "./MapModal";
// import Search from  '../../../components/Search'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import SelectDate from "../../../components/SelectDate"
import SearchkBox from "../../../components/SearchBox";
import Dropdown from "../../../components/SelectDropdown";
import SelectDropdownMaterial from "../../../components/SelectDropdownMaterial";

//@material-ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FormGroup from "@material-ui/core/FormGroup";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ConsumoFitaDash from '../../../components/ConsumoFitaDash/index'

function TableMachines() {

  const [state, setState] = useState({
    machines: [],
    modulos: [],
    clients: [],
    error: false,
    disabled: false,
    cod_client: [],
    active: true,
    used_tape: [],
    //endDate: moment(Date.now()).format("DD-MM-YYYY"),
    //startDate: moment(Date.now()).subtract(5, "d").format("DD-MM-YYYY"),
  })

  useEffect(() => {
    getData();
    //getTelemetries();
  }, [])

  async function getData() {
    try {
      const { data } = await api.get(`machines`);

      setState((prev) => ({
        ...prev,
        machines: data || [],
        filteredMachines: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getTelemetries() {
    try {
      const { data } = await api.get(`telemetries`);
      setState((prev) => ({
        ...prev,
        used_tape: data || []
      }))
    } catch (error) {
      console.warn(error);
    }
  }
  console.log('state.used_tape')
  console.log(state.used_tape)

  async function deleteMachine(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar a Máquina ? " + item.resource.id_machine
        )
      ) {
        await api.delete(`machines/` + item.id);
      } else {
      }
      getData();
    } catch (error) {
      console.warn(error);
    }
  }


  function getName(id, type) {
    if (type === "func") {
      const name = state.modulos?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    } else if (type == "local") {
      const name = state.clients?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    }
  }


  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  const classes = useStyles();
  const [direction, setDirection] = useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  function handleSearch(value) {
    const filter = state.machines.filter((item) => {
      return item.resource.id_machine.toLowerCase().includes(value.toLowerCase()) ||
        item.resource.cod_client.toLowerCase().includes(value.toLowerCase())[0]?.id
    })
    setState((prev) => ({
      ...prev,
      filteredMachines: filter
    }))
  }

  return (
    <Main color="#f7f7f7" title="Máquinas">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }} >
        <Grid item xs={12}>
          <Grid item>
            <TextField id="outlined-basic" label="Busca" variant="outlined" InputProps={{
              endAdornment: (
                <InputAdornment spacing={3} style={{ paddingBottom: 12 }}>
                  <SearchIcon />

                </InputAdornment>
              ),
            }}
              onChange={(e) => handleSearch(e.target.value)}
            />

          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <ConsumoFitaDash />
        </Grid> */}

        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} modulos={state.modulos} clients={state.clients} />}
              tooltipTitle="Adicionar Maquinas"
              onClick={() => handleOpen()}
            />

            {/* <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
            /> */}
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
        {/* <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> */}

      </Grid>
      <MaterialTable
        columns={[
          {
            title: "ID Maquina",
            field: "resource.id_machine",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Cod. Cliente",
            field: "resource.cod_client",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            /* render: (rowData) => <div style={{ display: 'flex', justifyContent: 'center',
             flexWrap: 'wrap', listStyle: 'none' }}>{getModulos(rowData.modulos)}</div> */
          },
          {
            title: "Tempo de Funcionamento",
            field: "resource.running_time",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Quantidade Fita Estipulada (m²)",
            field: "resource.amount_of_tape",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Quantidade Fita Utilizada",
            field: "used_tape",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            /* render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <TextField telemetries={getTelemetries}/>
                </FormGroup>
              </>
            ),
             */
          },
          {
            title: "Ver no mapa",
            field: "map",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <MapModal />
                </FormGroup>
              </>
            ),
          },

          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm machines={rowData} getData={getData} modulos={state.modulos} clients={state.clients} />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteMachine(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.filteredMachines}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  );
}

export default TableMachines;
