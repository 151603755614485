import React, { useEffect } from "react";
import api from "../../../../../../core/services/api";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

//custom components
import WhirpoolSelect from "../../../../components/WhirpoolSelect";

function EditForm(props) {
  const [state, setState] = React.useState("");
  const [forklift_number, setNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [macaddress, setMacaddress] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setState({
      forklift: props.forklift,
    forklift_type_id: props.forklift_type_id,
    forklift_types: props.forklift_types,
    operation_place_id: props.operation_place_id,
    forklift_operations: props.forklift_operations,
    name: props.tag,
    macaddress: props.macaddress,
    active: props.active,
    forklift_number: props.numero,
    system_client_id: props.system_client_id,
    disabled: false,
    });
    setNumber(props.numero);
    setName(props.tag);
    setMacaddress(props.macaddress);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  async function updateForklift() {
    try {
      const oldForklift = {
        name: state.forklift.name,
        macaddress: state.forklift.macaddress,
        system_client_id: state.forklift.system_client_id,
        forklift_number:state.forklift.forklift_number,
        operation_place_id: state.forklift.operation_place_id,
        active: state.forklift.active,
        forklift_type_id: state.forklift.forklift_type_id
      }
      const newForklift = {
        name: name,
        macaddress: macaddress,
        system_client_id: state.system_client_id,
        forklift_number: forklift_number,
        operation_place_id: state.operation_place_id,
        active: state.active,
        forklift_type_id: state.forklift_type_id
      };
      
      if (newForklift.name == state.forklift.name && newForklift.macaddress == state.forklift.macaddress && newForklift.system_client_id == state.forklift.system_client_id && newForklift.forklift_number == state.forklift.forklift_number && newForklift.operation_place_id == state.forklift.operation_place_id && newForklift.active == state.forklift.active && newForklift.forklift_type_id == state.forklift.forklift_type_id){
        console.log("dados iguais, não atualizar")
      } else {
        setState((prev) => ({
          ...prev,
         disabled: true,
        }));
       await api.put(`forklift-update/`+state.forklift.id, newForklift);
        props.getData();
      }
      setState("")
      setOpen(false);
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#c38148" }} className="icon-small" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Editar Máquina {props.numero}</DialogTitle>
        {state ? (  <DialogContent>
          <div style={{marginTop: 12, marginBottom: 12}}>

          <TextField
            disabled
            id="forklift_number"
            label="Número"
            type="text"
            defaultValue={state.forklift_number}
            value={forklift_number}
            onChange={e => setNumber(e.target.value)}
            fullWidth
            
          /> </div>
          <div style={{marginTop: 12, marginBottom: 12}}>
          <WhirpoolSelect
            label="Tipo"
            value={state.forklift_type_id}
            name="forklift_type_id"
            data={state.forklift_types}
            onChange={(event) => handleChange(event)}
          /></div>
          <div style={{marginTop: 12, marginBottom: 12}}>
          <WhirpoolSelect
            label="Local Operação"
            value={state.operation_place_id}
            name="operation_place_id"
            data={state.forklift_operations}
            onChange={(event) => handleChange(event)}
          /></div>
          <div style={{marginTop: 12, marginBottom: 12}}>
          <TextField
            id="name"
            label="Tag"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          /></div> 
          <div style={{marginTop: 12, marginBottom: 12}}>

          <TextField
            id="macaddress"
            label="Mac Address"
            type="text"
            value={macaddress}
            onChange={e => setMacaddress(e.target.value)}
            fullWidth
          /> </div>
         <FormControlLabel
        control={
          <Switch
            checked={state.active}
            onChange={(event) => handleChange(event)}
            name="active"
            color="primary"
          />
        }
        label={state.active ? "Ativo" : "Inativo"}
      />
        </DialogContent>) : ""}
      
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={updateForklift}
            variant="contained"
            style={{backgroundColor: state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff"}}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
