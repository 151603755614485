export default function (legends) {
  return {
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      stackType: "100%",
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },

    xaxis: {
      categories: legends,
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
  };
}
