const colors = {
  "picking": '#8480FC',
  "reversa": '#4FC2B7',
  "recebimento": '#1E87FF',
  "backup": '#6DD827',
  "otimização": '#FFAE36',
  "ociosa": '#EC273B',
  "reoperação": '#F65CCD',
  "linha híbrida": '#1e87ff99',
  "prada": '#4fc2b799',
  "área acerto": '#ffce86',
  "rec. fii": '#fa9ee1',
  "rec. fiii": '#c5efa9',
  "rec. fh": '#afd198',
  "reprocesso": '#cee8a1',
  "reoperação": '#d3dc99',
  "otimização": '#d7ea58',
  "porta pallet": '#fba2a2'
}

export function setColor(place) {
  return colors[place.toLowerCase()]
}

// Count Forklifts
export function countForklifts(forklifts) {
  const status = countActives(forklifts);
  const types = countTypes(forklifts);

  return {...status, ...types};
}

// Contabiliza Tipos de Empilhadeiras
function countTypes(forklifts) {
  const forks = forklifts.filter((item) => {
    if (item.type == "Garfo") return item;
  }).length;

  const clamps = forklifts.length - forks;

  return { forks, clamps };
}

// Contabiliza Ativos e Inativos
function countActives(forklifts) {
  const actives = forklifts.filter((item) => {
    if (item.active) return item;
  }).length;

  const total = forklifts.length;
  const inactives = total - actives;

  return { total, actives, inactives };
}

// Gera Uma Posição Aleatória No Raio de x
export function randomCoordinates({latitude, longitude}, distance) {
  const radius = distance / 113020

  const u = Math.random()
  const v = Math.random()

  const w = radius * Math.sqrt(u)
  const t = 2 * Math.PI * v

  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  let new_longitude = x / Math.cos(degrees2radians(latitude))

  let new_latitude = y / Math.cos(degrees2radians(longitude))

  new_latitude += latitude
  new_longitude += longitude

  return {
    latitude: new_latitude,
    longitude: new_longitude
  }
}

function degrees2radians(degrees) {
  const pi = Math.PI
  return degrees * (pi/180)
}
