import React, { Component } from "react";
// import ReactDom from "react-dom";
import  MapGL, {Marker} from "react-map-gl";

import {Icon} from './styles'


import markerPin from '../../assets/icons/markerPin.svg'
import locationRed from '../../assets/icons/locationRed.svg'
// locationRed


const MAPBOX_TOKEN =
  "pk.eyJ1IjoidWJlcmRhdGEiLCJhIjoiY2pwY3owbGFxMDVwNTNxcXdwMms2OWtzbiJ9.1PPVl0VLUQgqrosrI2nUhg";


const DEFAULT_VIEWPORT = {
  // "width":'80%',
  // "height":'80',
  "latitude":-23.180167258984458,
  "longitude":-47.695080810545555,
  "zoom":6,
};

const CITIES = [
  {lat:-23.180167258984458, long:-47.695080810545555, cerca:false},
  {lat:-22.180167258984458, long:-46.695080810545555, cerca:true},
  {lat:-21.180167258984458, long:-46.695080810545555, cerca:false},
  {lat:-20.180167258984458, long:-46.695080810545555, cerca:true},
]


function Markers(props) {

  const data = props.data

  return(
    data.map((local, index) => 
    <Marker  key={index} captureDoubleClick={false} longitude={local.long} latitude={local.lat} >
      <Icon src={markerPin} style={{width: 30, height: 30}} cerca={local.cerca} />
    </Marker>)  
  )
}


class MapLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: DEFAULT_VIEWPORT,
      mode: null,
      selectedFeatureIndex: null,
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

   

  render() {
    const { viewport, mode } = this.state;
    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={"mapbox://styles/mapbox/light-v10"}
        onViewportChange={this._updateViewport}
      >
        <Markers onCLick={this._updateViewport} data={CITIES} />
      </MapGL>
    );
  }
}

export default MapLocations

