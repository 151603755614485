import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";
import { Content } from './styles'

//custom components
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
import MapModal from "../../../components/MapModal";

// import SearchkBox from "../../../components/SearchInput";
import SearchkBox from "../../../components/SearchBox";
import SelectDate from "../../../components/SelectDate"
import Main from '../../../components/Main'
import Alert from '../../../components/Alert'

//@material-ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from "@material-ui/core/Button";
import RoomIcon from "@material-ui/icons/Room";
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField'



function TableClients() {

  const [state, setState] = useState({
    clients: [],
    machines:[],
    error: false,
    disabled: false,
    active: true,
  })

  useEffect(() => {
    getData();
    getMachines();
   // getType700();
  }, [])

  async function getData() {
    try {
      const { data } = await api.get(`clients`);
      setState((prev) => ({
        ...prev,
        clients: data || [],
        filteredClients: data || []
      }))
      console.log("data")
      console.log(data)
    } catch (error) {
      console.warn(error);
      
    }
  }

  async function getMachines() {
    try {
      const { data } = await api.get(`machines`);

      setState((prev) => ({
        ...prev,
        machines: data || [],
        filteredMachines: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

 /*  async function getType700() {
    try {
      const { data } = await api.get(`type700`);
      setState((prev) => ({
        ...prev,
        type700: data || [],
      }));
    }catch (error) {
      console.warn(error);
    }
  } */


  async function deleteClient(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o Cliente? " + item.name
        )
      ) {
        await api.delete(`clients/` + item.id);
      } else {
      }
      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  function getName(id, type) {
    if (type === "func") {
      const name = state.machines?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    } else if (type == "local") {
      const name = state.clients?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    }
  }


  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );


  const classes = useStyles();
  const [direction, setDirection] = useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleSearch(value) {
    const filter = state.clients.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.cod_client.toLowerCase().includes(value.toLowerCase()) ||
        item.city.toLowerCase().includes(value.toLowerCase())[0]?.id
    })
    setState((prev) => ({
      ...prev,
      filteredClients: filter
    }))
  }


  return (
    <Main color="#f7f7f7" title="Clientes">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
          <TextField id="outlined-basic" label="Busca" variant="outlined" InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Grid>
        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} machines={state.machines} />}
              tooltipTitle="Adicionar Cliente"
              onClick={() => handleOpen()}

            />
            {/*   <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
            /> */}
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
        {/*  <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> */}

      </Grid>
      <MaterialTable
        columns={[
          {
            title: "Cliente",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Cod. Cliente",
            field: "cod_client",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            //puxar quantidade de máquinas desse modelo o cliente possui
            title: "Mod. 700A",
            field: "quantity_700",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            /* render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'center' }}>
                   <DoneOutlinedIcon clients={rowData} get={getData} get={getType700} />
                  <div>
                  <TextField
                  value={state.type700}
                  name="type700"
                  data={getType700}
                  />
                  </div>
                </FormGroup>
              </>
            ), */
          },
          {
            //puxar quantidade de máquinas desse modelo o cliente possui
            title: "Mod. 800A",
            field: "quantity_800",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            /* render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'center' }}>
                  <DoneOutlinedIcon clients={rowData} getData={getData.machine_type} />
                </FormGroup>
              </>
            ), */

          },
          {
            //mostrar quantidade total de máquinas ativas no cliente
            title: "Ativo",
            field: "quantity_active",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            /* render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'center' }}>
                  <DoneOutlinedIcon clients={rowData} getData={getData.machine_type} />
                </FormGroup>
              </>
            ), */
          },
          {
            title: "Cidade",
            field: "city",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          //<MapModal
          //id={props.vehicle_id}
          // board={props.board}
          // latitude={props.latitude}
          // longitude={props.longitude}
          // />,
          {
            title: "Ver no mapa",
            field: "map",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <MapModal />
                </FormGroup>
              </>
            ),
          },

          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm clients={rowData} getData={getData}  />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteClient(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.filteredClients}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  );
}

export default TableClients;
