import React, {useState} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import options from "./options";
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//custom components
import {BlockedProgress, SegregatedProgress, StructuredProgress, TravelProgress} from './Progress'
import {DivWrapper, DivHeader, ContainerIMG, Placeholder} from './styles'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


function Table(){
    const [state, setState] = useState({          
        series: [{
          name: 'Dentro da Cerca',
          data: [31, 55, 41, 52]
        },
        {
          name: 'Fora da Cerca',
          data: [31, 55, 41, 52]
        }],        
          values: [],
          values2: [],
          cols: [],
          periods_id: null,
          operations_id: null,
          machines_id: null,
          endDate: moment(Date.now()).format("YYYY-MM-DD"),
          startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
        })
        const handleStartDate = (date) => {
          setState((prev) => ({
            ...prev,
            startDate: moment(date).format("YYYY-MM-DD"),
          }));
        };
        const handleEndDate = (date) => {
          setState((prev) => ({
            ...prev,
            endDate: moment(date).format("YYYY-MM-DD"),
          }));
        };
        const clients = [
          {id: 1, name: "Cliente A"},
          {id: 2, name: "Cliente B"}
        ]
        const machines = [
          {id: 1, name: "154"},
          {id: 2, name: "245"},
          {id: 3, name: "333"}
        ]
        const machine_type = [
          {id: 1, name: "Tipo Máquina 1"},
          {id: 2, name: "Tipo Máquia 2"},
          {id: 3, name: "Tipo 3"},
        ]
        const periods = [
          {id: 1, name: "Periodo A"},
          {id: 2, name: "Periodo B"},
          {id: 3, name: "Periodo C"}
        ]
  
    return (
      <Grid container  justify="center" alignItems="center" spacing={3}>
       
       <Grid item lg={3} md={3} sm={3} xs={3}>
       
       <Select
       MenuProps={{ disableScrollLock: true }}
        fullWidth
        variant="outlined"
         displayEmpty
         value={state.operations_id}
         onChange={(e) =>
           setState((prev) => ({
             ...prev,
             operations_id: e.target.value,
           }))
         }
         //input={<BootstrapInput />}
         
        
       >
         <MenuItem key="all" value={null}>
           Selecione um período
         </MenuItem>
         {periods.map((item) => {return (
           <MenuItem key={item.id} value={item.id}>
             {item.name}
           </MenuItem>
         )})}
       </Select>
    
</Grid>
  
        <Grid item lg={3} md={3} sm={3} xs={3}>
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  } 
                >
                  <MenuItem key="all" value={null}>
                    Número Máquina
                  </MenuItem>
                  {machines.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Tipo Máquina
                  </MenuItem>
                  {machine_type.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
       
       <Select
       MenuProps={{ disableScrollLock: true }}
        fullWidth
        variant="outlined"
         displayEmpty
         value={state.operations_id}
         onChange={(e) =>
           setState((prev) => ({
             ...prev,
             operations_id: e.target.value,
           }))
         }
         //input={<BootstrapInput />}
         
        
       >
         <MenuItem key="all" value={null}>
           Cliente
         </MenuItem>
         {clients.map((item) => {return (
           <MenuItem key={item.id} value={item.id}>
             {item.name}
           </MenuItem>
         )})}
       </Select>
    
</Grid>           

        
        
        <Grid item lg={12} sm={12} md={12}> 
        <Chart
              options={options(state.cols, state.values2[0])}
              series={state.series}
              type="bar"
              height={350}
            />
        </Grid>
      </Grid>
       
    )
}

export default Table;