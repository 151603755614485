import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import box from '../../../../../assets/icons/delivery-box.svg';


//@material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Divider from "@material-ui/core/Divider";
import {IconContainer} from './styles';


//@material ui 
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


//@material icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const useStyles = makeStyles({
  root: {
    minWidth: 1080,
    padding: 0,
    
  },
});


function TimeAverageCard(){
    const classes = useStyles();
    const [state, setState] = useState({
        periods_id: null,
        operations_id: null,
        machines_id: null,
        endDate: moment(Date.now()).format("YYYY-MM-DD"),
        startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
      })
      const handleStartDate = (date) => {
        setState((prev) => ({
          ...prev,
          startDate: moment(date).format("YYYY-MM-DD"),
        }));
      };
      const handleEndDate = (date) => {
        setState((prev) => ({
          ...prev,
          endDate: moment(date).format("YYYY-MM-DD"),
        }));
      };
        
      const operations = [
        {id: 1, name: "Operations A"},
        {id: 2, name: "Operations B"}
      ]
      const forkliftslist = [
        {id: 1, name: "Empilhadeira 1"},
        {id: 2, name: "Empilhadeira 2"},
        {id: 3, name: "Empilhadeira 3"},
      ]
      const periods = [
        {id: 1, name: "Periodo A"},
        {id: 2, name: "Periodo B"},
        {id: 3, name: "Periodo C"}
      ]
    
    return (
        <Grid container  justify="center" alignItems="center" spacing={3}>
         <Grid item lg={2} md={2} sm={2} xs={2}>
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Operações
                  </MenuItem>
                  {operations.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Empilhadeiras
                  </MenuItem>
                  {forkliftslist.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}              
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(state.startDate, "YYYY-MM-DD")}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
            
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}              
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Inicial"
              value={moment(state.endDate, "YYYY-MM-DD")}
              onChange={handleEndDate}
              KeyboardButtonProps={{
                "aria-label": "Data Inicial",
              }}
            />
          </MuiPickersUtilsProvider>
             
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
       
                <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.operations_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      operations_id: e.target.value,
                    }))
                  }
                  //input={<BootstrapInput />}
                  
                 
                >
                  <MenuItem key="all" value={null}>
                    Período
                  </MenuItem>
                  {periods.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )})}
                </Select>
             
        </Grid>
        
        
        <Grid item lg={12} sm={12} md={12}> 
        <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{marginBottom: '-24px'}}>
                <Grid item>
                    <IconContainer color="#7D77FF">
                    <img width="40" height="auto" src={box} style={{filter: 'invert(99%) sepia(9%) saturate(126%) hue-rotate(184deg) brightness(117%) contrast(100%)'}} alt=""/> 
                    </IconContainer>
                </Grid>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Total Quantidade de Produtos</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>8,333,910</p></Grid>
                    <Grid item><Typography color="textSecondary" gutterBottom>Total Quantidade de Produtos</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>100%</p></Grid>
                </Grid>
                <Grid item container direction="column" justify="center" lg={1} sm={1} md={1}>
                    <Grid item><KeyboardArrowRightIcon style={{fontSize: 80, opacity: '0.6'}} /></Grid>
                </Grid>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Total Produtos Blocado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>6,396,156</p></Grid>
                    <Grid item><Typography color="textSecondary" gutterBottom>% Qtd Produtos Blocado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>75,77%</p></Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginBottom: 20}}/>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Total Produtos Segregado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>1,557,504</p></Grid>
                    <Grid item><Typography color="textSecondary" gutterBottom>% Qtd Produtos Segregado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>12,11%</p></Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginBottom: 20}}/>
                <Grid item container direction="column" justify="center" lg={2} sm={2} md={2}>
                    <Grid item><Typography color="textSecondary" gutterBottom>Total Produtos Estruturado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>360,250</p></Grid>
                    <Grid item><Typography color="textSecondary" gutterBottom>% Qtd Produtos Estruturado</Typography></Grid>
                    <Grid item><p style={{fontSize: 60, fontWeight: 'bolder'}}>14,56%</p></Grid>
                </Grid>
                 
          </Grid>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
    )
}

export default TimeAverageCard;