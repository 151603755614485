import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';


//custom components
import Table from "./Table";

function RankingReasonPause() {
  const classes = useStyles();
  const [state, setState] = useState({
    rankMonth: [],
    rankYear: [],
    operations: [],
    operation_id: null,
    reasons: [],
    reason_id: null,
    operators: [],
    operator_id: null,
    forklifts: [],
    forklift_id: null,
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getFilters();
    getData();
  }, [
    state.operation_id,
    state.reason_id,
    state.forklift_id,
    state.operator_id,
    state.endDate,
    state.startDate,
  ]);

  async function getFilters() {
    try {
      const operations = await api.get(`/operations-filter`);
      const reasons = await api.get(`/reasons-filter`);
      const operators = await api.get(`/operators-filter`);
      const forklifts = await api.get(`/forklifts-filter`);
      setState((prev) => ({
        ...prev,
        operations: operations.data || [],
        reasons: reasons.data || [],
        operators: operators.data || [],
        forklifts: forklifts.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      let operations = "";
      let reasons = "";
      let operators = "";
      let forklifts_filter = "";

      if (state.operation_id != null)
        operations = `&operation_place=${state.operation_id}`;
      if (state.reason_id != null) reasons = `&reason=${state.reason_id}`;
      if (state.operator_id != null)
        operators = `&operator=${state.operator_id}`;
      if (state.forklift_id != null)
        forklifts_filter = `&forklift=${state.forklift_id}`;

      const dataMonth = await api.get(
        `/quantity-pauses-forklift-type?group='mes'` +
          operations +
          reasons +
          operators +
          forklifts_filter
      );
      const dataYear = await api.get(
        `/quantity-pauses-forklift-type?group='ano'` +
          operations +
          reasons +
          operators +
          forklifts_filter
      );
      setState((prev) => ({
        ...prev,
        rankMonth: dataMonth.data,
        rankYear: dataYear.data,
      }));
    } catch (error) {
      console.warn(error);
    }
  }


  
  async function handleReportDownload(){
    try {
      let reportName1 = "quantityPausesForkliftMonth.xlsx";
      let reportName2 = "quantityPausesForkliftYear.xlsx";

      let url1 = `/quantity-pauses-forklift-type?group='mes'&report=true`;
      let url2 = `/quantity-pauses-forklift-type?group='ano'&report=true`;
      

      api.get(url1, { responseType: 'blob' }).then(blobFile => {
          const url = window.URL.createObjectURL(new Blob([blobFile.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${reportName1}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        
      })

      api.get(url2, { responseType: 'blob' }).then(blobFile => {
        const url = window.URL.createObjectURL(new Blob([blobFile.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reportName2}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      
    })

    } catch (error) {
      console.warn(error);
    }
  }

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Ranking Motivo Pausa: Geral Ano e Mês - Número Máquina e Tipo
          Equipamento
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.operation_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  operation_id: e.target.value,
                }))
              }
            >
              <MenuItem key="all" value={null}>
                Operações
              </MenuItem>
              {state.operations.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.reason_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  reason_id: e.target.value,
                }))
              }
            >
              <MenuItem key="all" value={null}>
                Motivo Pausa
              </MenuItem>
              {state.reasons.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.reason}>
                    {item.reason}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.operator_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  operator_id: e.target.value,
                }))
              }
              //input={<BootstrapInput />}
            >
              <MenuItem key="all" value={null}>
                Operador
              </MenuItem>
              {state.operators.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          

        <Grid item lg={2} md={2} sm={2} xs={2}> 
          <Button fullWidth variant="outlined" size="large" onClick={() => handleReportDownload()} endIcon={<FilterListIcon />} style={{paddingTop: 14, paddingBottom: 14}}>
            Baixar
          </Button>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}></Grid>

          <Grid item container lg={6} md={6} sm={6} xs={6}>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                % Ranking Máquinas Empilhadeiras: Motivo Pausa - Acumulado Ano
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Table rank={state.rankYear} />
            </Grid>
          </Grid>
          <Grid item container lg={6} md={6} sm={6} xs={6}>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                % Ranking Máquinas Empilhadeiras: Motivo Pausa - Acumulado Mês
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Table rank={state.rankMonth} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default RankingReasonPause;
